import { makeStyles } from '@mui/styles';
import styles from 'assets/jss/material-kit-react/views/generalPage';
// nodejs library that concatenates classes
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import OptionPricingForm from './OptionPricingForm.jsx';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import { Container, Grid, Typography } from '@mui/material';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import OptionPricingOutput from './OptionPricingOutput.jsx';
import OptionPricingDesc from './OptionPricingDesc.jsx';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { WidgetBox } from 'assets/jss/GeneralStyle.jsx';
import AccessControl from 'views/Authorization/AccessControl.js';
import PermissionDenied from 'views/Authorization/PermissionDenied.js';
import Meta from 'components/meta.jsx';
const useStyles = makeStyles(styles);

export default function OptionPricingCalculator(props) {
    const optionPricingCalData = useSelector((state) => state.optionPricingCal);
    const navigate = useNavigate();

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'orderCal';
        }
    }, []);

    const classes = useStyles();
    const render = () => {
        return (
            <AccessControl allowedPermissions={['Option_Pricing']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}>
                {optionPricingCalData.loader ? <Loader /> : ''}
                <Grid container spacing={2} className="widget-grid-item">
                    <Grid item xs={12} sm={6} md={4.5}>
                        <OptionPricingForm optionPricingCalData={optionPricingCalData} classes={classes} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <OptionPricingOutput optionPricingCalData={optionPricingCalData} />
                    </Grid>
                </Grid>
            </AccessControl>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Investment'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Option Pricing Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Option Pricing Calculator | TPE Hub"
                    description="Use our Option Pricing Calculator to quickly and accurately determine the value of financial options. Get precise results with our easy-to-use tool designed for traders and investors."
                    page="/option-pricing-calculator"
                />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Option Pricing Calculator" />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <OptionPricingDesc />{' '}
                    </Container>
                </MainBox>
            </>
        )
    );
}
