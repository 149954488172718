import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { red, blue, green, orange, pink, purple, deepPurple, cyan, lime, amber, deepOrange, teal } from '@mui/material/colors';

const formatValue = (value) => {
    if (value >= 1e9) {
        return `${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
        return `${(value / 1e6).toFixed(1)}M`;
    } else if (value >= 1e3) {
        return `${(value / 1e3).toFixed(1)}K`;
    }
    return value;
};

const BarDebtChart = ({ debt }) => {
    const [data, setData] = useState([]);
    const [debtTypeKeys, setDebtTypeKeys] = useState([]);
    const colors = [
        '#FFA412',
        '#27A9E0',
        '#8D8D8D',
        purple[500],
        pink[500],
        deepPurple[500],
        teal[500],
        deepOrange[500],
        red[200],
        blue[200],
        lime[200],
        amber[200],
        green[500],
        orange[700],
        cyan[500]
    ];
    const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.8);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth * 0.8);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        handleData();
    }, []);

    const handleData = () => {
        const arr = [];
        let debt_type_keys;
        for (let i = 0; i < debt.length; i++) {
            const obj = {
                index: String(i),
                ...debt[i]
            };
            arr.push(obj);
        }
        let max = -999999;
        for (let obj of debt) {
            if (Object.keys(obj).length > max) {
                max = Object.keys(obj).length;
                debt_type_keys = Object.keys(obj);
            }
        }
        let indexOfStringToRemove = debt_type_keys.findIndex((str) => str === 'label');
        if (indexOfStringToRemove !== -1) {
            debt_type_keys.splice(indexOfStringToRemove, 1);
        }
        setData(arr);
        setDebtTypeKeys(debt_type_keys);
    };

    // Function to format the tooltip values based on the value range
    const formatTooltipValue = (value) => {
        // if (value >= 100000) {
        //     return `${value.toLocaleString('en-IN', { maximumFractionDigits: 0 })}`;
        // } else
        if (value >= 1000) {
            return `${value.toLocaleString('en-US', { maximumFractionDigits: 0 })}`;
        }
        return value.toLocaleString(undefined, { minimumFractionDigits: 2 });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <BarChart width={chartWidth} height={300} data={data} margin={{ top: 20, bottom: 10, left: 10, right: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" />
                <YAxis dataKey="label" tickFormatter={formatValue} />
                <Tooltip formatter={formatTooltipValue} />
                <Legend />
                {debtTypeKeys.map((key, index) => (
                    <Bar dataKey={key} fill={colors[index]} stackId="stack" />
                ))}
            </BarChart>
        </div>
    );
};

export default BarDebtChart;
