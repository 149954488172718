import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { RangeButton, WatchListChartPaper } from 'assets/jss/DashboardStyle';
import { ChartCardParent } from 'assets/jss/PortfolioStyle';
import AreaCharts from 'components/Charts/AreaChart';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const PortfolioChart = (props) => {
    const [selectedRange, setSelectedRange] = useState('1mo');
    const theme = useTheme();
    const chartParentRef = useRef(null);
    const rangeList = [
        { label: '5 Days', value: '5d' },
        { label: '1 Month', value: '1mo' },
        { label: '6 Months', value: '6mo' },
        { label: '1 Year', value: '1y' },
        { label: '3 Years', value: '3y' },
        { label: '5 Years', value: '5y' }
    ];
    const [chartData, setChartData] = useState([]);
    const [rangeMax, setRangeMax] = useState(0);
    const { secondary, warning, success, grey } = theme.palette;
    const portfolioData = useSelector((state) => state.portfolio);
    const [chartWidth, setChartWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            if (chartParentRef.current) {
                setChartWidth(chartParentRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    // useEffect(() => {
    //     const a = [];
    //     if (portfolioData.chartData.length) {
    //         const formatedData = portfolioData.chartData.map((val) => {
    //             a.push(val.Close);
    //             return { x: moment(val.Date).format('YYYY-MM-DD'), y: val.Close?.toFixed(2) };
    //         });
    //         setRangeMax(Math.min(...a));
    //         setChartData([{ id: props.chartdetails.symbol, data: formatedData }]);
    //     }
    // }, [portfolioData.chartData]);

    useEffect(() => {
        if (portfolioData.chartData.length) {
            const formatedData = portfolioData.chartData.map((val) => {
                return {
                    name: moment(val.Date).format('MMM DD YYYY'),
                    fullDate: val.Date,
                    [props.chartdetails.symbol]: parseFloat(val.Close?.toFixed(2))
                };
            });

            const minRange = Math.min(...portfolioData.chartData.map((val) => val.Close));
            setRangeMax(minRange);

            setChartData(formatedData);
        }
    }, [portfolioData.chartData]);

    // Trigger fetching of 1-month data on component mount
    useEffect(() => {
        props.rangeSelectionClicked('1mo');
    }, []);

    const formatValue = (value) => {
        if (value >= 1e9) {
            return `${(value / 1e9).toFixed(1)}B`;
        } else if (value >= 1e6) {
            return `${(value / 1e6).toFixed(1)}M`;
        } else if (value >= 1e3) {
            return `${(value / 1e3).toFixed(1)}K`;
        }
        return value;
    };
    const isAnnualRangeSelected = ['1y', '3y', '5y'].includes(selectedRange) || false;
    const priceChangeColor = Math.sign(props.chartdetails['per_change']) === 1 ? 'text.success' : 'text.error';
    return (
        <WatchListChartPaper elevation={2}>
            <CustomButton
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                className="read-more back-button"
                onClick={() => props.backButtonClicked()}
            >
                Back to table
            </CustomButton>
            <Box m={'16px 0 0 30px'}>
                <Typography variant="h5" gutterBottom>
                    {props.chartdetails['company_name']} ({props.chartdetails.symbol})
                </Typography>
                <Box display={'flex'}>
                    <Typography variant="h3"> {props.chartdetails['price'].toFixed(2)}</Typography>
                    <Typography m={'15px 0 0 15px'} color={priceChangeColor}>
                        {props.chartdetails['per_change']?.toFixed(2)} %
                    </Typography>
                </Box>
            </Box>
            <Box textAlign={'center'}>
                {rangeList.map((val) => (
                    <RangeButton
                        variant="text"
                        onClick={() => {
                            setSelectedRange(val.value);
                            props.rangeSelectionClicked(val.value);
                        }}
                        className={`${selectedRange === val.value && 'selected'}`}
                    >
                        {val.label}
                    </RangeButton>
                ))}
            </Box>
            <div ref={chartParentRef} style={{ width: '100%' }}>
                <ChartCardParent className="leave-space-dash" customheight={'450px'}>
                    {/* <AreaCharts
                  dateType={true}
                  data={chartData}
                  enableArea={false}
                  enablePoint={false}
                  rotateLabel={true}
                  isDateAxis={true}
                  areamin={rangeMax}
                  annualData={isAnnualRangeSelected}
                  //   halfGraph={true}
                  customColor={[secondary.main, warning.dark, success.main, grey[500]]}
                  curveType={'linear'}
              /> */}
                    <LineChart width={chartWidth} height={430} data={chartData || []}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            height={80}
                            style={{ fontSize: '0.8rem' }}
                            tick={{ angle: -30, textAnchor: 'middle' }}
                            dy={10}
                        ></XAxis>
                        <YAxis yAxisId="left" domain={[rangeMax, 'auto']} tickFormatter={formatValue} />
                        <YAxis yAxisId="right" orientation="right" tickFormatter={formatValue} />
                        <Tooltip
                            labelFormatter={(label, payload) => {
                                const fullDate = payload?.[0]?.payload?.fullDate;
                                return fullDate ? moment(fullDate).format('MM/DD/YYYY') : label;
                            }}
                        />
                        <Line
                            yAxisId="left"
                            type={isAnnualRangeSelected ? 'monotone' : 'linear'}
                            dataKey={props.chartdetails.symbol}
                            stroke={isAnnualRangeSelected ? warning.dark : secondary.main}
                            activeDot={{ r: 8 }}
                        />
                    </LineChart>
                </ChartCardParent>
            </div>
        </WatchListChartPaper>
    );
};

export default PortfolioChart;
