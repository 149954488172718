import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import RetirementCalForm from './RetirementCalForm.jsx';
import { Container, Grid, Typography } from '@mui/material';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import RetirementDescBlock from './RetirementDescBlock.jsx';
import RetirementOutput from './RetirementOutput.jsx';
import { WidgetCalDiv } from 'assets/jss/GeneralStyle.jsx';
import AccessControl from 'views/Authorization/AccessControl.js';
import PermissionDenied from 'views/Authorization/PermissionDenied.js';
import Loader from 'components/CommonComponent/Loader.js';
import Meta from 'components/meta.jsx';
export default function RetirementCalculator(props) {
    const retirementCalData = useSelector((state) => state.retirementCal);
    const navigate = useNavigate();
    const general = useSelector((state) => state.generalCal);
    const [result, setResult] = useState([]);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'retCal';
        }
    }, []);

    /**
     * @author disha
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(retirementCalData.retirementCalDetail).length) {
            setResult(retirementCalData.retirementCalDetail.data);
        } else {
            // to reset the output fields
            if (result.length > 0) setResult([]);
        }
    }, [retirementCalData]);
    const render = () => {
        return (
            <>
                <AccessControl allowedPermissions={['Retirement_calc']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}>
                    {retirementCalData.loader ? <Loader /> : ''}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4.5}>
                            <RetirementCalForm retirementCalData={retirementCalData} defaultoverrideData={general.defaultoverrideData} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={7.5}>
                            <RetirementOutput data={result} />
                        </Grid>
                    </Grid>
                </AccessControl>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Retirement'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Retirement Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetCalDiv>{render()}</WidgetCalDiv>) || (
            <>
                <Meta
                    title="Retirement Calculator | TPE Hub"
                    description="Use our Retirement Calculator to estimate how much you need to save for a comfortable retirement. Input your financial details and future goals to get personalized recommendations for your retirement planning."
                    page="/retirement-calculator"
                />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Retirement Calculator" />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <RetirementDescBlock />
                    </Container>
                </MainBox>
            </>
        )
    );
}
