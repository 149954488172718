import * as ActionTypes from './ActionTypes';
import { apiCall } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { handleError } from 'utils/Utils';

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to chat message and get the robo reponse
 */
export const sendChatBotMessage = (data) => async (dispatch) => {
    try {
        dispatch(sendChatBotMessageInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}finbot`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(sendChatBotMessageSuccess(apiResponse.data, data));
        } else {
            toast.error(Globals.errorMsg);
            dispatch(sendChatBotMessageError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(sendChatBotMessageError());
        throw error;
    }
};
// handle loader when api started to call
export const sendChatBotMessageInit = () => {
    return {
        type: ActionTypes.SEND_CHAT_BOT_MSG_INIT
    };
};
//handle success of api
export const sendChatBotMessageSuccess = (val, userData) => {
    return {
        type: ActionTypes.SEND_CHAT_BOT_MSG_SUCCESS,
        data: val,
        userData
    };
};

// handle api error
export const sendChatBotMessageError = () => {
    return {
        type: ActionTypes.SEND_CHAT_BOT_MSG_ERROR
    };
};
export const openChatBotPopup = (val) => {
    return {
        type: ActionTypes.OPEN_CHATBOT_POPUP,
        data: val
    };
};

//handle success of api
export const getChatHistorySuccess = (val) => {
    return {
        type: ActionTypes.FETCH_CHAT_HISTORY_SUCCESS,
        data: val
    };
};
// handle api error
export const getChatHistoryError = () => {
    return {
        type: ActionTypes.FETCH_CHAT_HISTORY_ERROR
    };
};

export const getChatHistory = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_CHAT_HISTORY_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}chat_history`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getChatHistorySuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getChatHistoryError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getChatHistoryError());
        throw error;
    }
};
