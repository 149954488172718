import { Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Tooltip, Typography, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';  // Import the CloseIcon
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submit_portfolio_allocation_admin_Success, submit_portfolio_allocation_admin, getPortfolioAllocation } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import { CustomButton } from 'assets/jss/ButtonStyle';

const PortfolioAllocationfunds = (props) => {
    const { classes, setting } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [problematicBroadTypes, setProblematicBroadTypes] = useState([]);
    const { handleSubmit, control, reset, formState: { isSubmitting }, setValue } = useForm({ mode: 'onChange' });

    useEffect(() => {
        if (setting.portfolioAllocationFormDialog) {
            handleClose();
            dispatch(submit_portfolio_allocation_admin_Success(false));
        }
    }, [setting.portfolioAllocationFormDialog]);

    useEffect(() => {
        dispatch(getPortfolioAllocation());
    }, []);

    useEffect(() => {
        if (setting.portfolioAllocationData.broad_asset_sums) {
            const issues = Object.entries(setting.portfolioAllocationData.broad_asset_sums).filter(([broadType, total]) => total !== 100);
            if (issues?.length > 0) {
                setProblematicBroadTypes(issues.map(([broadType]) => broadType));
                setAlertMessage('Some broad assets have allocations that are not equal to 100%.');
                setAlertOpen(true);
            } else {
                setProblematicBroadTypes([]);
            }
        }
    }, [setting.portfolioAllocationData]);

    const onSubmit = (data) => {
        const type = data.id ? 'put' : 'post';
        const objToSend = {
            Symbol: data?.Symbol,
            Broad_Type: data?.Broad_Type,
            Narrow_Type: data?.Narrow_Type,
            Asset_Class_Alloc: parseFloat(data?.Asset_Class_AllocUnFormattedValue || data?.Asset_Class_Alloc),
            id: data?.id || undefined
        };
        dispatch(submit_portfolio_allocation_admin(objToSend, type));
    };

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        reset({
            Symbol: '',
            Broad_Type: '',
            Narrow_Type: '',
            Asset_Class_Alloc: ''
        });
        setOpen(false);
    };

    const deleteSelectedData = (id) => dispatch(submit_portfolio_allocation_admin({ id: id }, 'delete'));

    const handleEditData = (val) => {
        setOpen(true);
        for (const key in val) {
            if (Object.hasOwnProperty.call(val, key)) {
                let element = val[key];
                setValue(key, element);
            }
        }
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    <Tooltip title="Add" arrow>
                        <CustomButton
                            variant="text"
                            className="read-more"
                            startIcon={<AddIcon />}
                            aria-label="add"
                            onClick={handleClickOpen}
                        >
                            Add New Data
                        </CustomButton>
                    </Tooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            Add New Ticker
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                style={{ position: 'absolute', right: 8, top: 8 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="Symbol"
                                            control={control}
                                            label={'Symbol'}
                                            id={'Symbol'}
                                            type={'text'}
                                            required={true}
                                            placeholder={'Enter Symbol'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="Broad_Type"
                                            control={control}
                                            label={'Broad Type'}
                                            id={'Broad_Type'}
                                            required={true}
                                            type={'text'}
                                            placeholder={'Enter Broad Type'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="Narrow_Type"
                                            control={control}
                                            label={'Narrow Type'}
                                            id={'Narrow_Type'}
                                            required={true}
                                            type={'text'}
                                            placeholder={'Enter Narrow Type'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            type="number"
                                            control={control}
                                            name="Asset_Class_Alloc"
                                            id="Asset_Class_Alloc"
                                            label="Asset Class Allocation"
                                            required
                                            placeholder="Enter Asset Class Allocation"
                                            setValue={setValue}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                    <Grid item xs={12}>
                                        <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                            Submit
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </DialogContent>
                    </Dialog>

                    {setting.portfolioAllocationData?.allocations?.length > 0 ? (
                        <Card>
                            <CardContent className="tlcardcontent">
                                <Grid container spacing={2} className="tlContainer">
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={2}>
                                                <Typography variant="h6">Actions</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Symbol</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Broad Type</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3} textAlign="center">
                                                <Typography variant="h6">Narrow Type</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3} textAlign="center">
                                                <Typography variant="h6">Asset Class Allocation</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {setting.portfolioAllocationData.allocations.map((val, index) => {
                                            const isProblematic = problematicBroadTypes.includes(val.Broad_Type);
                                            return (
                                                <Grid
                                                    container
                                                    key={val.id}
                                                    style={{
                                                        backgroundColor: index % 2 === 0 ? 'rgb(233 233 233)' : 'white',
                                                        padding: '10px',
                                                        transition: 'background-color 0.4s ease',
                                                        color: isProblematic ? 'red' : 'black',  // Highlight problematic rows
                                                    }}
                                                >
                                                    <Grid item xs={12} sm={2}>
                                                        <Tooltip title="Delete">
                                                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(val.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit">
                                                            <IconButton aria-label="edit" onClick={(e) => handleEditData(val)}>
                                                                <CreateIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Symbol}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Broad_Type}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Narrow_Type}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Asset_Class_Alloc}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) : (
                        <NoDataFound msg="Please add new module to display here." />
                    )}
                </CardContent>
            </Card>

            {/* Alert Popup for Broad Asset Allocation */}
            <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
                <DialogTitle>
                    Warning
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setAlertOpen(false)}
                        aria-label="close"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>
                        {alertMessage}
                        <ul>
                            {problematicBroadTypes.map((type, index) => (
                                <li key={index}>{type}</li>
                            ))}
                        </ul>
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PortfolioAllocationfunds;
