import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const EquityDashDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Equity Dashboard
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Welcome to our equity dashboard. Our equities are selected to include a mix of individual stocks as well as
                                critical sector ETFs. For example, we include key stocks such as Apple, Tesla and Microsoft, index ETFs
                                (e.g. the S&P 500 and the small-cap QQQ fund), active sector funds like ARKK and SEMI and finally bond index
                                funds that track risk-free government and corporate bonds. The daily prices are updated in real time
                                during the trading day. Each column is sortable so you can quickly see which investments increased or
                                decreased the most during the time-period you’re interested in (daily, weekly, yearly). In addition, each
                                column has a heat map format - the red/green colors allow you to quickly see which investments performed
                                best and worst during the period you are interested in. We also include the latest news headline relevant
                                for each position. In the future we will provide access to the full story. By the way – Please use the
                                feedback form if you’d like us to add your favorite stocks.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default EquityDashDesc;
