import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const AnnuityDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Annuity Calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                An annuity is like a pension in that it pays a fixed monthly amount over time. For that benefit you have to
                                pay an upfront premium. Most annuities aren’t inflation adjusted so the value of future payments may erode
                                under a high inflation scenario. Some annuity providers will offer an inflation feature but it will come at
                                a cost.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                We estimate the current value of the annuity using an assumed interest rate (AKA a discount rate). The
                                discount rate is the rate used to convert future dollars into today’s dollars. The total discount rate is
                                the risk-free rate + risk premium. You can use our fixed income dashboard to get proxies for these rates. We
                                recommend using 30-year treasuries as the risk-free rate and the single-A corporate rate as the risk
                                premium. Most annuity providers are highly rated and we think a Single-A risk is a good proxy (AAA is risk
                                free, like the government or strongest companies and anything below BBB is considered high risk). Remember
                                to always ask for the credit rating (ranging from AAA to Single-B) of your annuity provider. Annuities
                                entail some long-term risk to the annuity provider.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default AnnuityDesc;
