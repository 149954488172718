import React from 'react';
import { Box } from '@mui/material';
import NewsFeedTileView from './NewsFeedTileView';

function NewsFeedList({ newsList, latestNews, dailyNews }) {
    return (
        <Box sx={{ padding: '15px' }}>
            <NewsFeedTileView newsList={newsList} latestNews={latestNews} dailyNews={dailyNews} />
        </Box>
    );
}

export default NewsFeedList;
