import * as ActionTypes from '../actions/ActionTypes';
import moment from 'moment';

const initialState = {
    chatDetail: [],
    loader: false,
    roboResponse: [],
    togglechatbotpopup: false,
    chatHistory: []
};
// reducer for both the general and the document analysis bot
export const ChatBotReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OPEN_CHATBOT_POPUP:
            return { ...state, togglechatbotpopup: action.data };
        case ActionTypes.SEND_CHAT_BOT_MSG_INIT:
        case ActionTypes.FETCH_CHAT_HISTORY_INIT:
            return { ...state, loader: true };
        case ActionTypes.SEND_CHAT_BOT_ANALYSIS_MSG_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_CHAT_HISTORY_SUCCESS:
            return { ...state, chatHistory: action.data, loader: false };
        case ActionTypes.SEND_CHAT_BOT_MSG_SUCCESS:
            const Response = [];
            if (action.userData) {
                Response.push({
                    date: moment().format('X'),
                    msg: action.userData.user_response,
                    type: 'user'
                });
            }
            Response.push({
                date: moment().format('X'),
                msg: action.data,
                type: 'robo'
            });
            return {
                ...state,
                loader: false,
                chatDetail: state.chatDetail.concat(Response),
                roboResponse: action.data
            };
        case ActionTypes.SEND_CHAT_BOT_ANALYSIS_MSG_SUCCESS:
            const AnalysisResponse = [];
            if (action.userData) {
                AnalysisResponse.push({
                    date: moment().format('X'),
                    msg: action.userData.question,
                    type: 'user'
                });
            }
            AnalysisResponse.push({
                date: moment().format('X'),
                msg: action.data,
                type: 'robo'
            });
            return {
                ...state,
                loader: false,
                chatDetail: state.chatDetail.concat(AnalysisResponse),
                roboResponse: action.data
            };
        case ActionTypes.SEND_CHAT_BOT_MSG_ERROR:
        case ActionTypes.FETCH_CHAT_HISTORY_ERROR:
            return { ...state, loader: false };
        case ActionTypes.SEND_CHAT_BOT_ANALYSIS_MSG_ERROR:
            return { ...state, loader: false };
        default:
            return state;
    }
};
