import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const LoanDashDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Loan Dashboard
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This dashboard gives you a rough benchmark, updated daily, of current interest rates on various loan
                                products. Here we include various mortgage rates, auto loan, credit card and student loan rates. Aside from
                                student loans the rates are updated daily. The student loan rates are from the US government and only
                                updated once a year. Other than the student loan rates, the other rates are assumed to be for prime (strong
                                credit history) borrowers. We don’t (as of now) provide interest rates for borrowers who have a difficult
                                credit history.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default LoanDashDesc;
