import React from 'react';
import { Button, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '48rem',
    margin: '0',
    padding: '0',
  },
  card: {
    padding: '0',
    marginBottom: '0',
  },
  buttonContainer: {
    display: 'flex',
    overflowX: 'auto',
    paddingBottom: '0.25rem',
    
    '&::-webkit-scrollbar': {
      height: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  button: { 
    padding: "3px",
    flexShrink: 0,
    marginRight: '0.5rem',
    backgroundColor: 'transparent',
    borderRadius: '1rem',
    border: '1px solid #ccc',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
});

const QueryPanel = ({ onSubmit, inputRef, interactionType, documentType }) => {
  const classes = useStyles();

  const buttons = {
    'budget': [
        "Total expenditure in 2024?",
        "Total expenditure?",
        "Categorywise spending?",
        "Top three most expensive categories?",
        "average expense per month?",
        "highest single expense?",
        "Yearwise Spending?",
        "Which city had the most transactions?",
        "Recurring expenses?",
    ],
    'portfolio': [
      "top 5 assets by market value?",
      "Which assets had the largest dollar change today?",
      "What is the percentage change for each asset symbol?",
      "total market value of the portfolio?",
      "Which assets have the highest quantity?",
      "What are the average price and market value for each broad_asset_type?",
      "How many assets are non-tradeable?"
    ],
  };

  return (
    <div className={classes.container}>
      <Card key={documentType} className={classes.card}>
          <div className={classes.buttonContainer}>
          {(buttons[documentType] || []).map((item) => (
              <Button
                key={item}
                variant="contained"
                className={classes.button}
                onClick={(e) => {
                  interactionType.current = 'faq';
                  inputRef.current.value = item;
                  onSubmit(e);
                  inputRef.current.focus();
                }}
                style={{ 
                  color: '#4a4a4a', 
                  fontWeight: 'normal', 
                  fontSize: '0.800rem', 
                  padding: "4px",
                }}
              >
                {item}
              </Button>
          ))}
          </div>
      </Card>
    </div>
  );
};

export default QueryPanel;