import React, { useEffect, useState } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import InfoIcon from '@mui/icons-material/Info';

const CustomXAxisLabel = ({ viewBox, chartWidth }) => {
    const centerX = chartWidth / 2;

    return (
        <g transform={`translate(${centerX}, ${viewBox.y + 40})`}>
            <text textAnchor="middle" fill="#000">
                Scenario Multiplier
            </text>
            <MuiTooltip title="These multipliers apply to the monthly payments." arrow>
                <svg x={90} y={-18} width="24" height="24" style={{ cursor: 'pointer', color: 'rgb(39, 169, 224)' }}>
                    <InfoIcon fontSize="small" />
                </svg>
            </MuiTooltip>
        </g>
    );
};

const formatValue = (value) => {
    if (value >= 1e9) {
        return `${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
        return `${(value / 1e6).toFixed(1)}M`;
    } else if (value >= 1e3) {
        return `${(value / 1e3).toFixed(1)}K`;
    }
    return value;
};

export default function BiaxialCreditCardChart(props) {
    const { credit } = props;
    const [data, setData] = useState([]);
    const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.8);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth * 0.8);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        handleData();
    }, []);

    const handleData = () => {
        const arr = [];
        const minLength = Math.min(credit['Lifetime Interest'].length, credit['Months to Payoff'].length, credit['Multiplier'].length);
        for (let i = 0; i < minLength; i++) {
            const obj = {
                name: credit['Multiplier'][i],
                'Lifetime Interest': credit['Lifetime Interest'][i],
                'Months to Payoff': credit['Months to Payoff'][i]
            };
            arr.push(obj);
        }
        setData(arr);
    };

    // Function to format the tooltip values based on the value range
    const formatTooltipValue = (value) => {
        // if (value >= 100000) {
        //     return `${value.toLocaleString('en-IN', { maximumFractionDigits: 0 })}`;
        // } else
        if (value >= 1000) {
            return `${value.toLocaleString('en-US', { maximumFractionDigits: 0 })}`;
        }
        return value.toLocaleString(undefined, { minimumFractionDigits: 2 });
    };

    return (
        <>
            <div style={{ position: 'relative', overflow: 'hidden', display: 'flex', justifyContent: 'space-around' }}>
                <div
                    style={{
                        position: 'absolute',
                        top: '35%',
                        transform: 'rotate(-90deg)',
                        left: '-20px',
                        color: 'gray'
                    }}
                >
                    Lifetime Interest
                </div>
                <div style={{ marginLeft: '4px' }}>
                    <LineChart width={chartWidth} height={400} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" height={60}>
                            <Label content={<CustomXAxisLabel chartWidth={chartWidth} />} position="insideBottom" />
                        </XAxis>
                        <YAxis yAxisId="left" tickFormatter={formatValue}></YAxis>
                        <YAxis yAxisId="right" orientation="right" tickFormatter={formatValue}></YAxis>
                        <Tooltip formatter={formatTooltipValue} />;
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="Lifetime Interest" stroke="blue" activeDot={{ r: 8 }} />
                        <Line yAxisId="right" type="monotone" dataKey="Months to Payoff" stroke="orange" />
                    </LineChart>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '35%',
                        transform: 'rotate(-90deg)',
                        right: '-20px',
                        color: 'gray'
                    }}
                >
                    Months to Payoff
                </div>
            </div>
        </>
    );
}
