import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState, useRef, useMemo, forwardRef, useCallback } from 'react';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { toolsDetails } from 'views/LandingPage/ToolDetails';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Collapse,
    Container,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    MenuList,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/styles';
import DrawerComp from './Drawer';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { AppBar, Item } from 'assets/jss/HeaderStyle';

import { Masonry } from '@mui/lab';
import { CustomImg, CustomListItem, CustomListItemText, CustomPopoverBox, HeaderRightBtn, MenuBox } from 'assets/jss/HeaderStyle';
import { useDispatch } from 'react-redux';
import { handleLogout } from 'redux/actions/AuthActions';
import { getCookie, hasChildren } from 'utils/Utils';
import { setActiveSettingsTab } from 'redux/actions/SettingActions';
import { setActiveSettingsAdminPanelTab } from 'redux/actions/SettingActions';
import Tour from 'reactour';
import { useSelector } from 'react-redux';

function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = useMemo(
        () =>
            forwardRef(function Link(itemProps, ref) {
                return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
            }),
        [to]
    );

    return (
        <CustomListItem button disableGutters className="listbutton" sx={{ m: 0 }} component={renderLink}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <CustomListItemText primary={primary} />
        </CustomListItem>
    );
}
const MenuItemComp = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
};

const MultiLevel = ({ item }) => {
    const { children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    const menulabel = item.parent || item.title;
    return (
        <>
            <CustomListItem button onClick={handleClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <CustomListItemText primary={menulabel} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </CustomListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children.map((child, key) => {
                        return <MenuItemComp key={key + '_menuitemcomp_headermultilevel'} item={child} />;
                    })}
                </List>
            </Collapse>
        </>
    );
};
const SingleLevel = ({ item }) => {
    const menulabel = item.parent || item.name;
    return (
        <CustomListItem button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <CustomListItemText primary={menulabel} />
        </CustomListItem>
    );
};
export default function Header(props) {
    const theme = useTheme();
    const apiToken = getCookie('auth_token');
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openArrow, SetOpenArrow] = useState(null);
    const drawerEle = useRef(null);
    const containerRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const setting = useSelector((state) => state.setting);
    const [hover, setHover] = useState('');

    const handleButtons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {currentStep !== 0 && (
                    <button
                        style={{
                            padding: '10px',
                            border: '1px solid black',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            fontSize: '18px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep - 1)}
                    >
                        Back
                    </button>
                )}
                {currentStep !== steps.length - 1 ? (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep + 1)}
                    >
                        Continue
                    </button>
                ) : (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => closeTour()}
                    >
                        Finish
                    </button>
                )}
            </div>
        );
    };

    let steps = [
        {
            selector: '#navlink-0',
            content: () => (
                <>
                    <h4>Calculators</h4>
                    <p>Welcome to Calculators! here you find all kind of calculators.</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#navlink-1',
            content: () => (
                <>
                    <h4>Dashboards</h4>
                    <p>
                        Now, let me introduce to Dashboards! here you find Consumer Loans Rates, Equity, Fixed Income and Economic
                        dashboards.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#navlink-2',
            content: () => (
                <>
                    <h4>Todo</h4>
                    <p> Now, let me introduce to Todo!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#navlink-3',
            content: () => (
                <>
                    <h4>News & Research</h4>
                    <p> Now, let me introduce to News & Research!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#navlink-5',
            content: () => (
                <>
                    <h4>Contact us</h4>
                    <p> Now, let me introduce to Contact us!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#apiToken',
            content: () => (
                <>
                    <h4>Profile</h4>
                    <p> Now, let me introduce to Profile! here you just need to login and you can see the profile and settings section.</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#calc-0',
            content: () => (
                <>
                    <h4>Budgets & Taxes</h4>
                    <p> Now, let me introduce to Budgets & Taxes!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#calc-1',
            content: () => (
                <>
                    <h4>Investments</h4>
                    <p> Now, let me introduce to Investments!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#calc-2',
            content: () => (
                <>
                    <h4>Debt Calculators</h4>
                    <p> Now, let me introduce to Debt Calculators!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#calc-3',
            content: () => (
                <>
                    <h4>Insurance</h4>
                    <p> Now, let me introduce to Insurance!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#calc-4',
            content: () => (
                <>
                    <h4>Real Estate</h4>
                    <p> Now, let me introduce to Real Estate!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#calc-5',
            content: () => (
                <>
                    <h4>Retirement</h4>
                    <p> Now, let me introduce to Retirement!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#calc-6',
            content: () => (
                <>
                    <h4>FX Calculators</h4>
                    <p> Now, let me introduce to FX Calculators!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#dash-0',
            content: () => (
                <>
                    <h4>Consumer Loans Rates</h4>
                    <p> Now, let me introduce to Consumer Loans Rates!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#dash-1',
            content: () => (
                <>
                    <h4>Equity</h4>
                    <p> Now, let me introduce to Equity!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#dash-2',
            content: () => (
                <>
                    <h4>Fixed Income</h4>
                    <p> Now, let me introduce to Fixed Income!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#dash-3',
            content: () => (
                <>
                    <h4>Economic Dashboard</h4>
                    <p> Now, let me introduce to Economic Dashboard!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#todo',
            content: () => (
                <>
                    <h4>Todo</h4>
                    <p> Now, let me introduce to Todo!</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#knowledge-research',
            content: () => (
                <>
                    <h4>News & Research</h4>
                    <p> Now, let me introduce to News & Research!</p>
                    {handleButtons()}
                </>
            )
        },

        {
            selector: '#subscribe',
            content: () => (
                <>
                    <h4>Subscribe</h4>
                    <p> Now, let me introduce to Subscribe!</p>
                    {handleButtons()}
                </>
            )
        }
    ];

    const closeTour = () => {
        setIsTourOpen(false);
        document.body.style.overflow = 'auto';
    };

    const handleTour = () => {
        setIsTourOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const openProfileMenu = Boolean(anchorElProfile);
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));

    const username = Object.keys(props.setting.profileDetail).length
        ? props.setting.profileDetail.fname
              .split(' ')
              .map((name) => name[0])
              .join('')
              .toUpperCase()
        : '';
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    useEffect(() => {
        if (props.closePopup === true && drawerEle.current) {
            props.resetClosePopupFlag();
            handleDrawerToggle();
        }
    }, [props]);

    const handleClickProfile = (event) => {
        setAnchorElProfile(event.currentTarget);
    };
    const handleCloseProfile = () => {
        setAnchorElProfile(null);
    };

    const handleParentClick = useCallback(
        (event, val) => {
            if (val?.children != undefined) {
                setHover(val.parent);
                setSelectedMenu(val.children);
                SetOpenArrow(val.parent);
                setAnchorEl(event.currentTarget);
            } else {
                setAnchorEl(null);
                SetOpenArrow(null);
            }
        },
        [setHover]
    );
    const togglePopover = (event, val) => {
        if (openArrow === null && val?.children != undefined) {
            setSelectedMenu(val.children);
            SetOpenArrow(val.parent);
            setAnchorEl(event.currentTarget);
            return;
        } else {
            setAnchorEl(null);
            SetOpenArrow(null);
        }
    };

    const handlePopoverleave = useCallback(() => {
        setSelectedMenu(null);
        setAnchorEl(null);
        SetOpenArrow(null);
        setHover('');
    }, [setHover]);

    const logout = () => dispatch(handleLogout(navigate));

    return (
        <>
            <div>
                <Tour
                    steps={steps}
                    isOpen={isTourOpen}
                    onRequestClose={closeTour}
                    goToStep={currentStep}
                    showButtons={false}
                    showNavigation={true}
                    showNumber={false}
                    closeWithMask={false}
                    closeWithEsc={false}
                    rounded={10}
                    maskSpace={10}
                />
            </div>
            <AppBar
                position="static"
                component="nav"
                sx={{ zIndex: 1300 }}
                // sx={{ background: '#063970' }}
            >
                <Toolbar>
                    <MenuItem component={Link} disableGutters to={'/'}>
                        <IconButton
                            // sx={{ flexGrow: 1, justifyContent: 'space-between' }}
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            disableRipple={true}
                        >
                            <CustomImg src="/img/TPE-logo.svg" alt="tpe-logo" />
                        </IconButton>
                    </MenuItem>
                    {/* <AddBusinessRoundedIcon sx={{ transform: 'scale(2)' }} /> */}
                    {isMatch ? (
                        <>
                            <DrawerComp ListItemLink={ListItemLink} apiToken={apiToken} username={username} handleTour={handleTour} />
                        </>
                    ) : (
                        <>
                            <MenuBox ref={containerRef} sx={{ marginLeft: 'auto' }}>
                                <List component={Stack} direction="row">
                                    {toolsDetails.map((val, i) => {
                                        const hasChild = (val.children && true) || false;
                                        return (
                                            <>
                                                <CustomListItem
                                                    key={i + '_parentMenus'}
                                                    disablePadding
                                                    sx={{ zIndex: theme.zIndex.modal + 1 }}
                                                    onMouseLeave={() => setHover('')}
                                                    onMouseEnter={(e) => handleParentClick(e, val)}
                                                    onClick={(e) => togglePopover(e, val)}
                                                    id={`navlink-${i}`}
                                                    aria-controls={
                                                        val.parent === 'Dashboards' && hover === 'Dashboards' ? 'basic-menu' : undefined
                                                    }
                                                    aria-haspopup={val.parent === 'Dashboards' && hover === 'Dashboards' ? 'true' : 'false'}
                                                    aria-expanded={
                                                        val.parent === 'Dashboards' && hover === 'Dashboards' ? 'true' : undefined
                                                    }
                                                >
                                                    {hasChild ? (
                                                        <ListItemButton
                                                            disableGutters
                                                            className={`listbutton ${hover === val.parent && 'active'}`}
                                                        >
                                                            <CustomListItemText primary={val.parent} />
                                                            {hover === val.parent ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItemButton>
                                                    ) : (
                                                        <ListItemLink to={val.to} primary={val.parent} />
                                                    )}
                                                </CustomListItem>
                                                <CustomPopoverBox
                                                    sx={{
                                                        position: 'absolute',
                                                        display: openArrow !== null ? 'flex' : 'none',
                                                        flexDirection: 'row',
                                                        zIndex: openArrow === 'Dashboards' ? 10 : 0,
                                                        '& .popover-container': {
                                                            maxWidth: '300px',
                                                            border: '1px solid black'
                                                        },
                                                        '& .MuiPaper-elevation': {
                                                            margin: '0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    top="55px"
                                                    left="110px"
                                                    width="232px"
                                                    bgcolor="white"
                                                    onMouseLeave={handlePopoverleave} //  remaining
                                                    maxHeight="310px"
                                                    // overflow="scroll"
                                                >
                                                    <Container disableGutters className="popover-container">
                                                        {openArrow === 'Dashboards' && (
                                                            <Masonry
                                                                columns={{ md: 4, sm: 3 }}
                                                                spacing={2}
                                                                className={`${
                                                                    openArrow === 'Dashboards' ? 'align-menu-dash' : 'align-menu-cal'
                                                                }`}
                                                            >
                                                                <Item>
                                                                    <MenuList>
                                                                        <MenuItem
                                                                            component={Link}
                                                                            disableGutters
                                                                            to="/loan-dashboard"
                                                                            onClick={handlePopoverleave}
                                                                        >
                                                                            Consumer Loans Rates
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            component={Link}
                                                                            to="/equity-dashboard"
                                                                            onClick={handlePopoverleave}
                                                                            disableGutters
                                                                        >
                                                                            Equity
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            component={Link}
                                                                            to="/fixed-income-dashboard"
                                                                            onClick={handlePopoverleave}
                                                                            disableGutters
                                                                        >
                                                                            Fixed Income
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            component={Link}
                                                                            to="/economic-dashboard"
                                                                            onClick={handlePopoverleave}
                                                                            disableGutters
                                                                        >
                                                                            Economic Dashboard
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </Item>
                                                            </Masonry>
                                                        )}
                                                    </Container>
                                                </CustomPopoverBox>
                                            </>
                                        );
                                    })}
                                </List>
                                {window.location.pathname.length === 1 && setting.profileDetail.role === 'admin' && (
                                    <button
                                        style={{
                                            padding: '10px',
                                            backgroundColor: 'transparent',
                                            border: '1px solid black',
                                            borderRadius: '10px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleTour}
                                    >
                                        Take a tour
                                    </button>
                                )}

                                <CustomPopoverBox
                                    className="popover-box"
                                    sx={{
                                        position: 'absolute',
                                        display: openArrow !== null ? 'flex' : 'none',
                                        flexDirection: 'row',
                                        zIndex: openArrow === 'Calculators' ? 10 : 0
                                    }}
                                    top="55px"
                                    left="0px"
                                    width="100vw"
                                    bgcolor="white"
                                    onMouseLeave={handlePopoverleave}
                                    maxHeight="310px"
                                    // overflow="scroll"
                                >
                                    <Container disableGutters className="popover-container">
                                        {selectedMenu && openArrow === 'Calculators' && (
                                            <Masonry
                                                columns={{ md: 4, sm: 3 }}
                                                spacing={2}
                                                className={`${openArrow === 'Dashboards' ? 'align-menu-dash' : 'align-menu-cal'}`}
                                            >
                                                {selectedMenu.map((menuval, i) => {
                                                    const menutitle = menuval.title || menuval.name;
                                                    const child = menuval?.children || [];
                                                    return (
                                                        <Item key={i + '_selectedmenuitem'}>
                                                            {child.length > 0 ? (
                                                                <>
                                                                    <Typography variant="h6">{menutitle}</Typography>
                                                                    <MenuList>
                                                                        {child.map((submenu, s) => {
                                                                            return submenu.to !== '/coming-soon' ? (
                                                                                <MenuItem
                                                                                    component={Link}
                                                                                    key={s + '_submenus'}
                                                                                    disableGutters
                                                                                    to={submenu.to}
                                                                                    onClick={handlePopoverleave}
                                                                                >
                                                                                    <Typography variant="body1">{submenu.name}</Typography>
                                                                                </MenuItem>
                                                                            ) : (
                                                                                <></>
                                                                            );
                                                                        })}
                                                                    </MenuList>
                                                                </>
                                                            ) : menuval.to !== '/coming-soon' ? (
                                                                <MenuItem component={Link} disableGutters to={menuval.to}>
                                                                    <Typography variant="h6">{menutitle}</Typography>
                                                                </MenuItem>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Item>
                                                    );
                                                })}
                                            </Masonry>
                                        )}
                                    </Container>

                                    {openArrow === 'Calculators' && (
                                        <div style={{ width: '65vw', maxWidth: '50vw', display: 'flex', flexDirection: 'row-reverse' }}>
                                            <img
                                                src={`/img/Intersect.png?auto=format`}
                                                loading="lazy"
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    display: 'block'
                                                }}
                                            />
                                        </div>
                                    )}
                                </CustomPopoverBox>
                            </MenuBox>

                            {apiToken ? (
                                <>
                                    <Box
                                        id="apiToken"
                                        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', marginLeft: 'auto' }}
                                    >
                                        <Tooltip title="Account settings">
                                            <IconButton
                                                onClick={handleClickProfile}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                // aria-controls={anchorElUser ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                // aria-expanded={anchorElUser ? 'true' : undefined}
                                            >
                                                <Avatar sx={{ width: 32, height: 32 }}>{username}</Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Menu
                                        anchorEl={anchorElProfile}
                                        id="account-menu"
                                        open={openProfileMenu}
                                        onClose={handleCloseProfile}
                                        onClick={handleCloseProfile}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0
                                                }
                                            }
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <CustomListItem
                                            className="listbutton"
                                            sx={{ m: 0 }}
                                            onClick={() => {
                                                dispatch(setActiveSettingsTab(0));
                                                dispatch(setActiveSettingsAdminPanelTab(0));
                                            }}
                                            component={RouterLink}
                                            to="/settings"
                                        >
                                            <ListItemIcon>
                                                <Avatar />
                                            </ListItemIcon>
                                            <CustomListItemText primary={'Profile'} />
                                        </CustomListItem>
                                        <Divider />
                                        <CustomListItem
                                            className="listbutton"
                                            sx={{ m: 0 }}
                                            onClick={() => {
                                                dispatch(setActiveSettingsTab(0));
                                                dispatch(setActiveSettingsAdminPanelTab(0));
                                            }}
                                            component={RouterLink}
                                            to="/settings"
                                        >
                                            <ListItemIcon>
                                                <Settings fontSize="small" />
                                            </ListItemIcon>
                                            <CustomListItemText primary={'Settings'} />
                                        </CustomListItem>
                                        <CustomListItem className="listbutton" sx={{ m: 0 }} component={RouterLink} to="" onClick={logout}>
                                            <ListItemIcon>
                                                <Logout fontSize="small" />
                                            </ListItemIcon>
                                            <CustomListItemText primary={'Logout'} />
                                        </CustomListItem>
                                    </Menu>
                                </>
                            ) : (
                                <>
                                    <HeaderRightBtn id="apiToken" sx={{ marginLeft: 'auto' }} variant="text" to="/login" component={Link}>
                                        Login
                                    </HeaderRightBtn>
                                    <HeaderRightBtn
                                        sx={{ marginLeft: '10px' }}
                                        variant="outlined"
                                        className={'signup'}
                                        component={Link}
                                        to="/sign-up"
                                    >
                                        Sign Up
                                    </HeaderRightBtn>
                                </>
                            )}
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
}

// Header.defaultProp = {
//     color: 'white'
// };

// Header.propTypes = {
//     color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']),
//     rightLinks: PropTypes.node,
//     leftLinks: PropTypes.node,
//     brand: PropTypes.string,
//     fixed: PropTypes.bool,
//     absolute: PropTypes.bool,
//     // this will cause the sidebar to change the color from
//     // props.color (see above) to changeColorOnScroll.color
//     // when the window.pageYOffset is heigher or equal to
//     // changeColorOnScroll.height and then when it is smaller than
//     // changeColorOnScroll.height change it back to
//     // props.color (see above)
//     changeColorOnScroll: PropTypes.shape({
//         height: PropTypes.number.isRequired,
//         color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark']).isRequired
//     })
// };
