import axios from 'axios';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { history } from 'views/Routes/index';
import { toast } from 'react-toastify';
import Globals from 'Globals.js';
import { handleSignInPermissionDialog } from 'redux/actions/GeneralCalActions';
import imageCompression from 'browser-image-compression';

/**
 * @author disha
 * @use handle axios api call
 * @param {*} url name of the api
 * @param {*} method GET, POST, PUT, DELETE, etc
 * @param {*} data pass data if required to pass in api call
 * @returns
 */
export async function apiCall(url = '', method = '', data = {}) {
    const apiToken = getCookie('auth_token');
    var apiDetail = {
        method: method.toUpperCase(), // *GET, POST, PUT, DELETE, etc.
        url,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    if (apiToken) {
        apiDetail = {
            ...apiDetail,
            headers: { ...apiDetail.headers, Authorization: 'Bearer ' + apiToken }
        };
    }
    if (Object.keys(data).length > 0) {
        let objToAdd = {
            data: JSON.stringify(data) // body data type must match "Content-Type" header
        };
        Object.assign(apiDetail, objToAdd);
    }
    try {
        const response = await axios(apiDetail);
        return await response;
    } catch (err) {
        console.log(err.response, 'Error while calling api');
        if (err.response !== undefined && err.response.status === 401) {
            // localStorage.removeItem("auth_token");
            // history.push("/login");
        }
        return err?.response;
    }
}

export async function apiCall2(url = '', method = '', data = {}, nam) {
    const apiToken = getCookie('auth_token');
    var apiDetail = {
        method: method.toUpperCase(), // *GET, POST, PUT, DELETE, etc.
        url,
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    if (apiToken) {
        apiDetail = {
            ...apiDetail,
            headers: { ...apiDetail.headers, Authorization: 'Bearer ' + apiToken }
        };
    }

    try {
        const response = await axios(apiDetail);
        return await response;
    } catch (err) {
        console.log(err.response, 'Error while calling api');
        if (err.response !== undefined && err.response.status === 401) {
            // localStorage.removeItem("auth_token");
            // history.push("/login");
        }
        return err?.response;
    }
}

export function limitWords(textToLimit, wordLimit = 10, returnResponse = false) {
    var finalText = '';
    var text2 = textToLimit.replace(/\s+/g, ' ');
    var text3 = text2.split(' ');
    var numberOfWords = text3.length;
    var i = 0;
    if (numberOfWords > wordLimit) {
        for (i = 0; i < wordLimit; i++) finalText = finalText + ' ' + text3[i] + ' ';
        return finalText + '...';
    } else return returnResponse ? 'same' : textToLimit;
}

export function limitLetters(textToLimit, wordLimit = 10, returnResponse = false) {
    var length = textToLimit?.length;
    var finalText = '';
    var i = 0;
    if (length > wordLimit) {
        for (i = 0; i < wordLimit; i++) {
            finalText = finalText + textToLimit[i];
        }
        return finalText + '...';
    } else return returnResponse ? 'same' : textToLimit;
}
export function numberWithCommas(x) {
    // return x?.toString().replace(/\,/g, '').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return x
        ?.toString()
        .replace(/,/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function trimDecimals(number, points) {
    if (typeof number !== 'number' || isNaN(number)) {
        // Handle non-numeric inputs
        return NaN;
    }
    let numberStr = number.toString();
    if (numberStr.includes('.')) {
        let [integerPart, fractionalPart] = numberStr.split('.');
        return parseFloat(integerPart + '.' + fractionalPart.slice(0, points));
    }
    return number;
}
export async function parseCsv(file) {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            keepEmptyRows: false,
            skipEmptyLines: true,
            trimHeaders: true,
            transform: (value) => {
                return value.trim();
            },
            error: function (err, file, inputElem, reason) {
                return reject(err);
            },
            complete: (results) => {
                return resolve({
                    fileName: file.name || 'Text Data',
                    data: results.data
                });
            }
        });
    });
}
export async function parseExcel(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        try {
            reader.onload = function (e) {
                var data = e.target.result,
                    workbook = XLSX.read(data, { type: 'binary' }),
                    worksheets = workbook.SheetNames,
                    results = XLSX.utils.sheet_to_json(workbook.Sheets[worksheets[0]]);
                return resolve({
                    fileName: file.name,
                    data: results
                });
            };
            reader.readAsBinaryString(file);
        } catch (err) {
            reject(err);
        }
    });
}

export function currentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return today;
}

export const abbreviate_number = (num, fixed = 0) => {
    let locFixed = fixed;
    if (num === null) {
        return null;
    } // terminate early
    if (num === 0) {
        return '0';
    } // terminate early
    locFixed = !locFixed || locFixed < 0 ? 0 : locFixed; // number of decimal places to show
    var b = num.toPrecision(2).split('e'), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + locFixed) : (num / Math.pow(10, k * 3)).toFixed(1 + locFixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
};
/**
 * to split data into passed length to make column
 * @param {*} arr
 * @param {*} len
 * @returns
 */
export const splitArrayIntoChunksOfLen = (arr, len) => {
    var chunks = [],
        i = 0,
        n = arr.length;
    if (len == 0) {
        chunks.push(arr);
        return chunks;
    }
    while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
};

export const invertColor = (hex, bw) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
};
function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
function pickHex(color1, color2, weight) {
    var p = weight;
    var w = p * 2 - 1;
    var w1 = (w / 1 + 1) / 2;
    var w2 = 1 - w1;
    var rgb = [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)
    ];
    return rgb;
}
export const colorScale = (arr, count, reverseSort = false, isMiddleColorYellow = false) => {
    if (!count) return '#ffffff';
    if (typeof count === 'string') count = parseInt(count.replaceAll(',', ''));
    if (typeof arr[0] === 'string') arr = arr.map((obj) => parseInt(obj.replaceAll(',', '')));

    // Filter out NaN values
    arr = arr.filter((value) => !isNaN(value));

    if (arr.length === 0) return '#ffffff';

    var sortArr;
    sortArr = arr?.sort(function (a, b) {
        return a - b;
    });

    let gradient = [
            [Math.min(...arr), reverseSort ? [76, 175, 80] : [244, 67, 54]],
            [sortArr[Math.round(arr.length / 2)], isMiddleColorYellow ? [247, 250, 87] : [255, 255, 255]],
            [Math.max(...arr), reverseSort ? [244, 67, 54] : [76, 175, 80]]
        ],
        colorRange = [];
    if (count === gradient[2][0]) return `rgb(${gradient[2][1][0]},${gradient[2][1][1]},${gradient[2][1][2]})`;
    for (var i = 0; i < gradient.length; i++) {
        const index = gradient[i];
        if (count === index[0]) return `rgb(${index[1][0]},${index[1][1]},${index[1][2]})`;
        if (count < index[0]) {
            let firstIndex = i - 1;
            let lastIndex = i;
            if (firstIndex < 0) {
                firstIndex = 0;
                lastIndex = i + 1;
            }
            colorRange = [firstIndex, lastIndex];
            break;
        }
    }
    //Get the two closest colors
    if (!colorRange.length) return '#ffffff';
    var firstcolor = gradient[colorRange[0]][1];
    var secondcolor = gradient[colorRange[1]][1];

    //Calculate ratio between the two closest colors
    var firstcolor_x = arr.length * (gradient[colorRange[0]][0] / 100);
    var secondcolor_x = arr.length * (gradient[colorRange[1]][0] / 100) - firstcolor_x;
    var slider_x = arr.length * (count / 100) - firstcolor_x;
    var ratio = slider_x / secondcolor_x;
    //Get the color with pickHex(thx, less.js's mix function!)
    var result = pickHex(secondcolor, firstcolor, ratio);
    return 'rgb(' + result.join() + ')';
};
export const handleError = (apiResponse, dispatch) => {
    if (apiResponse) {
        if (apiResponse.status === 401) {
            eraseCookie('auth_token');
            // to remove the singup popup from the landing page if token is expired and still in the cookie
            if (history.location.pathname === '/') {
                history.go(0);
            }
            return dispatch(handleSignInPermissionDialog(true));
        }
        if (apiResponse.status === 503) {
            return toast.error('Service temporarily unavailable. Please try again later!');
        }
        if (apiResponse.status === 400) {
            let errorKey = Object.keys(apiResponse.data.error)[0];
            if (apiResponse.data.error[errorKey].data) {
                let errorFieldKey = Object.keys(apiResponse.data.error[errorKey].data[0])[0];
                return toast.error(errorFieldKey + ': ' + apiResponse.data.error[errorKey].data[0][errorFieldKey]);
            } else if (apiResponse.data.error.message && Array.isArray(apiResponse.data.error.message)) {
                return toast.error(apiResponse.data.error.message?.[0]);
            } else if (apiResponse.data.error.message && !Array.isArray(apiResponse.data.error.message)) {
                return toast.error(apiResponse.data.error.message);
            } else {
                return toast.error(apiResponse.data.error?.[errorKey]?.[0]);
            }
        }
        const errMsg = apiResponse?.data?.error?.message;
        let validationError;
        if (!errMsg && Object.keys(apiResponse?.data.error).length > 0) {
            var errorKey = Object.keys(apiResponse.data.error)[0];
            validationError = errorKey + ': ' + apiResponse.data.error[errorKey]?.[0];
        }
        return toast.error(errMsg || validationError || Globals.errorMsg);
    }
    return toast.error(Globals.errorMsg);
};
// to sort the array by passed key name
export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}
export const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
};
// to create First letter capital of word
export function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}
// to filter out the selected filter data in table
export const optionIncludes = (rows, ids, filterValue) => {
    return rows.filter((row) => {
        return ids.some((id) => {
            const rowValue = row.values[id]?.toString().toLowerCase() || '-';
            return filterValue === 'all' ? true : rowValue ? rowValue.includes(filterValue) : rowValue === filterValue;
        });
    });
};
//to sort the numeric column data
export function compareNumericString(rowA, rowB, id, desc) {
    let a = Number.parseFloat(rowA.values[id]?.toString().replace(/,/g, ''));
    let b = Number.parseFloat(rowB.values[id]?.toString().replace(/,/g, ''));
    if (Number.isNaN(a)) {
        // Blanks and non-numeric strings to bottom
        a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
        b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) return 1;
    if (a < b) return -1;
}
export function setCookie(name, value, days = null) {
    const storageName = name;
    // var expires = "";
    // if (days) {
    //   var date = new Date();
    //   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    //   expires = "; expires=" + date.toUTCString();
    // } else {
    //   //to get date after 5 years from now to set exipre date of cookie
    //   var expireDate = new Date();
    //   expireDate.setFullYear(expireDate.getFullYear() + 5);
    //   expires = "; expires=" + expireDate;
    // }
    // document.cookie = name + '=' + (value || '') + expires + '; path=/' + '; SameSite= Strict;secure';
    localStorage.setItem(storageName, value);
}
export function getCookie(name) {
    // var nameEQ = name + "=";
    // var ca = document.cookie.split(";");
    // for (var i = 0; i < ca.length; i++) {
    //   var c = ca[i];
    //   while (c.charAt(0) == " ") c = c.substring(1, c.length);
    //   if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    // }
    // return null;
    return localStorage.getItem(name);
}
export function eraseCookie(name) {
    // document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    localStorage.removeItem(name);
}

export function hasChildren(item) {
    const { children: children } = item;

    if (children === undefined) {
        return false;
    }

    if (children.constructor !== Array) {
        return false;
    }

    if (children.length === 0) {
        return false;
    }

    return true;
}

export const compressUploadImage = async (file, maxWidth, maxHeight, maxSizeMB) => {
    const options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: Math.max(maxWidth, maxHeight),
        useWebWorker: true,
        fileType: file.type
    };

    try {
        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() });
        return compressedFile;
    } catch (error) {
        throw new Error('Image compression failed.');
    }
};
