import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const StudLoanDesc = ({ classes, control, handleOnChange, setValue, ...rest }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Student Loan Calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This is one of our most complicated but important calculators. The calculator includes detailed aspects such
                                as combining federal and private loans and includes the maximum federal loans students are permitted to
                                borrow. It also includes real (albeit estimated) interest rates for federal and private loans, approximate
                                terms/maturity of the loans as well as an estimate of funding provided by parents/or the students.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The output looks at total loans, and an estimate of debt to income ratio and a traffic light system that
                                flags to prospective students and their parents when debt may be too high.","The goal of our calculator is
                                to enable students to be aware that they may be taking on too much debt relative to the income they expect
                                to return. The calculator includes as input the income the student expects to earn. This will be a function
                                of the school attended, grades achieved, the field of study and the geography as well as many other factors.
                                Students and their parents should check for incomes reported by their schools
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default StudLoanDesc;
