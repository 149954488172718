import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles, withStyles } from "@mui/styles";
import { CustomButton } from "assets/jss/ButtonStyle";
import { ResetButton } from "assets/jss/GeneralStyle";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Button from "components/CustomButtons/Button.js";
import Meta from "components/meta";
import Parallax from "components/Parallax/Parallax.js";
import React from "react";
import { Link } from "react-router-dom";
const useStyles = makeStyles(styles);
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
})(Typography);
export default function ResetSuccess() {
  const classes = useStyles();

  return (
      <Parallax filter image={require('assets/img/landing-image.jpg')} darkenShadow={true}>
           <Meta
                title="Reset Success | TPE Hub"
                description="Reset Success"
                page="/reset-success"
            />
          <div className={classes.container} justify="center">
              <WhiteTextTypography variant="h4" align="center" fontWeight="fontWeightMedium">
                  Password Reset Complete
              </WhiteTextTypography>
              <Typography align="center" my={1}>Your password has been set. You may go ahead and login now.</Typography>
              <Grid container justifyContent={'center'}>
                  <ResetButton component={Link} color="primary" size="lg" to="/login" variant="contained" rel="noopener noreferrer">
                      <Typography variant="subtitle1"> Login</Typography>
                  </ResetButton>
              </Grid>
          </div>
      </Parallax>
  );
}
