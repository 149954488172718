import { Card, CardContent, Divider, Grid, IconButton, ButtonGroup, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
import { SumbitMortgageRefinanceCal } from 'redux/actions/MortgageRefinanceActions';
import { mortgageRefinanceCalSuccess } from 'redux/actions/MortgageRefinanceActions';

const MortgageRefinanceCalForm = (props) => {
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const { classes } = props;

    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);
    const [pointsPrefix, setPointsPrefix] = useState('%');
    const [feesPrefix, setFeesPrefix] = useState('$');
    const { handleSubmit, control, formState, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            market_rate: parseFloat(props.loanList[0]?.value, 10),
            borrower_rate: 8,
            mortgage_amount: 1000000.0,
            points: 3,
            fees: 7000.0,
            term: 30,
            payback_period: 3
        }
    });
    const { isDirty, isSubmitting } = formState;

    const onSubmit = (data) => {
        console.log('data fffffff', data);
        let market_rate = data.market_rate.toString();
        let objToPass = {
            borrower_rate: data.borrower_rateUnFormattedValue || data.borrower_rate,
            market_rate: market_rate.replace('%', ''),
            term: data.termUnFormattedValue || data.term,
            mortgage_amount: data.mortgage_amountUnFormattedValue || data.mortgage_amount,
            points: data.pointsUnFormattedValue || data.points,
            fees: data.feesUnFormattedValue || data.fees,
            payback_period: data.payback_periodUnFormattedValue || data.payback_period,
            points_prefix: pointsPrefix,
            fees_prefix: feesPrefix
        };
        // objToPass = { ...objToPass, save_data: data.save_data };
        objToPass = { ...objToPass };
        console.log(objToPass, 'ffffffff objToPass');
        dispatch(SumbitMortgageRefinanceCal(objToPass));
    };

    const handleFormInputChange = () => {
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
        }
        // reset saved output from redux state
        if (isDirty && result.length > 0) {
            dispatch(mortgageRefinanceCalSuccess({}));
        }
    };

    //   // to load saved data in form
    //   const handleLoadData = () => {
    //     setDisabledLoadBtn(true);
    //     const savedData = props.houseAffordData.houseAffordDetails;
    //     for (const key in savedData) {
    //       if (Object.hasOwnProperty.call(savedData, key)) {
    //         let element = savedData[key];
    //         setValue(key, element);
    //       }
    //     }
    //   }

    /**
     * @author Ulyana
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (props.mortgageRefinanceData.mortgageRefinanceData?.data?.data?.length) {
            setResult(props.mortgageRefinanceData.mortgageRefinanceData.data.data);
        } else {
            // to reset the output fields
            if (result.length > 0) setResult([]);
        }
    }, [props.mortgageRefinanceData]);

    /**
     * @author Ulyana
     * @use to set the mortage rate after we get it form the API
     */
    useEffect(() => {
        const data = getValues();
        if (props.loanList.length && !data.market_rate) {
            setValue('market_rate', parseFloat(props.loanList[0]?.value, 10));
        }
    }, [props.loanList]);

    // Toggle between '%' and '$' when the button is clicked
    const handlePrefixChange = (field) => {
        if (field == 'Points') {
            setPointsPrefix((prevPrefix) => (prevPrefix === '%' ? '$' : '%'));
        }
        if (field == 'Fees') {
            setFeesPrefix((prevPrefix) => (prevPrefix === '$' ? '%' : '$'));
        }
    };

    function PrefixDropdown({ handlePrefixChange, selectedPrefix, field }) {
        return (
            <div>
                <select id="prefixSelect" value={selectedPrefix} onChange={() => handlePrefixChange(field)}>
                    <option value="%">%</option>
                    <option value="$">$</option>
                </select>
            </div>
        );
    }

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    {/* {Object.keys(props.houseAffordData.houseAffordDetails).length ? (
                      <Box color="text.primary">
                          <Typography variant="body2" component="p">
                              We have your saved data. Click button to load it.
                              <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="label"
                                  disabled={disabledLoadBtn}
                                  onClick={handleLoadData}
                              >
                                  <CachedIcon color="white" />
                              </IconButton>
                              <Divider sx={{ mb: 1 }} />
                          </Typography>
                      </Box>
                  ) : (
                      ''
                  )} */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={13} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="market_rate"
                                    control={control}
                                    label="Market Rate"
                                    id="market_rate"
                                    options={props?.market_rate}
                                    type="number"
                                    prefix={'%'}
                                    required
                                    customPatternName="decimalRate3"
                                    valueLabelDisplay="on"
                                    max_value="100"
                                />
                            </Grid>
                            <Grid item xs={13} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="borrower_rate"
                                    control={control}
                                    label="Borrower Rate"
                                    id="borrower_rate"
                                    type="number"
                                    required
                                    prefix={'%'}
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="8"
                                    setValue={setValue}
                                    max_value="100"
                                />
                            </Grid>
                            <Grid item xs={13} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="points"
                                    control={control}
                                    label="Points"
                                    id="points"
                                    type="number"
                                    required
                                    //   prefix={
                                    //     <PrefixDropdown
                                    //     handlePrefixChange={handlePrefixChange}
                                    //     selectedPrefix={pointsPrefix}
                                    //     field="Points"
                                    //     />
                                    // }
                                    prefix={
                                        <ButtonGroup
                                            disableElevation
                                            onClick={() => {
                                                handlePrefixChange('Points');
                                            }}
                                            className="toggleButtonGroup"
                                        >
                                            <Button
                                                variant={pointsPrefix === '%' ? 'contained' : 'outlined'}
                                                className="toggleButton"
                                                size="small"
                                            >
                                                %
                                            </Button>
                                            <Button
                                                variant={pointsPrefix === '$' ? 'contained' : 'outlined'}
                                                className="toggleButton"
                                                size="small"
                                            >
                                                $
                                            </Button>
                                        </ButtonGroup>
                                    }
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="3"
                                    setValue={setValue}
                                    valueLabelDisplay="on"
                                    max_value={pointsPrefix === '%' ? 100 : 0}
                                />
                            </Grid>
                            <Grid item xs={13} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="fees"
                                    control={control}
                                    label="Fees"
                                    id="fees"
                                    type="number"
                                    required
                                    //   prefix={
                                    //     <PrefixDropdown
                                    //     handlePrefixChange={handlePrefixChange}
                                    //     selectedPrefix={feesPrefix}
                                    //     field="Fees"
                                    //     />
                                    // }
                                    prefix={
                                        <ButtonGroup
                                            disableElevation
                                            onClick={() => {
                                                handlePrefixChange('Fees');
                                            }}
                                            className="toggleButtonGroup"
                                        >
                                            <Button
                                                variant={feesPrefix === '%' ? 'contained' : 'outlined'}
                                                className="toggleButton"
                                                size="small"
                                            >
                                                %
                                            </Button>
                                            <Button
                                                variant={feesPrefix === '$' ? 'contained' : 'outlined'}
                                                className="toggleButton"
                                                size="small"
                                            >
                                                $
                                            </Button>
                                        </ButtonGroup>
                                    }
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="7,000"
                                    setValue={setValue}
                                    valueLabelDisplay="on"
                                    max_value={feesPrefix === '%' ? 100 : 0}
                                />
                            </Grid>
                            <Grid item xs={13} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="term"
                                    control={control}
                                    label="Loan Term"
                                    id="term"
                                    type="number"
                                    required
                                    helperText="(in years)"
                                    customPatternName="decimalRate"
                                    thousandSeparator={true}
                                    placeholder="30"
                                    setValue={setValue}
                                    valueLabelDisplay="on"
                                />
                            </Grid>
                            <Grid item xs={13} md={6} styles={{ margin: 0 }}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="payback_period"
                                    control={control}
                                    label="Break Even Time"
                                    id="payback_period"
                                    type="number"
                                    required
                                    helperText="(in years)"
                                    customPatternName="decimalRate"
                                    thousandSeparator={true}
                                    placeholder="3"
                                    setValue={setValue}
                                    valueLabelDisplay="on"
                                    max_value="7"
                                />
                            </Grid>
                            <Grid item xs={13} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="mortgage_amount"
                                    control={control}
                                    label="Mortgage Amount"
                                    id="mortgage_amount"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="1,000,000"
                                    setValue={setValue}
                                    valueLabelDisplay="on"
                                />
                            </Grid>
                        </Grid>
                        <CalCardActions className="contactus-cardaction">
                            <CustomButton
                                type="submit"
                                ps={'9px 30px'}
                                variant="contained"
                                // color="rose"
                                disabled={isSubmitting}
                            >
                                Calculate
                            </CustomButton>
                        </CalCardActions>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default MortgageRefinanceCalForm;
