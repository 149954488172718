import React from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { colorScale } from 'utils/Utils';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: theme.spacing(4),
        textAlign: '-webkit-center'
    },
    sticky: {
        backgroundColor: 'rgb(231, 245, 255) !important',
        position: 'sticky',
        left: -2,
        background: 'white',
        fontWeight: 'bold',
        border: '2.5px solid white',
        padding: '10px',
        fontSize: '12px'
    },
    table: {
        borderCollapse: 'collapse',
        width: 'auto'
    },
    tableHeader: {
        fontWeight: 'bold',
        padding: '10px',
        fontSize: '12px'
    },
    tableCell: {
        padding: '10px',
        borderRadius: '4px',
        border: '2.5px solid white',
        fontSize: '12px'
    }
}));

const PortfolioCorrelationTable = ({ correlationMatrix = {} }) => {
    const classes = useStyles();

    // Convert string to JSON object if needed
    const correlationMatrixObject = typeof correlationMatrix === 'string' ? JSON.parse(correlationMatrix) : correlationMatrix;

    // Ensure correlationMatrix is not undefined or null
    if (!correlationMatrixObject || Object.keys(correlationMatrixObject).length === 0) {
        return (
            <Typography variant="h6" className={classes.tableTitle}>
                No data available
            </Typography>
        );
    }

    const tickers = Object.keys(correlationMatrixObject);

    // Validate if the correlationMatrixObject has valid data
    const isValidMatrix = tickers.every((ticker) => {
        const value = correlationMatrixObject[ticker];
        return value && typeof value === 'object' && Object.keys(value).length > 0;
    });

    if (!isValidMatrix) {
        return (
            <Typography variant="h6" className={classes.tableTitle}>
                Invalid data format
            </Typography>
        );
    }

    // // Create a list of numeric values for colorScale
    // const allValues = tickers.flatMap((rowTicker) => {
    //     const rowData = correlationMatrixObject[rowTicker];
    //     return tickers.map((colTicker) => rowData[colTicker]).filter((value) => typeof value === 'number');
    // });

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Typography variant="h6" className={classes.tableTitle} style={{ textAlign: 'center' }}>
                Correlation Table
            </Typography>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow style={{ backgroundColor: 'rgb(231, 245, 255)' }}>
                        <TableCell className={classes.sticky}>Ticker</TableCell>
                        {tickers.map((ticker) => (
                            <TableCell key={ticker} className={classes.tableHeader}>
                                {ticker}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tickers.map((rowTicker) => {
                        const rowData = correlationMatrixObject[rowTicker];
                        const columnData = Object.values(rowData);
                        return (
                            <TableRow key={rowTicker}>
                                <TableCell className={classes.sticky}>{rowTicker}</TableCell>
                                {tickers.map((colTicker) => {
                                    const value = rowData ? rowData[colTicker] : 'N/A';
                                    return (
                                        <TableCell
                                            key={colTicker}
                                            className={classes.tableCell}
                                            style={{
                                                backgroundColor: colorScale(columnData, value, false, true)
                                            }}
                                        >
                                            {value !== undefined && value !== null ? value : 'N/A'}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PortfolioCorrelationTable;
