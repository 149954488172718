import { Container, Grid, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import CompoundCalcForm from './CompoundCalcForm';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import CompoundCalcOutput from './CompoundCalcOutput';
import CompoundCalDesc from './CompoundCalDesc';
import Meta from 'components/meta';

export default function CompoundInterestCalculator(props) {
    const generalCal = useSelector((state) => state.generalCal);
    const navigate = useNavigate();

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'comCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {generalCal.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <CompoundCalcForm compoundCalData={generalCal.compoundCalData} />
                        {/* <CompoundCalcForm /> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <CompoundCalcOutput data={generalCal.compoundCalData} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Budget'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Compound Interest Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Compound Interest Calculator | TPE Hub"
                    description="Use our Compound Interest Calculator to determine how your investments will grow over time. Enter your principal amount, interest rate, and time period to see how compound interest can work for you. Plan your financial future with our easy-to-use tool."
                    page="/compound-interest-calculator"
                />
                <CommonCalculator variantText="Compound Interest Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <CompoundCalDesc />{' '}
                    </Container>
                </MainBox>
            </>
        )
    );
}
