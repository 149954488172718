import CachedIcon from '@mui/icons-material/Cached';
import { Card, CardContent, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import RenderField from 'utils/RenderField';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { submitBudgetCal, submitBudgetCalSuccess } from 'redux/actions/BudgetActions';
import { getCookie } from 'utils/Utils';

const periods = [
    'family_income_period',
    'groceries_period',
    'gym_period',
    'health_ins_period',
    'healthcare_period',
    'leisure_period',
    'misc_exp_period',
    'rent_mortgage_period',
    'transportation_period',
    'utilities_period',
    'vacation_period'
];

const fieldsDetail = [
    {
        name: 'family_income',
        label: 'Family Income',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'rent_mortgage',
        label: 'Rent',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'utilities',
        label: 'Utilities',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'healthcare',
        label: 'Healthcare',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'health_ins',
        label: 'Health Insurance',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'leisure',
        label: 'Leisure',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'groceries',
        label: 'Groceries',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'misc_exp',
        label: 'Misc',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'transportation',
        label: 'Transportation',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    { name: 'gym', label: 'Gym', type: 'number', required: true, commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
    {
        name: 'vacation',
        label: 'Vacation',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    }
];
const BudgetForm = ({ classes, budgetCalData, ...rest }) => {
    const dispatch = useDispatch();
    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);
    const [editData, setEditData] = useState(false);
    const apiToken = getCookie('auth_token');

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty, isSubmitting },
        setValue,
        getValues
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            rent_mortgage: 24000,
            utilities: 1000,
            healthcare: 1000,
            health_ins: 10000,
            leisure: 1000,
            groceries: 1200,
            misc_exp: 1300,
            family_income: 150000,
            transportation: 1000,
            gym: 600,
            vacation: 2000
        }
    });
    useEffect(() => {
        if (rest.profile) {
            setValue('family_income', rest.profile.annualincome || 150000);
        }
    }, [rest.profile]);

    //to submit the form data
    const onSubmit = (data) => {
        // let type = 'post';
        let objToSend = {
            budgetName: data.budgetNameUnFormattedValue || data.budgetName,
            rent_mortgage: data.rent_mortgageUnFormattedValue || data.rent_mortgage,
            utilities: data.utilitiesUnFormattedValue || data.utilities,
            healthcare: data.healthcareUnFormattedValue || data.healthcare,
            health_ins: data.health_insUnFormattedValue || data.health_ins,
            leisure: data.leisureUnFormattedValue || data.leisure,
            groceries: data.groceriesUnFormattedValue || data.groceries,
            misc_exp: data.misc_expUnFormattedValue || data.misc_exp,
            family_income: data.family_incomeUnFormattedValue || data.family_income,
            transportation: data.transportationUnFormattedValue || data.transportation,
            gym: data.gymUnFormattedValue || data.gym,
            vacation: data.vacationUnFormattedValue || data.vacation,
            rent_mortgage_period: data.rent_mortgage_periodUnFormattedValue || data.rent_mortgage_period,
            utilities_period: data.utilities_periodUnFormattedValue || data.utilities_period,
            healthcare_period: data.healthcare_periodUnFormattedValue || data.healthcare_period,
            health_ins_period: data.health_ins_periodUnFormattedValue || data.health_ins_period,
            leisure_period: data.leisure_periodUnFormattedValue || data.leisure_period,
            groceries_period: data.groceries_periodUnFormattedValue || data.groceries_period,
            misc_exp_period: data.misc_exp_periodUnFormattedValue || data.misc_exp_period,
            family_income_period: data.family_income_periodUnFormattedValue || data.family_income_period,
            transportation_period: data.transportation_periodUnFormattedValue || data.transportation_period,
            gym_period: data.gym_periodUnFormattedValue || data.gym_period,
            vacation_period: data.vacation_periodUnFormattedValue || data.vacation_period
        };
        if (data.id && data.update_data) {
            // type = 'put';
            objToSend = {
                ...objToSend,
                budid: data.id
            };
        }
        if (data.save_data) {
            if (!apiToken) {
                toast.error('Please login to save data!');
                return;
            }
            // type = 'post';
            objToSend = { ...objToSend, save_data: data.save_data, budid: undefined };
        }
        dispatch(submitBudgetCal(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
            // setEditData(false);
        }
        if (isDirty && budgetCalData && Object.keys(budgetCalData).length > 0) {
            // dispatch(submitBudgetCalSuccess({}));
        }
    };
    useEffect(() => {
        // reset output when route will change
        return () => {
            dispatch(submitBudgetCalSuccess({}));
            setEditData(false);
        };
    }, []);

    // to load saved data in form
    const handleLoadData = (e = null, loadData = null) => {
        setDisabledLoadBtn(true);
        setEditData(true);
        const savedData = loadData ? loadData : rest.budget.savedBudgetData.length > 0 ? rest.budget.savedBudgetData[0] : {};
        for (const key in savedData) {
            if (Object.hasOwnProperty.call(savedData, key)) {
                let element = savedData[key];
                setValue(key, element);
                periods.map((period) => {
                    setValue(period, 'yearly');
                });
            }
        }
    };

    useEffect(() => {
        if (Object.keys(rest.loadbud).length) {
            handleLoadData(null, rest.loadbud);
            setEditData(true);
        }
    }, [rest.loadbud]);
    useEffect(() => {
        if (rest.resetForm) {
            setEditData(false);
            reset({});
            rest.resetFormFlag(false);
        }
    }, [rest.resetForm]);

    return (
        <>
            <Card className={` ${classes.widgetCalCard}`} elevation={2}>
                <CardContent>
                    {Object.keys(rest.budget.savedBudgetData).length ? (
                        <Box color="text.primary">
                            <Typography variant="body2" component="p">
                                We have your saved data. Click button to load it.
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    disabled={disabledLoadBtn}
                                    onClick={handleLoadData}
                                >
                                    <CachedIcon color="white" />
                                </IconButton>
                            </Typography>
                            <Divider sx={{ mb: 1 }} />
                        </Box>
                    ) : (
                        ''
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} sm={10} key={'budgetFormFields'}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name={"budgetName"}
                                    control={control}
                                    label="Budget Name"
                                    placeholder={"Budget Name"}
                                    id={"budgetName"}
                                    type={"text"}
                                    required={false}
                                    addNew="addNew"
                                />
                            </Grid>
                            {fieldsDetail.map((val, i) => {
                                return (
                                    <>
                                        <Grid item xs={12} sm={5} key={i + 'budgetFormFields'}>
                                            <RenderField
                                                handleOnChange={handleFormInputChange}
                                                name={val.name}
                                                control={control}
                                                label={val.label}
                                                id={val.name}
                                                type={val.type}
                                                required={val.required || true}
                                                thousandSeparator={val.commaSep}
                                                customPatternName={val.custompattern}
                                                placeholder={val.placeholder}
                                                setValue={setValue}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5} key={i + 'budgetFormFields'}>
                                            <RenderField
                                                handleOnChange={handleFormInputChange}
                                                name={val.name + '_period'}
                                                control={control}
                                                style={{ marginTop: '16px' }}
                                                id={val.name + '_period'}
                                                type={'select'}
                                                options={[
                                                    { label: 'Yearly', value: 'yearly' },
                                                    { label: 'Monthly', value: 'monthly' }
                                                ]}
                                                required={true}
                                                defaultValue={'yearly'}
                                            />
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                        <CalCardActions className="contactus-cardaction">
                            <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                {/* <Grid container justifyContent={'center'}> */}
                                <Grid item xs="auto">
                                    <CustomButton
                                        type="submit"
                                        ps={'9px 30px'}
                                        onClick={() => {
                                            setValue('update_data', false);
                                            setValue('save_data', false);
                                        }}
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >
                                        Calculate
                                    </CustomButton>
                                </Grid>
                                {Object.keys(rest.budget.savedBudgetData).length > 0 &&
                                    (Object.keys(rest.loadbud).length > 0 || editData) ? (
                                    <Grid item xs>
                                        <Tooltip title='Apply updates to your budget'>
                                            <CustomButton
                                                type="submit"
                                                ps={'9px 30px'}
                                                onClick={() => {
                                                    setValue('update_data', true);
                                                    setValue('save_data', false);
                                                }}
                                                variant="contained"
                                                disabled={isSubmitting}
                                            >
                                                Update
                                            </CustomButton>
                                        </Tooltip>
                                    </Grid>
                                ) : (
                                    ''
                                )}
                                <Grid item xs>
                                    <Tooltip title='Click to save your budget'>
                                        <CustomButton
                                            type="button"
                                            ps={'9px 30px'}
                                            onClick={() => {
                                                setValue('update_data', false);
                                                setValue('save_data', true);
                                                onSubmit(getValues());
                                            }}
                                            variant="outlined"
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </CustomButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </CalCardActions>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default BudgetForm;
