import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    createStyles,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import AddDataDialog from "./AddDataDialog";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/system';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { LargeContainer, MainBox, StyledTableSlider } from 'assets/jss/GeneralStyle';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import portfolioPageStyle from 'assets/jss/material-kit-react/views/portfolioPage';
import { AddUserOptionBox, RefreshBox, TopBlockBox } from 'assets/jss/PortfolioStyle';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import Loader from 'components/CommonComponent/Loader';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { CurrencyList } from 'Currency.json';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { submitMutualFundTicker } from 'redux/actions/MutualFundActions';
import {
    deletePortfolio,
    fetchPortfolioList,
    smartTblPortfolio,
    smartTblPortfolioError,
    submitPortfolioData
} from 'redux/actions/PortfolioActions';
import RenderField from 'utils/RenderField';
import { abbreviate_number, compareNumericString, dynamicSort, getCookie, numberWithCommas, optionIncludes, titleCase } from 'utils/Utils';
import { MutualFundViewPopup } from 'views/MutualFund/MutualFundViewPopup';
import AddUserDialog from './AddUserDialog.jsx';
import PortfolioBreadcrumb from './PortfolioBreadcrumb';
import PortfolioDesc from './PortfolioDesc';
import PortfolioOutput from './PortfolioOutput';
import PortfolioTopBlock from './PortfolioTopBlock';
import PortfolioUpload from './PortfolioUpload';
import ViewOptions from './ViewOptions';
import { a11yProps } from 'utils/Utils.js';
import PortfolioTabPanel from './PortfolioTabPanel.js';
import { WidgetBox } from 'assets/jss/GeneralStyle.jsx';
import { TableSearchBox } from 'assets/jss/PortfolioStyle.jsx';
import { fetchPortfolioChart } from 'redux/actions/PortfolioActions.js';
import PortfolioChart from './PortfolioChart.jsx';
import { resetCloseViewPopup } from 'redux/actions/MutualFundActions.js';
import CustomTooltip from 'components/CommonComponent/CustomTooltip.js';
import * as tooltip from 'Tooltip.json';
import Button from 'components/CustomButtons/Button';
import { fetchSavedData, submitHomeValuationCal } from 'redux/actions/HomeCalActions.js';
import CirclePackingChart from 'components/Charts/CirclePackingChart.js';
import { getConnectedAccountDetail } from 'redux/actions/SettingActions.js';
import { getInvestmentHoldings } from 'redux/actions/PortfolioActions.js';
import { getInvestmentTransactions } from 'redux/actions/PortfolioActions.js';
// import Paper from '@mui/material';
import moment from 'moment';
import DoRegistration from 'views/DoRegistration.jsx';
import Tour from 'reactour';
import './index.css';
import Meta from 'components/meta.jsx';

const tabs = [
    { label: 'Holdings' },
    // { label: 'Investment Holdings & Securities' },
    { label: 'Investment Transactions' },
    { label: 'Upload Portfolio' }
];

var currList = [...CurrencyList];
currList.sort(dynamicSort('code'));
// https://codesandbox.io/s/1j68j?file=/demo.js:0-1134
const handleOptions = () =>
    currList.map((val) => {
        return {
            value: val.code,
            label: val.code + `(${val.name})`,
            symbol: val.symbol,
            flag: val.flag
        };
    });

export const includes = (rows, ids, filterValue) => {
    return rows.filter((row) => {
        return ids.some((id) => {
            const rowValue = row.values[id]?.toLowerCase() || '-';
            return filterValue === 'all' ? true : rowValue ? rowValue.includes(filterValue) : rowValue === filterValue;
        });
    });
};
includes.autoRemove = (val) => !val || !val.length;

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...portfolioPageStyle(theme),
        ...styles(theme)
    })
);

const acceptedBanks = ['Wells Fargo', 'PNC', 'Vanguard'];

export default function PortfolioIndex(props) {
    const theme = useTheme();
    const classes = mergedStyle();
    const searchcontainer = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const portfolioData = useSelector((state) => state.portfolio);
    const homevalDetail = useSelector((state) => state.homecal);
    const setting = useSelector((state) => state.setting);
    const [homeValPrice, setHomeValPrice] = useState(null);
    const [homeVal, setHomeVal] = useState(false);
    const mutualfund = useSelector((state) => state.mutualfund);
    const [holdingsData, setHoldingsData] = useState([]);
    const [viewData, setOpenViewDialog] = useState(false);
    const [colorScaleArr, setColorScaleArr] = useState([]);
    const [totalVal, setTotalVal] = useState(null);
    const [topfive, setTopFive] = useState([]);
    const [bottomfive, setBottomFive] = useState([]);
    const [correlation, setCorrelation] = useState([]);
    const [avgPer, setAvgPer] = useState(null);
    const [dayChange, setDayChange] = useState('');
    const [summaryData, setSummaryData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedPortCount, setSelectedPortCount] = useState([]);
    const [enableEdit, setEnableEdit] = useState({});
    const [renderBreadCrumb, setRenderBreadCrumb] = useState([]);
    const [updatedVal, setUpdatedVal] = useState({});
    const [enableChart, setEnableChart] = useState({});
    const [renderHoldingList, setHoldingList] = useState(false);
    const [isManualClicked, setManualClicked] = useState(false);
    const [enableEdits, setEnableEdits] = useState([]);
    const [selectedRowsSet, setSelectedRowsSet] = useState({});
    const [currentAction, setCurrentAction] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [hidePortfolioMessage, setHidePortfolioMessage] = useState(true);
    const [banksToShow, setBanksToShow] = useState([]);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isBankFile, setIsBankFile] = useState(false);
    const fileList = [...portfolioData.portfolioList];
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [showHomeValTrue, setShowHomeValTrue] = useState(true);
    const toggleShowHomeValTrue = () => setShowHomeValTrue((prev) => !prev);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'portVal';
        }
    }, []);

    const handleButtons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {currentStep !== 0 && (
                    <button
                        style={{
                            padding: '10px',
                            border: '1px solid black',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            fontSize: '18px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep - 1)}
                    >
                        Back
                    </button>
                )}
                {currentStep !== steps.length - 1 ? (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep + 1)}
                    >
                        Continue
                    </button>
                ) : (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => closeTour()}
                    >
                        Finish
                    </button>
                )}
            </div>
        );
    };

    const steps = [
        {
            selector: windowWidth < 600 ? '' : '#summary',
            content: () => (
                <>
                    <h4>Summary</h4>
                    <p>
                        Welcome to Summary! here you can see the data cards including Total Value, Day Change, Total Winners and Bottom
                        Losers.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 600 ? '' : '#allocation',
            content: () => (
                <>
                    <h4>Allocation</h4>
                    <p>Now, let me introduce you to Allocation tab! here by clicking on this tab, you can see different charts.</p>
                    {handleButtons()}
                </>
            )
        },

        {
            selector: windowWidth < 600 ? '' : '#correlation',
            content: () => (
                <>
                    <h4>Additional Fund Metrics</h4>
                    <p>
                        Now, let me introduce you to Additional Fund Metrics tab! here by clicking on this tab, you can see Correlation
                        groupings and Sharpe ratio.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#link',
            content: () => (
                <>
                    <h4>External Account</h4>
                    <p>
                        Now, let me introduce you to External Account! here by clicking on this button. you can link external account if you
                        have not added.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#Holdi',
            content: () => (
                <>
                    <h4>Holdings</h4>
                    <p>Now, let me introduce you to Holdings! here by clicking on this tab. you can see the holdings table.</p>
                    {handleButtons()}
                </>
            )
        },
        // {
        //     selector: '#ihs',
        //     content: () => (
        //         <>
        //             <h4>Investment Holdings & Securities</h4>
        //             <p>
        //                 Now, let me introduce you to Investment Holdings & Securities! here by clicking on this tab. you can see the
        //                 Investment Holdings & Securities table.
        //             </p>
        //             {handleButtons()}
        //         </>
        //     )
        // },
        {
            selector: windowWidth < 600 ? '' : '#Inves',
            content: () => (
                <>
                    <h4>Investment Transactions</h4>
                    <p>
                        Now, let me introduce you to Investment Transactions! here by clicking on this tab. you can see the Investment
                        Transactions table.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 690 ? '' : '#Uploa',
            content: () => (
                <>
                    <h4>Upload Portfolio</h4>
                    <p>
                        Now, let me introduce you to Upload Portfolio! here by clicking on this tab. you can upload portfolio via text data
                        or local file.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 865 ? '' : '#new-data',
            content: () => (
                <>
                    <h4>Add Portfolio</h4>
                    <p>
                        Now, let me introduce you to Add Portfolio! here by clicking on this tab. you will have a modal, via modal you can
                        add portfolio.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 1100 ? '' : '#view',
            content: () => (
                <>
                    <h4>View Options</h4>
                    <p>
                        Now, let me introduce you to View Options! here by clicking on this tab. you will have a modal, via modal you can
                        select views for table.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 1255 ? '' : '#refresh',
            content: () => (
                <>
                    <h4>Load Data</h4>
                    <p>Now, let me introduce you to Load Data! here by clicking on this link. you can reload the table data.</p>
                    {handleButtons()}
                </>
            )
        }
    ];

    const closeTour = () => {
        setIsTourOpen(false);
        document.body.style.overflow = 'auto';
    };

    const handleTour = () => {
        setIsTourOpen(true);
        document.body.style.overflow = 'hidden';
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        const banks = setting.plaidDetail.user_detail
            .filter((bank) => acceptedBanks.includes(bank.bank_name))
            .map((bank) => {
                return {
                    id: bank.id,
                    name: bank.bank_name
                };
            });
        setBanksToShow(banks);
    }, [setting]);

    if (setting.profileDetail.allow_investment_in_portfolio) {
        let flag = true;
        for (let bank of setting.plaidDetail.user_detail) {
            if (flag) {
                for (let acc of bank?.accounts) {
                    if (flag && acc?.type === 'investment') {
                        flag = false;
                        fileList.push({
                            id: `${bank.id}**`,
                            file_name: `Bank Data: ${bank.bank_name}`,
                            is_bank: true,
                            type: acc?.type
                        });
                    }
                }
            }
        }
    }

    useEffect(() => {
        dispatch(getInvestmentHoldings(banksToShow));
        dispatch(getInvestmentTransactions(banksToShow));
    }, [banksToShow]);

    useEffect(() => {
        if (Object.keys(portfolioData?.selectedPortfolioDetail).length) {
            setTotalVal(portfolioData.selectedPortfolioDetail['Total Value']?.toFixed(2));
            setDayChange(portfolioData.selectedPortfolioDetail['Day Change']?.toFixed(2));
            setBottomFive(portfolioData.selectedPortfolioDetail['bottom_5_df']);
            setCorrelation(portfolioData.selectedPortfolioDetail['correlation_tab']);
            setAvgPer(portfolioData.selectedPortfolioDetail['Avg_Per']);
            setTopFive(portfolioData.selectedPortfolioDetail['top_5_df']);
            const holdingData = portfolioData.selectedPortfolioDetail.holdings;
            const filteredHoldingData = holdingData.filter((item) => showHomeValTrue || item.home_val !== true);
            setHoldingsData(filteredHoldingData);
            setHidePortfolioMessage(portfolioData.selectedPortfolioDetail['home_val']);
            const keysGroupingArray = ['institution_name', 'broad_asset_type', 'symbol'];

            const result = {};

            for (const item of holdingData) {
                if (!result[item[keysGroupingArray[0]]]) {
                    result[item[keysGroupingArray[0]]] = {
                        id: `firstNode-${item[keysGroupingArray[0]]}`,
                        name: item[keysGroupingArray[0]],
                        children: []
                    };
                }

                const firstNode = result[item[keysGroupingArray[0]]];

                let secondNode = firstNode.children.find((node) => node.name === item[keysGroupingArray[1]]);

                if (!secondNode) {
                    secondNode = {
                        id: `secondNode-${item[keysGroupingArray[0]]}-${item[keysGroupingArray[1]]}`,
                        name: item[keysGroupingArray[1]],
                        children: []
                    };
                    firstNode.children.push(secondNode);
                }

                secondNode.children.push({
                    id: `thirdNode-${item[keysGroupingArray[0]]}-${item[keysGroupingArray[1]]}-${item[keysGroupingArray[2]]}`,
                    name: item[keysGroupingArray[2]],
                    value: item.quantity
                });
            }

            const formatCirclePackChartData = {
                id: 'rootNode',
                name: 'holdings',
                children: Object.values(result)
            };
            if (portfolioData.selectedPortfolioDetail?.summary) {
                if (Object.keys(portfolioData.selectedPortfolioDetail?.summary).length > 0) {
                    const arrOfSummary = portfolioData.selectedPortfolioDetail.summary;
                    if (Object.keys(arrOfSummary).length > 0) {
                        const formatChartData = [],
                            formatTreeChartData = [{ name: 'Broad Asset Type', children: [] }];
                        const { 'Narrow Assets': narrow_type_table, ...rest } = arrOfSummary;
                        let parentarr = [];
                        //  make format to display tree map chart
                        for (let index = 0; index < narrow_type_table.length; index++) {
                            const element = narrow_type_table[index];
                            let { All, ...parentCat } = element;
                            //  condition to create data which do not have child value
                            if (narrow_type_table.length === index + 1) {
                                const { lowernarrow, ...remainCat } = parentCat;
                                Object.keys(remainCat).forEach((val, i) => {
                                    const index = parentarr.findIndex((pv) => pv.name === val);
                                    if (index === -1) {
                                        parentarr.push({ name: val, loc: parentCat[val] });
                                    }
                                });
                            } else {
                                // loop to create structure for data who have child value
                                Object.keys(parentCat).forEach((val, i) => {
                                    var child = { name: parentCat['lowernarrow'], loc: 0 };
                                    if (parentCat[val] > 0) {
                                        child['loc'] = parentCat[val];
                                        const index = parentarr.findIndex((pv) => pv.name === val);
                                        if (index !== -1) {
                                            parentarr[index].children.push(child);
                                        } else {
                                            parentarr.push({ name: val, children: [child] });
                                        }
                                    }
                                });
                            }
                        }
                        Object.keys(rest).forEach((val, index) => {
                            const element = rest[val];
                            const individualChartData = [];
                            const marketValues = {};
                            const holdingKey =
                                val === 'Account Type'
                                    ? 'account_type'
                                    : val === 'Institution'
                                    ? 'institution_name'
                                    : val === 'Investment Type'
                                    ? 'broad_asset_type'
                                    : 'broad_asset_type';
                            portfolioData.selectedPortfolioDetail['holdings'].forEach((holding) => {
                                if (marketValues[holding[holdingKey]] === undefined) {
                                    marketValues[holding[holdingKey]] = holding['market_value'];
                                } else {
                                    marketValues[holding[holdingKey]] += holding['market_value'];
                                }
                            });
                            if (val === 'Investment Type') {
                                for (let key in marketValues) {
                                    marketValues[key.toLowerCase()] = marketValues[key];
                                }
                            }
                            Object.keys(element).forEach((objKey) => {
                                individualChartData.push({
                                    id: objKey,
                                    label: objKey,
                                    value: element[objKey],
                                    percentageValue: element[objKey],
                                    marketValue: marketValues[objKey]
                                });
                            });
                            formatChartData.push([[val], individualChartData]);
                        });
                        formatTreeChartData[0].children = parentarr;
                        setSummaryData({ pie: formatChartData, tree: formatTreeChartData, circlePack: formatCirclePackChartData });
                    }
                }
            }

            //  else {
            //     const holdingData = portfolioData.selectedPortfolioDetail;
            //     setHoldingsData(holdingData);
            //     const keysGroupingArray = ['institution_name', 'broad_asset_type', 'symbol'];

            //     const result = {};

            //     for (const item of holdingData) {
            //         if (!result[item[keysGroupingArray[0]]]) {
            //             result[item[keysGroupingArray[0]]] = {
            //                 id: `firstNode-${item[keysGroupingArray[0]]}`,
            //                 name: item[keysGroupingArray[0]],
            //                 children: []
            //             };
            //         }

            //         const firstNode = result[item[keysGroupingArray[0]]];

            //         let secondNode = firstNode.children.find((node) => node.name === item[keysGroupingArray[1]]);

            //         if (!secondNode) {
            //             secondNode = {
            //                 id: `secondNode-${item[keysGroupingArray[0]]}-${item[keysGroupingArray[1]]}`,
            //                 name: item[keysGroupingArray[1]],
            //                 children: []
            //             };
            //             firstNode.children.push(secondNode);
            //         }

            //         secondNode.children.push({
            //             id: `thirdNode-${item[keysGroupingArray[0]]}-${item[keysGroupingArray[1]]}-${item[keysGroupingArray[2]]}`,
            //             name: item[keysGroupingArray[2]],
            //             value: item.quantity
            //         });
            //     }
            // }
        }
    }, [portfolioData, showHomeValTrue]);

    // This is a custom filter UI for selecting
    // a unique option from a list
    function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                options.add(row.values[id]?.toLowerCase().trim() || '-');
            });
            let list = [...options.values()];
            list = list.map((option, i) => ({ value: option, label: titleCase(option) }));
            list.unshift({ value: 'all', label: 'All' });
            return list;
        }, [id, preFilteredRows]);
        // Render a multi-select box
        return (
            <CustomSelect
                disableEmptyValue={true}
                defaultValue={filterValue || 'all'}
                formControlProps={{
                    fullWidth: true
                }}
                options={options}
                onChange={(e) => {
                    setFilter(e.target.value || null);
                }}
                size="small"
            />
        );
    }
    // Define a custom filter filter function!
    function filterGreaterThan(rows, id, filterValue) {
        return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue >= filterValue;
        });
    }

    const handleButtonClick = () => {
        setIsOpen(true);
        if (homevalDetail?.homeValuationDetail?.data?.length > 0) {
            setHomeValPrice({
                priceOverride: homevalDetail.homeValuationDetail.data[2].value,
                symbol: homevalDetail?.savedHomeValuationDetail.property_name,
                quantity: homevalDetail?.savedHomeValuationDetail.purchase_price
            });
        }
    };

    useEffect(() => {
        if (homevalDetail?.homeValuationDetail?.data?.length > 0) {
            setHomeVal(true);
        }
    }, [homevalDetail.homeValuationDetail.data]);

    // This is a custom filter UI that uses a
    // slider to set the filter value between a column's
    // min and max values
    function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
        // Calculate the min and max
        // using the preFilteredRows

        const [min, max] = useMemo(() => {
            let min = preFilteredRows.length ? preFilteredRows[0].values[id] ?? 0 : 0;
            let max = preFilteredRows.length ? preFilteredRows[0].values[id] ?? 0 : 0;
            preFilteredRows.forEach((row) => {
                min = Math.min(row.values[id] ?? 0, min);
                max = Math.max(row.values[id] ?? 0, max);
            });
            return [min, max];
        }, [id, preFilteredRows]);
        return (
            <>
                <div className={classes.sliderWrapperFilter}>
                    <StyledTableSlider
                        id={id}
                        key={id}
                        value={filterValue || min}
                        min={min}
                        max={max}
                        onChange={(e, val) => setFilter(parseInt(val, 10))}
                    />
                    <span className={classes.sliderValue}>{abbreviate_number(filterValue || min)}</span>
                </div>
                <CustomButton className={classes.offBtn} variant="contained" size="small" onClick={() => setFilter(undefined)}>
                    Off
                </CustomButton>
            </>
        );
    }
    const requiredFields = ['PrinAmount', 'Maturity Years', 'Repay Type', 'Interest Rate', 'Debt Type'];
    // Create an editable cell renderer
    const EditableCell = (initialValue, type, row, cell, options = [], extra = { commaSep: true }) => {
        let valToPass = initialValue || '',
            custompattern = '';
        // We need to keep and update the state of the cell normally
        const [value, setCellValue] = useState(updatedVal);

        switch (type) {
            case 'number':
                custompattern = 'decimalRate';
                valToPass = extra.commaSep
                    ? !isNaN(initialValue) && initialValue?.toString().indexOf('.') !== -1
                        ? numberWithCommas(initialValue?.toFixed(2))
                        : numberWithCommas(initialValue)
                    : initialValue;
                break;
            case 'select':
                valToPass = initialValue?.toString().toLowerCase();
                break;
            case 'searchSelect':
                valToPass = initialValue;
                break;
            case 'datetime':
                valToPass = initialValue;
                break;
            default:
                custompattern = 'validString';
                break;
        }
        valToPass = valToPass || '';
        if (value[cell.column.id] !== undefined && value[cell.column.id] !== null) {
            valToPass = value[cell.column.id];
        }
        const id = [row.original.pid, cell.column.id].join('_');
        // Check to make sure not all columns are editable
        return (
            <RenderField
                label={''}
                id={id}
                defaultValue={valToPass}
                name={`${row.values.pid}__${cell.column.id}`}
                type={type}
                renderOption={(props, option) => {
                    return (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.flag !== '' ? (
                                <img loading="lazy" width="20" src={'img/flags/' + option.flag} alt="country flags" />
                            ) : (
                                ''
                            )}
                            <Typography variant="body2">{option.label}</Typography>
                        </Box>
                    );
                }}
                fieldName={cell.column.id}
                options={options}
                control={control}
                required={requiredFields.includes(cell.column.Header)}
                thousandSeparator={extra?.commaSep || false}
                customPatternName={extra?.custompattern || custompattern}
                setValue={setValue}
                {...extra}
            />
        );
    };
    useEffect(() => {
        // get all list of uploaded portfolios
        dispatch(fetchPortfolioList());
        dispatch(fetchSavedData());
        dispatch(getConnectedAccountDetail());
        // getHomevaluation();
    }, []);

    useEffect(() => {
        if (Object.keys(homevalDetail?.savedHomeValuationDetail).length) {
            const val = homevalDetail?.savedHomeValuationDetail;
            const data = {
                purchase_year: val?.purchase_year,
                purchase_quarter: val?.purchase_quarter,
                state: val?.state,
                region_name: val?.region_name,
                purchase_price: val?.purchase_price,
                adjustment_multiplier: val?.adjustment_multiplier,
                property_name: val?.property_name,
                mortgage_amount: val?.mortgage_amount ? val?.mortgage_amount : 0,
                country: val?.country ? val?.country : 'USA',
                currency: val?.currency ? val?.currency : 'USD',
                percentage_owned: val?.percentage_owned ? val?.percentage_owned : 100
            };
            dispatch(submitHomeValuationCal(data));
        }
    }, [homevalDetail?.savedHomeValuationDetail]);

    const handleTabChange = (event, newValue) => typeof newValue !== 'undefined' && setSelectedTab(newValue);

    // delete selected files
    const handleFileDelete = (list) => {
        dispatch(deletePortfolio(list));
    };
    //call api to upload selected file portfolio
    const handlePortfolioUpload = (data) => {
        dispatch(submitPortfolioData(data));
    };

    function groupByStartingKey(input) {
        const result = [];

        for (const [key, value] of Object.entries(input)) {
            const idx = key.indexOf('__');
            if (idx < 0) {
                // no double underscore, create new object for single key
                result.push({ pid: key, [key]: value });
            } else {
                // split the key into prefix and suffix
                const pid = key.substring(0, idx);
                const suffix = key.substring(idx + 2);
                // find existing object with pid or create new object if not exist
                const group = result.find((obj) => obj.pid === pid) || { pid };
                group[suffix] = value;
                if (!result.includes(group)) {
                    result.push(group);
                }
            }
        }
        return result;
    }

    const { handleSubmit, control, setValue, reset } = useForm({
        mode: 'onChange'
    });
    useEffect(() => {
        // reset all state flag whenever form is submitted
        if (portfolioData?.submitSmartTblSuccess) {
            setEnableEdits([]);
            setUpdatedVal({});
            setHoldingList(true);
            dispatch(smartTblPortfolioError());
        }
    }, [portfolioData?.submitSmartTblSuccess]);
    useEffect(() => {
        // reset all state flag whenever form is submitted
        if (mutualfund?.closeViewPopup) {
            handleClose();
            dispatch(resetCloseViewPopup());
        }
    }, [mutualfund?.closeViewPopup]);

    const deleteSelectedData = (ids) => dispatch(smartTblPortfolio(ids, 'delete'));
    const handleMutualSymbol = (symbol) => {
        setOpenViewDialog(true);
        dispatch(submitMutualFundTicker({ tickers: [symbol], from: 'portfolio' }, 'post'));
    };
    // close the dialog
    const handleClose = () => {
        setOpenViewDialog(false);
    };
    const handleSymbolClick = (data) => {
        setEnableChart(data);
        dispatch(fetchPortfolioChart(data.symbol));
    };
    const hideColumnList = getCookie('selected_columns') ? JSON.parse(getCookie('selected_columns')) : {};
    const [anchorActionEl, setActionAnchorEl] = React.useState(null);
    const openActionMenu = Boolean(anchorActionEl);
    const idAction = openActionMenu ? 'simple-popover' : undefined;
    const handleActionClick = (event) => {
        event.preventDefault();
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(null);
    };
    const columns = useMemo(() => {
        const emptySelectionOfColumn = Object.keys(hideColumnList).length === 0;
        const result = [
            {
                Header: () => null,
                accessor: 'pid',
                isVisible: false
            },
            {
                Header: (val) => {
                    return (
                        <>
                            Actions <CustomTooltip tooltipText={tooltip['Bulk Edit']} customTooltipStyle={{ maxWidth: 450 }} />
                            <IconButton aria-describedby={'action menu'} variant="contained" onClick={handleActionClick}>
                                <MoreVertIcon></MoreVertIcon>
                            </IconButton>
                            {currentAction === 'BULK_EDIT' && enableEdits.length > 0 && Object.keys(enableEdits?.[0]).length > 0 && (
                                <>
                                    <Tooltip title="Cancel">
                                        <IconButton
                                            aria-label="cancel"
                                            onClick={(e) => {
                                                setEnableEdits([]);
                                                setSelectedRowsSet({});
                                                setCurrentAction('');
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Save">
                                        <IconButton type="submit" aria-label="save">
                                            <DoneIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    );
                },
                disableSortBy: true,
                accessor: 'Action',
                disableFilters: true,
                // sticky: true,
                // width: 120,
                Cell: ({ row }) => {
                    let actionIcons,
                        allowLightIcon = ['Mutual Funds', 'ETF', 'Mutual Fund'];
                    if (enableEdits?.[0]?.[row.original.pid] && currentAction !== 'BULK_EDIT') {
                        actionIcons = (
                            <>
                                <Tooltip title="Cancel">
                                    <IconButton
                                        aria-label="cancel"
                                        onClick={(e) => {
                                            setEnableEdits([]);
                                            setSelectedRowsSet({});
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save">
                                    <IconButton type="submit" aria-label="save">
                                        <DoneIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                        // );
                    } else {
                        actionIcons = (
                            <>
                                {row.original?.pid && (
                                    <>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                aria-label="delete"
                                                disabled={currentAction === 'BULK_EDIT'}
                                                onClick={() => {
                                                    delete selectedRowsSet[row.original.pid];
                                                    deleteSelectedData([row.original.pid]);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton
                                                aria-label="edit"
                                                disabled={currentAction === 'BULK_EDIT'}
                                                onClick={(e) => {
                                                    reset();
                                                    setUpdatedVal({});
                                                    setSelectedRowsSet({});
                                                    setEnableEdits([{ [row.original.pid]: row.original }]);
                                                }}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </>
                        );
                        // );
                    }
                    return (
                        <div className={'actionWrapper'}>
                            {actionIcons}
                            {allowLightIcon.includes(row.original.quote_type) && (
                                <Tooltip title="Mutual Fund Detail">
                                    <IconButton
                                        aria-label="Mutual Fund Detail"
                                        disabled={currentAction === 'BULK_EDIT'}
                                        onClick={(e) => handleMutualSymbol(row.original.symbol)}
                                    >
                                        <FlashOnIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    );
                }
            },
            {
                Header: 'Symbol',
                sticky: true,
                width: 100,
                isVisible: hideColumnList['Symbol'] === true || emptySelectionOfColumn || false,
                accessor: 'symbol',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? (
                        EditableCell(value, 'text', row, cell, [], { custompattern: /^([a-z0-9A-Z#*&_ ]{1,20})$/g })
                    ) : (
                        <CustomButton variant="text" onClick={() => handleSymbolClick(row.original)}>
                            {value}
                        </CustomButton>
                    );
                }
            },
            {
                Header: 'Company Name',
                accessor: 'company_name',
                isVisible: hideColumnList['Company Name'] === true || emptySelectionOfColumn || false,
                Cell: ({ value }) => value || '-'
            },
            // {
            //     Header: 'Institition Id',
            //     accessor: 'institution_id',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            // {
            //     Header: 'Type',
            //     accessor: 'type',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            {
                Header: 'Cost Basis',
                accessor: 'cost_basis',
                Cell: ({ value }) => {
                    return value || '0';
                }
            },
            // {
            //     Header: 'Institution Price',
            //     accessor: 'institution_price',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            // {
            //     Header: 'Institution Price As Of',
            //     accessor: 'institution_price_as_of',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            // {
            //     Header: 'Institution Value',
            //     accessor: 'institution_value',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            // {
            //     Header: 'Close Price',
            //     accessor: 'close_price',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            // {
            //     Header: 'Close Price As Of',
            //     accessor: 'close_price_as_of',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            {
                Header: 'Is Cash Equivalent',
                accessor: 'is_cash_equivalent',
                Cell: ({ value }) => {
                    return value ? 'Yes' : 'No';
                }
            },
            // {
            //     Header: 'Institution Name',
            //     accessor: 'bank_name',
            //     Cell: ({ value }) => {
            //         return value || '-';
            //     }
            // },
            {
                Header: 'Capital Gains And Losses',
                accessor: 'gains',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Price',
                id: 'Price',
                isVisible: hideColumnList['Price'] === true || emptySelectionOfColumn || false,
                sortType: compareNumericString,
                accessor: (value) => numberWithCommas(value.price?.toFixed(2))
            },
            {
                Header: 'Market Value',
                isVisible: hideColumnList['Market Value'] === true || emptySelectionOfColumn || false,
                accessor: 'market_value',
                width: 200,
                Cell: ({ value, row, cell }) => numberWithCommas(parseInt(value)),
                colorScaleArr: colorScaleArr['market_value'],
                isMiddleColorYellow: true,
                Filter: SliderColumnFilter,
                filter: filterGreaterThan
            },
            {
                Header: '% Change',
                isVisible: hideColumnList['% Change'] === true || emptySelectionOfColumn || false,
                accessor: 'per_change',
                width: 200,
                sortType: compareNumericString,
                Cell: ({ value, row, cell }) => (value && numberWithCommas(value?.toFixed(2))) || '-',
                colorScaleArr: colorScaleArr['per_change'],
                isMiddleColorYellow: true
            },
            {
                Header: '$ Change',
                isVisible: hideColumnList['$ Change'] === true || emptySelectionOfColumn || false,
                accessor: 'dollar_change',
                sortType: compareNumericString,
                width: 200,
                Cell: ({ value, row, cell }) => (value && numberWithCommas(value?.toFixed(2))) || '-',
                colorScaleArr: colorScaleArr['dollar_change'],
                isMiddleColorYellow: true
            },
            {
                Header: 'Legal Type',
                isVisible: hideColumnList['Legal Type'] === true || emptySelectionOfColumn || false,
                accessor: 'quote_type',
                sortType: compareNumericString,
                Filter: SelectColumnFilter,
                filter: includes,
                width: 200,
                Cell: ({ value, row, cell }) => value || '-'
            },
            {
                Header: 'Maturity Date',
                accessor: 'maturity_date',
                isVisible: hideColumnList['Maturity Date'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdits?.[0]?.[row.original.pid]
                                ? EditableCell(value, 'datetime', row, cell, [], { timeFormat: false })
                                : (value && moment(value).format('MM/DD/YYYY')) || '-'}
                        </div>
                    );
                }
                // enableEdit.id === row.original.id
                //     ? EditableCell(value, 'datetime', row, cell, [], { timeFormat: false })
                //     : (value && moment(value, 'YYYY/MM/DD').format('MM/DD/YYYY')) || '-'
            },
            {
                Header: 'who',
                isVisible: hideColumnList['who'] === true || emptySelectionOfColumn || false,
                accessor: 'who',
                Filter: SelectColumnFilter,
                filter: includes,
                width: 100,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdits?.[0]?.[row.original.pid]
                                ? EditableCell(value, 'select', row, cell, [
                                      { value: 'me', label: 'For Me' },
                                      { value: 'another', label: 'For Another' }
                                  ])
                                : value || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'what',
                isVisible: hideColumnList['what'] === true || emptySelectionOfColumn || false,
                accessor: 'what',
                width: 150,
                Filter: SelectColumnFilter,
                filter: optionIncludes,
                Cell: ({ value, row, cell }) => {
                    const trimmedval = value?.trim();
                    return (
                        <div>
                            {enableEdits?.[0]?.[row.original.pid]
                                ? EditableCell(trimmedval, 'select', row, cell, [
                                      { value: 'bank', label: 'Bank' },
                                      { value: 'brokerage', label: 'Brokerage' },
                                      { value: 'other', label: 'Other' }
                                  ])
                                : trimmedval || value || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Tradeable',
                isVisible: hideColumnList['Tradeable'] === true || emptySelectionOfColumn || false,
                accessor: 'tradeable',
                Filter: SelectColumnFilter,
                filter: optionIncludes,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdits?.[0]?.[row.original.pid]
                                ? EditableCell(value, 'select', row, cell, [
                                      { value: 'yes', label: 'Yes' },
                                      { value: 'no', label: 'No' }
                                  ])
                                : value || '-'}{' '}
                        </div>
                    );
                }
            },
            {
                Header: 'Position Name',
                isVisible: hideColumnList['Position Name'] === true || emptySelectionOfColumn || false,
                accessor: 'position_name',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            {
                Header: 'Quantity',
                isVisible: hideColumnList['Quantity'] === true || emptySelectionOfColumn || false,
                accessor: 'quantity',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid]
                        ? EditableCell(value, 'number', row, cell)
                        : numberWithCommas(value.toFixed(2));
                }
            },
            {
                Header: 'Price Override',
                isVisible: hideColumnList['Price Override'] === true || emptySelectionOfColumn || false,
                accessor: 'price_override',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid]
                        ? EditableCell(value, 'number', row, cell)
                        : value
                        ? numberWithCommas(value?.toFixed(2))
                        : '-';
                }
            },
            {
                Header: 'Currency',
                isVisible: hideColumnList['Currency'] === true || emptySelectionOfColumn || false,
                accessor: 'currency',
                Filter: SelectColumnFilter,
                filter: optionIncludes,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid]
                        ? EditableCell(value, 'searchSelect', row, cell, handleOptions())
                        : value || '-';
                }
            },
            {
                Header: 'Account Type',
                isVisible: hideColumnList['Account Type'] === true || emptySelectionOfColumn || false,
                accessor: 'account_type',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            {
                Header: 'Institution Name',
                isVisible: hideColumnList['Institution Name'] === true || emptySelectionOfColumn || false,
                accessor: 'institution_name',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            {
                Header: 'Primary Account Holder',
                isVisible: hideColumnList['Primary Account Holder'] === true || emptySelectionOfColumn || false,
                accessor: 'primary_acc_holder',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            {
                Header: 'Trust Flag',
                isVisible: hideColumnList['Trust Flag'] === true || emptySelectionOfColumn || false,
                accessor: 'trust_flag',
                Filter: SelectColumnFilter,
                filter: optionIncludes,
                Cell: ({ value, row, cell }) => {
                    return (
                        <div>
                            {enableEdits?.[0]?.[row.original.pid]
                                ? EditableCell(value, 'select', row, cell, [
                                      { value: 'yes', label: 'Yes' },
                                      { value: 'no', label: 'No' }
                                  ])
                                : value || '-'}{' '}
                        </div>
                    );
                }
            },
            {
                Header: 'Asset Location',
                isVisible: hideColumnList['Asset Location'] === true || emptySelectionOfColumn || false,
                accessor: 'asset_location',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            {
                Header: 'Broad Asset Type',
                isVisible: hideColumnList['Broad Asset Type'] === true || emptySelectionOfColumn || false,
                accessor: 'broad_asset_type',
                Filter: SelectColumnFilter,
                filter: optionIncludes,
                Cell: ({ value, row, cell }) => {
                    const trimmedval = value?.trim().toLowerCase();
                    return (
                        <div>
                            {enableEdits?.[0]?.[row.original.pid]
                                ? EditableCell(trimmedval, 'select', row, cell, [
                                      { value: 'Equities', label: 'Equities' },
                                      { value: 'Fx', label: 'FX' },
                                      { value: 'Commodities', label: 'Commodities' },
                                      { value: 'Inflation', label: 'Inflation' },
                                      { value: 'Cash', label: 'Cash' },
                                      { value: 'Fixed Income', label: 'Fixed Income' },
                                      { value: 'Real Estate', label: 'Real Estate' }
                                  ])
                                : trimmedval || value || '-'}{' '}
                        </div>
                    );
                }
            },
            {
                Header: 'Narrow Asset Type',
                isVisible: hideColumnList['Narrow Asset Type'] === true || emptySelectionOfColumn || false,
                accessor: 'narrow_asset_type',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            // {
            //     Header: 'Security Type',
            //     isVisible: hideColumnList['Security Type'] === true || emptySelectionOfColumn || false,
            //     accessor: 'security_type',
            //     Cell: ({ value, row, cell }) => {
            //         return enableEdits?.[0]?.[row.original.pid]
            //             ? EditableCell(value, 'text', row, cell, [], { custompattern: /^([a-z0-9A-Z ]{1,30})$/g })
            //             : value || '-';
            //     }
            // },
            {
                Header: 'Employer',
                isVisible: hideColumnList['Employer'] === true || emptySelectionOfColumn || false,
                accessor: 'employer',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            {
                Header: 'Short Des Override',
                isVisible: hideColumnList['Short Des Override'] === true || emptySelectionOfColumn || false,
                accessor: 'short_des_override',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            },
            {
                Header: 'Category Override',
                isVisible: hideColumnList['Category Override'] === true || emptySelectionOfColumn || false,
                accessor: 'category_override',
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.pid] ? EditableCell(value, 'text', row, cell) : value || '-';
                }
            }
        ];
        if (selectedPortCount?.length > 1) {
            result.push({
                Header: 'File Name',
                isVisible: hideColumnList['File Name'] === true || emptySelectionOfColumn || false,
                accessor: 'File Name',
                Filter: SelectColumnFilter,
                filter: optionIncludes
            });
        }
        return result;
    }, [enableEdits, updatedVal, selectedPortCount, colorScaleArr, getCookie('selected_columns')]);

    const investmentColumns = useMemo(() => {
        let result = [
            {
                Header: 'Symbol',
                sticky: true,
                width: 150,
                accessor: 'symbol',
                disableFilters: true,
                Cell: ({ value, row }) => {
                    return value ? (
                        <CustomButton variant="text" onClick={() => handleSymbolClick(row.original)}>
                            {value}
                        </CustomButton>
                    ) : (
                        '-'
                    );
                }
            },
            {
                Header: 'Company Name',
                accessor: 'company_name',
                Cell: ({ value }) => value || '-'
            },
            {
                Header: 'Institition Id',
                accessor: 'institution_id',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Cost Basis',
                accessor: 'cost_basis',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Institution Price',
                accessor: 'institution_price',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Institution Price As Of',
                accessor: 'institution_price_as_of',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Institution Value',
                accessor: 'institution_value',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                disableFilters: true,
                Cell: ({ value }) => numberWithCommas(value.toFixed(2))
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                disableFilters: true,
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Security Type',
                accessor: 'security_type',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Close Price',
                accessor: 'close_price',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Close Price As Of',
                accessor: 'close_price_as_of',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Is Cash Equivalent',
                accessor: 'is_cash_equivalent',
                Cell: ({ value }) => {
                    return value ? 'Yes' : 'No';
                }
            },
            {
                Header: 'Bank Name',
                accessor: 'bank_name',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Gains And Losses',
                accessor: 'gains',
                Cell: ({ value }) => {
                    return value || '-';
                }
            }
        ];
        if (setting.profileDetail.role !== 'admin') {
            result.pop();
            return result;
        }
        return result;
    }, []);

    const sortDate = (rowA, rowB, id, desc) => {
        const a = moment(rowA.values[id]);
        const b = moment(rowB.values[id]);

        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    };

    const investmentTransactionsColumns = useMemo(() => {
        const result = [
            {
                Header: 'Symbol',
                accessor: 'ticker_symbol',
                Cell: ({ value }) => value || '-',
                disableFilters: true,
                Cell: ({ value, row }) => {
                    return value ? (
                        <CustomButton variant="text" onClick={() => handleSymbolClick(row.original)}>
                            {value}
                        </CustomButton>
                    ) : (
                        '-'
                    );
                }
            },
            {
                Header: () => null,
                accessor: 'transaction_id',
                isVisible: false
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ value }) => value || '-'
            },
            {
                Header: 'Date',
                sticky: true,
                accessor: 'date',
                disableFilters: true,
                sortType: sortDate,
                Cell: ({ value }) => moment(value).format('MM/DD/YYYY')
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                Cell: ({ value }) => (value ? numberWithCommas(value.toFixed(2)) : 0),
                Filter: SliderColumnFilter,
                filter: filterGreaterThan
            },
            {
                Header: 'Price',
                accessor: 'price',
                Cell: ({ value }) => (value ? numberWithCommas(value.toFixed(2)) : 0),
                Filter: SliderColumnFilter,
                filter: filterGreaterThan
            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                Cell: ({ value }) => (value ? numberWithCommas(value.toFixed(2)) : 0),
                Filter: SliderColumnFilter,
                filter: filterGreaterThan
            },
            {
                Header: 'Subtype',
                accessor: 'subtype',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'ISO Currency Code',
                accessor: 'iso_currency_code',
                Cell: ({ value }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Fees',
                accessor: 'fees',
                Cell: ({ value }) => (value ? numberWithCommas(value.toFixed(2)) : 0),
                Filter: SliderColumnFilter,
                filter: filterGreaterThan
            },
            {
                Header: 'Bank Name',
                accessor: 'bank_name',
                Cell: ({ value }) => {
                    return value || '-';
                }
            }
        ];
        return result;
    }, []);

    const createColorScale = (val) => {
        let portfolioValueArrColorField = {};
        const portfolioValueColorScaleFields = ['market_value', 'per_change', 'dollar_change'];
        if (val) {
            val.forEach((subval) => {
                Object.keys(subval).forEach((scaleValue) => {
                    if (portfolioValueColorScaleFields.indexOf(scaleValue) > -1) {
                        if (portfolioValueArrColorField[scaleValue]) {
                            portfolioValueArrColorField[scaleValue].push(subval[scaleValue]);
                        } else {
                            portfolioValueArrColorField = { ...portfolioValueArrColorField, [scaleValue]: [] };
                            portfolioValueArrColorField[scaleValue].push(subval[scaleValue]);
                        }
                    }
                });
            });
        }
        setColorScaleArr(portfolioValueArrColorField);
    };

    useEffect(() => {
        if (portfolioData.selectedPortfolioDetail.holdings) {
            createColorScale(portfolioData.selectedPortfolioDetail?.holdings);
        }
    }, [portfolioData.selectedPortfolioDetail?.holdings]);

    const [filteredTableData, setFilteredData] = useState([]);
    const [reRenderColumn, setRerenderColumn] = useState(false);
    const container = React.useRef(null);
    const onSubmit = (input) => {
        const objToSend = groupByStartingKey(input).map((data) => ({
            portFileId: data.pid,
            symbol: data['symbol'] || null,
            who: data['who'] || null,
            what: data['what'] || null,
            tradeable: data['tradeable'] || null,
            position_name: data['position_name'] || null,
            quantity: data['quantityUnFormattedValue'] || (data['quantity'] && data['quantity'].replace(/,/g, '')) || null,
            price_override: data['price_overrideUnFormattedValue'] || data['price_override']?.replace(/,/g, '') || null,
            currency: data['currency']?.value || null,
            account_type: data['account_type'] || null,
            institution_name: data['institution_name'] || null,
            primary_acc_holder: data['primary_acc_holder'] || null,
            trust_flag: data['trust_flag'] || null,
            asset_location: data['asset_location'] || null,
            broad_asset_type: data['broad_asset_type'] || null,
            narrow_asset_type: data['narrow_asset_type'] || null,
            security_type: data['security_type'] || null,
            employer: data['employer'] || null,
            short_des_override: data['short_des_override'] || null,
            category_override: data['category_override'] || null,
            // maturity_date: "2024/01/04",
            maturity_date: (data.maturity_date && moment(data.maturity_date).format('YYYY/MM/DD')) || null
        }));
        dispatch(smartTblPortfolio(objToSend, 'put'));

        setCurrentAction('');
        setSelectedRowsSet({});
        setEnableEdits([]);
        reset();
    };
    const onSelectionEdit = (selectedRows) => {
        setEnableEdits(selectedRows.map((obj) => obj.original));
    };
    const handleOnChangeSelection = (selectedRows) => {
        setEnableEdits([]);
    };
    const onSelectionCancel = () => {
        setCurrentAction('');
        setSelectedRowsSet({});
        setEnableEdits([]);
    };
    const onSelectionDelete = () => {
        deleteSelectedData(Object.keys(selectedRowsSet).filter((key) => selectedRowsSet[key]));
        setCurrentAction('');
        setSelectedRowsSet({});
        setEnableEdits([]);
    };
    useEffect(() => {
        setHoldingsData(filteredTableData);
    }, [filteredTableData]);
    const handleRangeSelection = (range) => {
        //   setEnableChart(range);
        dispatch(fetchPortfolioChart(enableChart.symbol, range));
    };

    useEffect(() => {
        const scrollContainer = document.querySelector('.scroll');
        const lastTab = document.querySelector('.scroll > div:last-child');
        if (!scrollContainer || !lastTab) return;

        const containerRight = scrollContainer.getBoundingClientRect().right;
        const lastTabRight = lastTab.getBoundingClientRect().right;

        const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
        if (isLastTabVisible) {
            setScrollPosition(3);
        }
    }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleTabNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scroll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedTab(index);
    };

    const render = () => {
        return (
            <>
                <Meta
                    title="Portfolio Value Upload | TPE Hub"
                    description="Upload and manage your portfolio value data with ease. Our Portfolio Value Upload page allows you to securely import and update your investment information for accurate tracking and analysis."
                    page="/portfolio-valuation-calculator"
                />
                <div>
                    <Tour
                        steps={steps}
                        isOpen={isTourOpen}
                        onRequestClose={closeTour}
                        goToStep={currentStep}
                        showButtons={false}
                        showNavigation={true}
                        showNumber={false}
                        closeWithMask={false}
                        closeWithEsc={false}
                        rounded={10}
                        maskSpace={10}
                    />
                </div>
                {portfolioData.loader || mutualfund.loader ? <Loader /> : ''}
                <Popover
                    id={idAction}
                    open={openActionMenu}
                    anchorEl={anchorActionEl}
                    onClose={handleActionClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <List component="nav" aria-label="main mailbox folders">
                        {/* <ListItem
                            button
                            onClick={() => {
                                // delete selectedRowsSet[row.original.pid];
                                // deleteSelectedData([row.original.pid]);
                            }}
                        >
                            <ListItemText primary="Bulk Delete" />
                        </ListItem> */}
                        <ListItem
                            button
                            disabled={currentAction === 'BULK_EDIT' || Object.keys(selectedRowsSet).length === 0}
                            onClick={(e) => {
                                reset();
                                setUpdatedVal({});
                                setCurrentAction('BULK_EDIT');
                                handleActionClose();
                                setEnableEdits([selectedRowsSet]);
                            }}
                        >
                            <ListItemText primary="Bulk Edit" />
                        </ListItem>
                    </List>
                </Popover>
                <PortfolioTopBlock
                    totalVal={totalVal}
                    summaryData={summaryData}
                    dayChange={dayChange}
                    topfive={topfive}
                    bottomfive={bottomfive}
                    correlation={correlation}
                    avgPer={avgPer}
                    isBankFile={isBankFile}
                    columns={columns}
                    data={holdingsData}
                />
                {homeVal && !hidePortfolioMessage && (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} style={{ paddingTop: '0px', margin: '10px' }}>
                            We note that you have saved a property on TPE’s home valuation tool. Would you like to add it to the portfolio?{' '}
                            <Button
                                variant="contained"
                                size="sm"
                                style={{ vertical: 'middle', marginLeft: '30px' }}
                                color="secondary"
                                onClick={handleButtonClick}
                            >
                                yes
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {isOpen && <AddUserDialog setIsOpen={setIsOpen} open={true} homePrice={homeValPrice} />}
                {hidePortfolioMessage && (
                    <FormControlLabel
                        control={<Switch checked={showHomeValTrue} onChange={() => setShowHomeValTrue((prev) => !prev)} color="primary" />}
                        label={showHomeValTrue ? 'Hide Home valuation Data' : 'Show Home valuation Data'}
                        labelPlacement="end"
                    />
                )}
                <Grid container>
                    {/* <Box style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                    <Grid item xs className="widget-full-width">
                        <PortfolioBreadcrumb
                            classes={classes}
                            handleTabChange={handleTabChange}
                            list={fileList}
                            renderBreadCrumb={renderBreadCrumb}
                            renderHoldingList={renderHoldingList}
                            manualLoad={isManualClicked}
                            resetManualClick={() => setManualClicked(false)}
                            resetHoldingList={() => setHoldingList(false)}
                            selectedPortCount={(count) => setSelectedPortCount(count)}
                            resetRenderBreadCrumb={() => setRenderBreadCrumb([])}
                            setIsBankFile={setIsBankFile}
                            isBankFile={isBankFile}
                        />
                    </Grid>
                    {/* <ExportGrid item xs>
                  <RightSideActionBox>
                      <Tooltip title="Export" arrow>
                          <CustomButton variant="text" startIcon={<DownloadIcon />} aria-label="export" className="export-btn">
                              Export
                          </CustomButton>
                      </Tooltip>
                  </RightSideActionBox>
              </ExportGrid> */}
                    {/* <Paper elevation={3}> */}

                    {/* </Paper> */}
                    <Grid item xs="auto" className="widget-full-width">
                        <Box ref={container} />
                    </Grid>
                </Grid>
                <MutualFundViewPopup handleClose={handleClose} data={mutualfund.portfolioMutualTicker} viewData={viewData} />
                <TopBlockBox>
                    <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(231, 245, 255)', width: '100%' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 0 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('backward')}
                        >
                            {' '}
                            &lt;
                        </div>
                        <div
                            style={{
                                overflowX: 'auto',
                                display: 'flex'
                            }}
                            className="scroll"
                            onScroll={handleScroll}
                        >
                            {tabs.map((tab, index) => (
                                <div
                                    id={tab.label.slice(0, 5)}
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        // flex: '-1 0 auto',
                                        flex: 'none',

                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        borderBottom: '3px solid transparent',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                        color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                                    }}
                                    onClick={() => handleTabClick(index)}
                                >
                                    {tab.label}
                                </div>
                            ))}
                            <Box
                                style={{
                                    flex: 'none',
                                    display: 'flex'
                                }}
                            >
                                <AddUserOptionBox>
                                    <AddUserDialog />
                                </AddUserOptionBox>

                                <AddUserOptionBox>
                                    <ViewOptions columns={columns} reRenderColumn={(val) => setRerenderColumn(val)} />
                                </AddUserOptionBox>
                                {!isBankFile && (
                                    <RefreshBox id="refresh">
                                        Load the Data
                                        <Tooltip title="Refresh">
                                            <IconButton
                                                className="MuiDropzonePreviewList-removeButton"
                                                color="primary"
                                                onClick={() => setManualClicked(true)}
                                            >
                                                <AutorenewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </RefreshBox>
                                )}
                            </Box>
                            <TableSearchBox m={'auto 0'}>
                                <Box width="150px" ref={searchcontainer} />
                            </TableSearchBox>
                        </div>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 2 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('forward')}
                        >
                            &gt;
                        </div>
                    </div>
                    {/* <Tabs
                        value={selectedTab}
                        className="topblock-tabs"
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        allowScrollButtonsMobile={true}
                        aria-label="full width portfolio tab"
                    >
                        <Tab label="Holdings" {...a11yProps(0)} id="holdings" />
                        <Tab label="Investment Holdings & Securities" {...a11yProps(1)} style={{ width: '200px' }} id="ihs" />
                        <Tab label="Investment Transactions" {...a11yProps(2)} style={{ width: '150px' }} id="it" />
                        <Tab label="Upload Portfolio" {...a11yProps(3)} id="up" />

                        <AddUserOptionBox>
                            <AddUserDialog />
                        </AddUserOptionBox>

                        <AddUserOptionBox>
                            <ViewOptions columns={columns} reRenderColumn={(val) => setRerenderColumn(val)} />
                        </AddUserOptionBox>
                        {!isBankFile && (
                            <RefreshBox id="refresh">
                                Load the Data
                                <Tooltip title="Refresh">
                                    <IconButton
                                        className="MuiDropzonePreviewList-removeButton"
                                        color="primary"
                                        onClick={() => setManualClicked(true)}
                                    >
                                        <AutorenewIcon />
                                    </IconButton>
                                </Tooltip>
                            </RefreshBox>
                        )}
                        <TableSearchBox m={'auto 0'}>
                            <Box ref={searchcontainer} />
                        </TableSearchBox>
                    </Tabs> */}
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={selectedTab}
                        onChangeIndex={(index) => {
                            setActiveTab(index);
                            setSelectedTab(index);
                        }}
                    >
                        <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    {Object.keys(enableChart).length > 0 ? (
                                        <PortfolioChart
                                            chartdetails={enableChart}
                                            backButtonClicked={() => setEnableChart({})}
                                            rangeSelectionClicked={(val) => handleRangeSelection(val)}
                                        />
                                    ) : (
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <EnhancedTable
                                                searchcontainer={searchcontainer}
                                                hideToolBar={true}
                                                container={container}
                                                // portfolioTotal={fetchPortfolioTotal()}
                                                columns={columns}
                                                data={holdingsData}
                                                deleteSelectedData={deleteSelectedData}
                                                enableSort={true}
                                                enableSearch={true}
                                                enableSelection={true}
                                                handleOnChangeSelection={handleOnChangeSelection}
                                                onSelectionEdit={onSelectionEdit}
                                                onSelectionCancel={onSelectionCancel}
                                                onSelectionDelete={onSelectionDelete}
                                                currentAction={currentAction}
                                                setCurrentAction={setCurrentAction}
                                                selectedRowsSet={selectedRowsSet}
                                                setSelectedRowsSet={setSelectedRowsSet}
                                                // manualLoad={() => setManualClicked(true)}
                                                // addDataDialogCompo={AddUserDialog}
                                            />
                                        </form>
                                    )}
                                </Grid>
                            </Grid>
                        </PortfolioTabPanel>
                        {/* <PortfolioTabPanel value={selectedTab} index={1}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <EnhancedTable
                                        searchcontainer={searchcontainer}
                                        hideToolBar={true}
                                        container={container}
                                        columns={investmentColumns}
                                        data={portfolioData.bankPortfolioData}
                                        enableSort={true}
                                        enableSearch={true}
                                        enableSelection={true}
                                    />
                                </Grid>
                            </Grid>
                        </PortfolioTabPanel> */}
                        <PortfolioTabPanel value={selectedTab} index={1}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <EnhancedTable
                                        searchcontainer={searchcontainer}
                                        hideToolBar={true}
                                        container={container}
                                        columns={investmentTransactionsColumns}
                                        data={portfolioData.bankPortfolioTransactions}
                                        enableSort={true}
                                        enableSearch={true}
                                        enableSelection={true}
                                    />
                                </Grid>
                            </Grid>
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={selectedTab} index={2} dir={theme.direction} mx={0.5}>
                            <Grid container className={classes.uploadWrapper} spacing={2}>
                                <Grid item xs={12} md={6} lg={6} className="widget-full-width">
                                    <PortfolioUpload
                                        a11yProps={a11yProps}
                                        portList={portfolioData.portfolioList}
                                        uploadPortfolio={handlePortfolioUpload}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} className="widget-full-width">
                                    <PortfolioOutput
                                        classes={classes}
                                        deleteFiles={handleFileDelete}
                                        data={portfolioData.portfolioList}
                                        updateBreadCrumb={(data) => {
                                            setRenderBreadCrumb(data);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </PortfolioTabPanel>
                    </SwipeableViews>
                </TopBlockBox>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Investments'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Portfolio Valuation
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <DoRegistration />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Portfolio Valuation" handleTour={handleTour} />
                <MainBox>
                    <LargeContainer maxWidth="xl">
                        {render()}
                        <PortfolioDesc />
                    </LargeContainer>
                </MainBox>
            </>
        )
    );
}
