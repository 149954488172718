import { Card, CardContent, Divider, Grid, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { StudLabelGrid } from 'assets/jss/CommonCalStyle';
import { StudInputGrid } from 'assets/jss/CommonCalStyle';
import { fetchSavedData, fetchSavedDataSuccess, homeValuationCalSuccess, submitHomeValuationCal } from 'redux/actions/HomeCalActions';
import { StateList } from 'States.json';
import { RegionList, UKRegionList } from 'Regions.json';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import * as tooltip from 'Tooltip.json';
import { CurrencyList } from 'Currency.json';
import { CountryList } from 'countries.json';

const defaultValues = {
    purchase_year: '1996',
    purchase_quarter: '2',
    purchase_price: '1000000',
    property_name: '',
    adjustment_multiplier: 1,
    mortgage_amount: 0,
    percentage_owned: 100,
    country: 'USA',
    currency: {
        flag: 'united-states.png',
        label: 'USD(United States Dollar)',
        symbol: '$',
        value: 'USD'
    }
    // state: ''
    // region_name: ''
};
const handleOptionLabel = (option) => option.label;
const handleOptions = () => {
    const list = CurrencyList.map((val) => {
        return {
            value: val.code,
            label: val.code + `(${val.name})`,
            symbol: val.symbol,
            flag: val.flag
        };
    });
    return list;
};
const ProductSection = (props) => {
    const apiToken = getCookie('auth_token');
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCountry, setSelectedCounrty] = useState('USA');
    const [currSymbol, setCurrSymbol] = useState('');

    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        getValues
    } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const startingYear = 1991,
        endingYear = new Date().getFullYear();
    const diffYear = endingYear - startingYear;
    const fetchHomeVal = () => {
        if (apiToken) dispatch(fetchSavedData());
    };
    const onSubmit = (data) => {
        const purPrice = data.purchase_price.toString().replace(/,/g, '');
        const mortgage = data.mortgage_amount.toString().replace(/,/g, '');
        let state;
        if (typeof data.state == 'object') {
            state = data.state ? data.state.value : '';
        } else {
            state = data.state;
        }
        let objToPass = {
            purchase_year: data.purchase_year,
            purchase_quarter: data.purchase_quarter,
            state: state,
            region_name: data.region_name ? data.region_name.label : '',
            purchase_price: purPrice,
            property_name: data.property_name,
            adjustment_multiplier: data.adjustment_multiplier,
            currency: data.currency ? data.currency.value : '',
            mortgage_amount: mortgage,
            percentage_owned: data.percentage_owned,
            country: data.country
        };
        // onSubmit(getValues())
        if (getValues('save_data')) {
            if (!apiToken) {
                toast.error('Please login to save data!');
                return;
            }
            objToPass = { ...objToPass, save_data: getValues('save_data') };
        }
        setCurrSymbol(data.currency?.symbol || '');
        dispatch(submitHomeValuationCal(objToPass, fetchHomeVal));
    };

    useEffect(() => {
        props.setCurrSignVal(currSymbol);
    }, [currSymbol]);

    /**
     * @author disha
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(props.homeValuationData.homeValuationDetail).length) {
            setResult(props.homeValuationData.homeValuationDetail.data);
        } else {
            // to reset the output fields
            if (result.length > 0) setResult([]);
        }
    }, [props.homeValuationData]);

    // to reset redux stored value of result when page is changing
    useEffect(() => {
        if (apiToken) dispatch(fetchSavedData());
        return () => {
            dispatch(homeValuationCalSuccess({}));
            dispatch(fetchSavedDataSuccess({}));
        };
    }, []);

    // to load saved data in form
    const handleLoadData = () => {
        setValue('save_data', false);
        setDisabledLoadBtn(true);
        const savedData = props.homeValuationData.savedHomeValuationDetail;
        for (const key in savedData) {
            if (Object.hasOwnProperty.call(savedData, key)) {
                let element = savedData[key];
                if (key == 'country') {
                    element = element ? element : 'USA';
                    setSelectedCounrty(element);
                }
                if (key === 'state') {
                    const list = handleStateOptions();
                    element = list.filter((val) => val.value === element)[0];
                }
                if (key === 'region_name') {
                    const list = handleRegionOptions();
                    element = list.filter((val) => val.label === element)[0];
                }
                if (key == 'currency') {
                    const list = handleOptions();
                    element = element ? element : 'USD';
                    element = list.filter((val) => val.value === element)[0];
                }
                if (key == 'mortgage_amount') {
                    element = element ? element : 0;
                }
                if (key == 'percentage_owned') {
                    element = element ? element : 100;
                }
                setValue(key, element);
            }
        }
    };

    const handleCountryChange = (event) => {
        if (event.target.value) {
            setSelectedCounrty(event.target.value);
            setValue('region_name', '');
            setSelectedOption('');
            setValue('state', '');
        } else {
            setSelectedCounrty('');
        }
    };

    const handleSelectChange = (event, label) => {
        if (event.target.innerText) {
            setSelectedOption(label);
        } else {
            setSelectedOption('');
        } // Update selectedOption when the selection changes
    };

    const handleCityChange = (event) => {
        if (event && event.target) {
            const selectedCity = event.target.value;
            setValue('region_name', selectedCity);

            const stateCode = selectedCity.split(', ')[1];

            if (stateCode) {
                const list = handleStateOptions();
                const element = list.filter((val) => val.value === stateCode)[0];
                setValue('state', element);
            }
        }
    };

    /**
     * @author disha
     * @use to reset the output if there is modification in form
     */
    const handleFormInputChange = () => {
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
        }
        // reset saved output from redux state
        if (isDirty && result.length > 0) {
            dispatch(homeValuationCalSuccess({}));
        }
    };

    const handleCountryOptions = () => {
        const list = CountryList.map((val) => {
            return {
                value: val.value,
                label: val.label
            };
        });
        return list;
    };
    const handleStateOptions = () => {
        const list = StateList.map((val) => {
            return {
                value: val.abb,
                label: val.name + ` (${val.abb})`
            };
        });
        return list;
    };
    const handleRegionOptions = () => {
        const regionList = selectedCountry === 'United Kingdom' ? UKRegionList : RegionList;
        return regionList.map((val) => ({
            label: val.name,
            value: val.name
        }));
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    {Object.keys(props.homeValuationData.savedHomeValuationDetail).length ? (
                        <Box color="text.primary">
                            <Typography variant="body2" component="p">
                                We have your saved data. Click button to load it.
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    disabled={disabledLoadBtn}
                                    onClick={handleLoadData}
                                >
                                    <CachedIcon color="white" />
                                </IconButton>
                                <Divider sx={{ mb: 1 }} />
                            </Typography>
                        </Box>
                    ) : (
                        ''
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="purchase_year"
                                    control={control}
                                    label="Purchase Year"
                                    id="purchase_year"
                                    options={[...Array(diffYear + 1)].map((_, index) => {
                                        const currVal = startingYear + index;
                                        return { value: currVal, label: currVal };
                                    })}
                                    type="select"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="purchase_quarter"
                                    control={control}
                                    label="Purchase Quarter"
                                    id="purchase_quarter"
                                    options={[
                                        { value: 1, label: 1 },
                                        { value: 2, label: 2 },
                                        { value: 3, label: 3 },
                                        { value: 4, label: 4 }
                                    ]}
                                    type="select"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="purchase_price"
                                    control={control}
                                    label="Purchase Price"
                                    id="purchase_price"
                                    type="number"
                                    required
                                    // prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="100,000"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="mortgage_amount"
                                    control={control}
                                    label="Mortgage"
                                    id="mortgage_amount"
                                    type="number"
                                    required
                                    // prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="0"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="currency"
                                    control={control}
                                    style={{ marginTop: '11px' }}
                                    label="Currency"
                                    id="currency"
                                    placeholder="Type to search..."
                                    options={handleOptions()}
                                    renderDisableOption={(option) => getValues().currTo?.value === option.value}
                                    getOptionLabel={(option) => handleOptionLabel(option)}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.flag !== '' ? (
                                                    <img loading="lazy" width="20" src={'img/flags/' + option.flag} alt="country flags" />
                                                ) : (
                                                    ''
                                                )}
                                                <Typography variant="body2">{option.label}</Typography>
                                            </Box>
                                        );
                                    }}
                                    type="searchSelect"
                                    required
                                />
                            </Grid>
                            <Grid display="flex" alignItems="center" item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={(event) => {
                                        handleFormInputChange(event);
                                        handleCountryChange(event);
                                    }}
                                    name="country"
                                    control={control}
                                    label="Country"
                                    id="country"
                                    options={handleCountryOptions()}
                                    type="select"
                                    required
                                />
                            </Grid>
                            <Grid display="flex" alignItems="center" item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={(event) => {
                                        handleFormInputChange(event);
                                        handleSelectChange(event, 'State Code');
                                    }}
                                    name="state"
                                    control={control}
                                    label="State Code"
                                    id="state"
                                    options={handleStateOptions()}
                                    disabled={selectedOption === 'City' || selectedCountry !== 'USA'}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box component="li" sx={{ m: 1 }} {...props}>
                                                <Typography variant="body2">{option.label}</Typography>
                                            </Box>
                                        );
                                    }}
                                    type="searchSelect"
                                />
                                {/* <InputAdornment position="end">
                                    <CustomTooltip tooltipText={tooltip.default?.['State Code']} label="State Code" />
                                </InputAdornment> */}
                            </Grid>
                            <Grid display="flex" alignItems="center" item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={(event) => {
                                        handleFormInputChange(event);
                                        handleCityChange(event);
                                    }}
                                    name="region_name"
                                    control={control}
                                    label="City"
                                    id="region_name"
                                    options={handleRegionOptions()}
                                    disabled={
                                        selectedOption === 'State Code' ||
                                        (selectedCountry !== 'USA' && selectedCountry !== 'United Kingdom')
                                    }
                                    type="select"
                                />
                                {/* <InputAdornment position="end">
                                    <CustomTooltip tooltipText={tooltip.default?.['City']} label="City" />
                                </InputAdornment> */}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="property_name"
                                    control={control}
                                    label="Property Name"
                                    id="property_name"
                                    type="text"
                                    setValue={setValue}
                                    style={{ marginRight: 0 }}
                                    // customPatternName = "name_no_space"
                                />
                            </Grid>
                            <StudLabelGrid item xs={12} md={6} lg={6}>
                                <Typography gutterBottom style={{ marginLeft: '8px' }}>
                                    Percentage Owned
                                </Typography>
                            </StudLabelGrid>
                            <StudInputGrid item xs={12} md={6} lg={6}>
                                <RenderField
                                    type="slider"
                                    control={control}
                                    name="percentage_owned"
                                    id="percentage_owned"
                                    max={100}
                                    min={1}
                                    step={1}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(value) => value.toFixed(0)}
                                    handleOnChange={handleFormInputChange}
                                />
                            </StudInputGrid>
                            <StudLabelGrid item xs={12} md={7} lg={7}>
                                <Typography gutterBottom style={{ marginLeft: '8px' }}>
                                    Adjustment multiplier
                                </Typography>
                            </StudLabelGrid>
                            <StudInputGrid item xs={12} md={5} lg={5}>
                                <RenderField
                                    type="slider"
                                    control={control}
                                    name="adjustment_multiplier"
                                    id="adjustment_multiplier"
                                    max={1.5}
                                    min={0.5}
                                    step={0.1}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(value) => value.toFixed(1)}
                                    handleOnChange={handleFormInputChange}
                                />
                            </StudInputGrid>
                        </Grid>
                        <CalCardActions>
                            <Grid container style={{ width: '100%', textAlign: 'center' }}>
                                <Grid item xs={12} md={6}>
                                    <CustomButton
                                        type="submit"
                                        ps={'9px 30px'}
                                        onClick={() => setValue('save_data', false)}
                                        variant="contained"
                                        // color="rose"
                                        disabled={isSubmitting}
                                    >
                                        Calculate
                                    </CustomButton>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomButton
                                        type="button"
                                        ps={'9px 30px'}
                                        onClick={() => {
                                            setValue('save_data', true);
                                            onSubmit(getValues());
                                        }}
                                        variant="outlined"
                                        // color="success"
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </CalCardActions>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default ProductSection;
