import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const PortfolioDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Portfolio Valuation
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our portfolio valuation tool allows you to take track of your portfolio daily and includes all asset types
                                from stocks to mutual funds to real estate. We allow you to upload your portfolio in a number of ways. You
                                can copy and paste from a spreadsheet, drag a portfolio into the portfolio screen or upload from your
                                computer. Among our filtering tools we include a slider filter on the market value column so you can focus
                                on investments that exceed a specified value.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Also, our portfolio valuation tool works in conjunction with our mutual fund tool so that for any mutual
                                fund or ETF you analyze in the mutual fund tool we will automatically connect the results in the portfolio
                                tool and similarly we will highlight your owned funds in the mutual fund tool.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The Summary tab summarizes your portfolio along several dimensions including Investment Type (EG stocks v
                                bonds), Account Type (Brokerage v. 401K) and Institution (EG Citibank, Robinhood, etc).
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default PortfolioDesc;
