import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { LargeContainer } from 'assets/jss/GeneralStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import React, { useEffect, useRef, useState } from 'react';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import AccessControl from 'views/Authorization/AccessControl';
import PermissionDenied from 'views/Authorization/PermissionDenied';
import DoRegistration from 'views/DoRegistration';
import AddNewCreditCard from './AddNewCreditCard';
import { a11yProps } from 'utils/Utils';
import { AddUserOptionBox } from 'assets/jss/PortfolioStyle';
import { fetchCreditCardCal } from 'redux/actions/CreditCardCalActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import { CreditCardTable } from './CreditCardTable';
import CreditCardCalTopBlock from './CreditCardCalTopBlock';
import CreditCardCalDesc from './CreditCardCalDesc';
import Tour from 'reactour';
import Meta from 'components/meta';

export default function CreditCardCalculator(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const creditCard = useSelector((state) => state.creditCard);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { can_payoff } = creditCard.creditCardDetail;

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'cred';
        }
    }, []);

    const handleButtons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {currentStep !== 0 && (
                    <button
                        style={{
                            padding: '10px',
                            border: '1px solid black',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            fontSize: '18px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep - 1)}
                    >
                        Back
                    </button>
                )}
                {currentStep !== steps.length - 1 ? (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep + 1)}
                    >
                        Continue
                    </button>
                ) : (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => closeTour()}
                    >
                        Finish
                    </button>
                )}
            </div>
        );
    };

    let steps = [
        {
            selector: windowWidth < 650 ? '' : '#Summa',
            content: () => (
                <>
                    <h4>Summary</h4>
                    <p>
                        Welcome to Summary! here you can see the data cards including Total Annual Debt Payments, First Monthly Payment,
                        Number of Payments and Lifetime Interest.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 650 ? '' : '#Lifet',
            content: () => (
                <>
                    <h4>Lifetime Interest</h4>
                    <p>
                        Now, let me introduce you to Lifetime Interest! here you can see the Lifetime Interest chart with scenario
                        multiplier.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 650 ? '' : '#Month',
            content: () => (
                <>
                    <h4>Months to Payoff</h4>
                    <p>
                        Now, let me introduce you to Months to Payoff! here you can see the Months to Payoff chart with scenario multiplier.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 650 ? '' : '#Scena',
            content: () => (
                <>
                    <h4>Scenario Output</h4>
                    <p>
                        Now, let me introduce you to Scenario Output! here you can see the Lifetime Interest, Months to Payoff and Scenario
                        Multiplier chart.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#Princ',
            content: () => (
                <>
                    <h4>Summary Table</h4>
                    <p>
                        Now, let me introduce you to Summary Table! here you can see the credit card table data including, Balance, Interest
                        Rate, Payment Type and Monthly Payment. you can also delete and edit the credit card data.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#tab-6',
            content: () => (
                <>
                    <h4>Add New Data</h4>
                    <p>Now, let me introduce you to Add New Data! here by clicking on this button, you can add new credit card data.</p>
                    {handleButtons()}
                </>
            )
        }
    ];

    const closeTour = () => {
        setIsTourOpen(false);
        document.body.style.overflow = 'auto';
    };

    const handleTour = () => {
        setIsTourOpen(true);
        document.body.style.overflow = 'hidden';
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        dispatch(fetchCreditCardCal());
    }, []);

    /**
     * handle the tab switch view
     * @param {*} event
     * @param {*} newValue
     */

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const render = () => {
        return (
            <>
                <div>
                    <Tour
                        steps={steps}
                        isOpen={isTourOpen}
                        onRequestClose={closeTour}
                        goToStep={currentStep}
                        showButtons={false}
                        showNavigation={true}
                        showNumber={false}
                        closeWithMask={false}
                        closeWithEsc={false}
                        rounded={10}
                        maskSpace={10}
                    />
                </div>
                <AccessControl allowedPermissions={['creditcard_calc']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}>
                    {creditCard.loader ? <Loader /> : ''}
                    <CreditCardCalTopBlock />
                    {can_payoff != null && (
                        <div style={{ backgroundColor: 'orange', padding: '10px', marginBottom: '20px', color: 'white' }}>
                            Warning:- {can_payoff}
                        </div>
                    )}
                    <TopBlockBox>
                        <Tabs
                            className="topblock-tabs"
                            value={value}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            // variant="scrollable"
                            // allowScrollButtonsMobile={true}
                            aria-label="credit card calculator summary tabs"
                        >
                            <Tab label="Summary" {...a11yProps(0)} id="tab-5" />
                            {!(
                                creditCard?.creditCardDetail?.credit_card_output &&
                                creditCard?.creditCardDetail?.credit_card_output['Loan Type']
                            ) && (
                                <AddUserOptionBox>
                                    <AddNewCreditCard />
                                </AddUserOptionBox>
                            )}
                        </Tabs>
                        <Box value={value} index={0}>
                            <CreditCardTable hideToolBar={true} dispatch={dispatch} creditCardData={creditCard} />
                        </Box>
                    </TopBlockBox>
                </AccessControl>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Debt Calculators'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Credit Card Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Credit Card Calculator | TPE Hub"
                    description="Use our Credit Card Calculator to easily calculate your monthly payments, interest rates, and payoff schedules. Enter your credit card details to get insights into managing your credit card debt effectively."
                    page="/credit-card-calculator"
                />
                <DoRegistration />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Credit Card Calculator" key="credit" handleTour={handleTour} />
                <MainBox>
                    <LargeContainer maxWidth="xl">
                        {render()}
                        <CreditCardCalDesc />
                    </LargeContainer>
                </MainBox>
            </>
        )
    );
}
