import React, { useEffect, useRef } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader';
import { useSelector } from 'react-redux';
import AllocationForm from './AllocationForm';
import AllocationOutput from './AllocationOutput';
import CalDescription from 'CalDescription';
import { MainBox } from 'assets/jss/GeneralStyle';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import PortfolioAllocationDesc from './PortfolioAllocationDesc';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import Meta from 'components/meta';

export default function PortfolioAllocationIndex(props) {
    const navigate = useNavigate();
    const portfolioData = useSelector((state) => state.portfolio);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'portAll';
        }
    }, []);

    const render = () => {
        return (
            <>
                {portfolioData.allocationLoader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <AllocationForm
                            allocationData={portfolioData.allocationDetails}
                            portfolioValuationData={portfolioData.portfolioValuationData}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <AllocationOutput allocationData={portfolioData.allocationDetails} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Investment'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Portfolio Analysis
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Portfolio Allocation | TPE Hub"
                    description="Analyze and optimize your investment strategy with our Portfolio Allocation tool. View and manage the distribution of your assets across different categories to enhance your portfolio's performance."
                    page="/portfolio-allocation-calculator"
                />
                <CommonCalculator
                    paddingvalue={'14px 0'}
                    breadcrumbs={breadcrumbs}
                    variantText="Portfolio Analysis"
                    //   subVarientText="The People's Economist Portfolio Allocation Calculator"
                    desc={CalDescription['Portfolio Allocation Calculator']}
                />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <PortfolioAllocationDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
