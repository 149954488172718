import { Box, CardContent, Grid, Paper, Tab } from '@mui/material';
import { styled } from '@mui/styles';
export const TopBlockBox = styled(Box)(({ ps, theme }) => {
    return {
        marginBottom: '20px',
        '& .topblock-tabs': {
            marginBottom: '10px'
            //    backgroundColor: theme.palette.primary['200']
        }
    };
});
export const ChartSelectionBox = styled(Box)(({ ps, theme }) => {
    return {
        display: 'grid',
        height: 'max-content',
        marginRight: '5px'
    };
});
export const SummaryAllocationBox = styled(Box)(({ ps, theme }) => {
    return {
        display: 'flex',
        '& .subIconBtn.selected': {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary[200]
        }
    };
});
export const ChartCardParent = styled(Paper)(({ customheight, theme }) => {
    return {
        height: customheight || '300px',
        zIndex: '0',
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            '&.large-height': {
                height: '500px'
            }
        },
        '&.text-block': {
            padding: '100px 0',
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
                padding: '30px 0',
                height: '100px'
            }
        },
        '&.leave-space-dash': {
            // margin: '10px 0',
            // overflow:'scroll'
        },
        '& .chartwrapper': {
            height: 288
        },
        '& .toggleButtonGroup': {
            marginY: '10px',
            position: 'absolute',
            left: '15px',
            zIndex: '999'
        },
        '& .toggleButton': {
            padding: '4px',
            minWidth: '30px'
        }
    };
});
export const ChartWrapperCardContent = styled(CardContent)(({ ps, theme }) => {
    return {
        position: 'absolute',
        top: '60px',
        bottom: '15px',
        width: '100%'
    };
});
export const RightSideActionBox = styled(Box)(({ ps, theme }) => {
    return {
        display: 'flex',
        justifyContent: 'end',
        '& .export-btn': {
            margin: 'auto 0'
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'start'
        }
    };
});
export const SummaryTabPaper = styled(Paper)(({ ps, theme }) => {
    return {
        height: 'auto',
        minHeight: '158px',
        textAlign: 'center',
        display: 'grid',
        //    gap: '25px',
        padding: '13px',
        '& .summary-text': {
            height: '44px'
        },
        '& .green-box': {
            width: 'fit-content',
            padding: '14px',
            margin: ' -8px auto',
            backgroundColor: theme.palette.success['200'],
            color: theme.palette.success.light
        },
        '& .error-box': {
            width: 'fit-content',
            padding: '14px',
            margin: ' -8px auto',
            backgroundColor: theme.palette.error['200'],
            color: theme.palette.error.light
        }
    };
});

export const AddUserOptionBox = styled(Box)(({ ps, theme }) => {
    return {
        padding: '16px',
        margin: 'auto 0'
    };
});
export const ViewOptionGrid = styled(Grid)(({ ps, theme }) => {
    return {
        margin: 'auto',
        display: 'flex',
        '& .checkbox-label': {
            margin: 'auto 0'
        }
    };
});
export const ViewOptionSearchBox = styled(Box)(({ ps, theme }) => {
    return {
        width: '60%',
        margin: 'auto',
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '52px'
        }
    };
});
export const RefreshBox = styled(Box)(({ ps, theme }) => {
    return {
        margin: 'auto 0 auto auto',
        '&.budgetsearch': {
            width: 200,
            '& .MuiTableContainer-root': {
                [theme.breakpoints.down('md')]: {
                    width: 'inherit'
                }
            }
        }
    };
});
export const ExportGrid = styled(Grid)(({ ps, theme }) => {
    return {
        margin: 'auto',
        marginTop: '12px'
    };
});
export const TableSearchBox = styled(Box)(({ ps, theme }) => {
    return {
        width: '200px',
        '& .MuiTableContainer-root': {
            [theme.breakpoints.down('md')]: {
                width: 'inherit'
            }
        }
    };
});
export const PortUploadTab = styled(Tab)(({ ps, theme }) => {
    return {
        backgroundColor: theme.palette.grey[200],
        '&.Mui-selected': {
            boxShadow: 'inset 1px 2px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '6px 0px 0px 6px',
            backgroundColor: 'inherit'
        }
    };
});
