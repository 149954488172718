import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography, Box, Grid, CardMedia, Avatar, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsFeed, fetchNewsById } from 'redux/actions/SettingActions';
import { useParams } from 'react-router-dom';
import { infoColor } from 'assets/jss/material-kit-react';
import { withStyles } from '@mui/styles';

// Define custom styles for the Carousel arrows
const StyledCarousel = styled(Carousel)(({ theme }) => ({
    '& .MuiButtonBase-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)'
        }
    },
    '& .MuiButtonBase-root:focus': {
        outline: 'none'
    }
}));

const CarouselLoader = withStyles({
    colorPrimary: {
        backgroundColor: '#9cd0d6'
    },
    root: { top: 23 },
    barColorPrimary: {
        backgroundColor: infoColor
    }
})(LinearProgress);

const NewsArticlePage = () => {
    const dispatch = useDispatch();
    const { newsList, newsArticle } = useSelector((state) => state.setting);
    const [publishedNews, setPublishedNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const { newsId } = useParams();
    const news = JSON.parse(localStorage.getItem('myNews') || '[]');
    const latestNews = JSON.parse(localStorage.getItem('latestNews') || '[]');
    
    useEffect(() => {
        dispatch(getNewsFeed('', 100, true));
    }, []);
    
    useEffect(() => {
        const combinedNews = [...(newsList?.news || []), ...(newsList?.latestNews || [])];
        const published = newsList?.latest_news?.filter((val) => val?.is_published);
        setPublishedNews(published);
        if (published?.length > 0) {
            setLoading(false);
        }
    }, [newsList]);
    
    useEffect(() => {
        if (!news || !latestNews) return;
        const combinedNews = [...news, ...latestNews];
        const matchedNews = combinedNews.find((val) => {
            return (
                val?.id
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^a-z0-9\-]/g, '') === newsId
            );
        });
        
        if (matchedNews) {
            dispatch(fetchNewsById(matchedNews.id));
        }
    }, [newsId]);
    const isDashboardNews = newsArticle?.Type?.startsWith('daily-updates');
    const newsImage = newsArticle?.Type === 'daily-updates-treasury' 
                            ? "/img/treasury-bonds.jpeg" 
                            : newsArticle?.Type === 'daily-updates-corporate' 
                            ? "/img/corporate-bonds.jpeg" 
                            : newsArticle?.Type === 'daily-updates-econ' 
                            ? "/img/econ-market.jpg" 
                            : newsArticle?.Image 
                            ? newsArticle?.Image 
                            : "/img/logo.png";
    const formatDynamicContent = (content) => {
        const paragraphs = content?.split('\n\n');

        return paragraphs?.map((paragraph, index) => {
            const isHeadline = paragraph === paragraph?.toUpperCase();

            // Handle Headline
            if (isHeadline) {
                return (
                    <Typography
                        key={index}
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: '1.5rem',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            borderBottom: '2px solid #eee',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        {paragraph}
                    </Typography>
                );
            }

            // Handle Regular Paragraph
            return (
                <Typography
                    key={index}
                    variant="body1"
                    sx={{
                        lineHeight: 1.8,
                        fontSize: '1.05rem',
                        marginBottom: '1.5rem',
                        textAlign: 'justify',
                        color: '#333'
                    }}
                >
                    {paragraph}
                </Typography>
            );
        });
    };
    return (
        <>
            {loading ? (
                <CarouselLoader />
            ) : (
                <StyledCarousel indicators={false} navButtonsAlwaysVisible>
                    {publishedNews?.map((news, index) => (
                        <a
                            key={index}
                            href={`/knowledge-and-research/news/${news?.id
                                .toLowerCase()
                                .replace(/\s+/g, '-')
                                .replace(/[^a-z0-9\-]/g, '')}`}
                            target="_blank"
                        >
                            <Box
                                key={news?.id}
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: { xs: '300px', sm: '400px', md: '500px', lg: '250px' },
                                    overflow: 'hidden'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <Avatar
                                        src={ isDashboardNews ? '/img/white-logo.png' : news?.['News Source Logo'] }
                                        alt={news?.['News Source']}
                                        sx={{ width: 30, height: 30, marginRight: '10px' }}
                                    />
                                    <Typography variant="subtitle2" sx={{ color: '#fff', fontWeight: 'bold' }}>
                                        {news?.['News Source']}
                                    </Typography>
                                </Box>
                                <CardMedia
                                    component="img"
                                    image={(news?.Type === 'daily-updates-treasury' ? "/img/treasury-bonds.jpeg" : (news?.Type === 'daily-updates-corporate' ? "/img/corporate-bonds.jpeg" : (news?.Type === 'daily-updates-econ' ? "/img/econ-market.jpg" : (news?.Image || "/img/logo.png"))) )}
                                    alt={news?.Headline}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                        color: '#fff',
                                        padding: 2
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            padding: '20px',
                                            color: '#fff'
                                        }}
                                    >
                                        {news?.Headline}
                                    </Typography>
                                </Box>
                            </Box>
                        </a>
                    ))}
                </StyledCarousel>
            )}

            <Container maxWidth="md">
                <Box mb={3} mt={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        
                        <Avatar
                            src={isDashboardNews ? '/img/white-logo.png' : newsArticle?.['News Source Logo']}
                            alt={newsArticle?.['News Source']}
                            sx={{ width: 30, height: 30, marginRight: '10px' }}
                        />
                        <Typography variant="h6" component="h1" gutterBottom>
                            {newsArticle?.['News Source']}
                        </Typography>
                    </Box>
                    <a href={newsArticle?.Link} target="_blank">
                        <Typography
                            sx={{
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                            variant="h3"
                            component="h1"
                            gutterBottom
                        >
                            {newsArticle?.Headline}
                        </Typography>
                    </a>
                    <Typography
                        variant="caption"
                        sx={{
                            display: 'block',
                            color: 'gray',
                            fontWeight: 'bold',
                            fontSize: '0.8rem'
                        }}
                    >
                        {newsArticle?.['Created At']}
                    </Typography>
                </Box>

                <Box mb={3}>
                    <CardMedia
                        component="img"
                        image={newsImage}
                        alt={newsArticle?.Headline}
                        sx={{
                            width: '600px',
                            height: '300px',
                            objectFit: 'cover',
                            margin: '0 auto'
                        }}
                    />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1" paragraph>
                            {formatDynamicContent(newsArticle?.Content)}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default NewsArticlePage;
