import React, { useEffect, useRef } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import AnnuityDesc from './AnnuityDesc';
import AnnuityForm from './AnnuityForm';
import AnnuityOutput from './AnnuityOutput';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import Meta from 'components/meta';

export default function AnnuityCalIndex(props) {
    const general = useSelector((state) => state.generalCal);
    const navigate = useNavigate();

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'annCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {general.annuityData.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <AnnuityForm annuityCalData={general.annuityData?.calData} defaultoverrideData={general.defaultoverrideData} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <AnnuityOutput data={general.annuityData} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Insurance'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Annuity Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Annuity Calculator | TPE Hub"
                    description="Calculate your annuity payments and plan for the future with our easy-to-use annuity calculator. Get accurate estimates and detailed insights on your investment."
                    page="/annuity-calculator"
                />
                <CommonCalculator variantText="Annuity Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <AnnuityDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
