import { Box, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

export const BlogTitleToolTipStyle = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 14
        }
    })
);

export const BlogBGImageBox = styled(Box)(({ backgroundImage }) => ({
    width: '100%',
    height: '40vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'gray',
    backgroundBlendMode: 'multiply',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
}));
