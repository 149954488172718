import { Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import { LargeContainer, MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle';
import { AddUserOptionBox, RefreshBox, TopBlockBox } from 'assets/jss/PortfolioStyle';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { fetchHomePurchFiles } from 'redux/actions/HomeCalActions';
import { fetchLoanDashBoardData } from 'redux/actions/LoanDashBoardAction';
import { a11yProps } from 'utils/Utils';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import AddNewHomePurchase from './AddNewHomePurchase';
import HomePurchaseBreadcrumb from './HomePurchaseBreadcrumb';
import HomePurchaseDesc from './HomePurchaseDesc';
import HomePurchaseOutput from './HomePurchaseOutput';
import { HomePurchaseTable } from './HomePurchaseTable';
import HomePurchaseUpload from './HomePurchaseUpload';
import HomePurchViewOptions from './HomePurchViewOptions';
import RenameFilesPopup from './RenameFilesPopup';
import HomePurchaseCollBlock from './HomePurchaseCollBlock';
import DoRegistration from 'views/DoRegistration';
import Meta from 'components/meta';
// import HomePurchaseCollBlock from './HomepurchaseSharingOption';

const tabs = [{ label: 'Summary' }, { label: 'Upload Tool' }];

export default function HomePurchaseIndex(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const homecal = useSelector((state) => state.homecal);
    const setting = useSelector((state) => state.setting);
    const debt = useSelector((state) => state.debt);
    const loanDashboardData = useSelector((state) => state.loanDashboard);
    const [renderBreadCrumb, setRenderBreadCrumb] = useState([]);
    const [renderInputList, setInputList] = useState(false);
    const [reRenderColumn, setRerenderColumn] = useState(false);
    const [purchFileList, setpurchFileList] = useState([]);
    const [triggerLoad, setTriggerLoad] = useState({});
    const [enableSendBtn, setEnableSendBtn] = useState(false);
    const [inputValue, setInputValue] = useState(false);
    const [rowValue, setRowValue] = useState('');
    const peertopeer = useSelector((state) => state.peertopeer);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeTab, setActiveTab] = useState(0);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'homePuchCal';
        }
    }, []);

    // fetch uploaded file and loandashboard detail on page load
    useEffect(() => {
        dispatch(fetchHomePurchFiles());
        dispatch(fetchLoanDashBoardData(true));
        // appending the single data in list of files to allow user to select non assigned file data
        const lopurchFileList = purchFileList.push({ file_name: 'Single Data', id: '0' });
        setpurchFileList(lopurchFileList);
    }, []);

    useEffect(() => {
        //append file list with other file list data
        const lopurchFileList = [...homecal.homePurchFiles];
        setpurchFileList(lopurchFileList);
    }, [homecal.homePurchFiles]);
    const [value, setValue] = useState(0);
    const [tableColl, setTableColumns] = useState([]);
    /**
     * handle the tab switch view
     * @param {*} event
     * @param {*} newValue
     */
    const handleTabChange = (newValue) => {
        if (typeof newValue !== 'undefined') setValue(newValue);
    };
    const theme = useTheme();

    const searchcontainer = useRef(null);

    const selfData =
        Object.keys(homecal.homePurchaseCollDetails).length > 0
            ? (homecal.homePurchaseCollDetails.collaborated_by && homecal.homePurchaseCollDetails.collaborated_by) || ''
            : '';
    const collData =
        Object.keys(homecal.homePurchaseCollDetails).length > 0
            ? (homecal.homePurchaseCollDetails?.collaborator && homecal.homePurchaseCollDetails.collaborator) || ''
            : '';
    const isSharingEnabled = collData ? collData.is_sharing_enabled : '';

    useEffect(() => {
        const scrollContainer = document.querySelector('.scroll');
        const lastTab = document.querySelector('.scroll > div:last-child');
        if (!scrollContainer || !lastTab) return;

        const containerRight = scrollContainer.getBoundingClientRect().right;
        const lastTabRight = lastTab.getBoundingClientRect().right;

        const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
        if (isLastTabVisible) {
            setScrollPosition(3);
        }
    }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleTabNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scroll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    const render = () => {
        return (
            <>
                {homecal.loader || setting.loader ? <Loader /> : ''}
                <HomePurchaseCollBlock
                    homecal={homecal}
                    setting={setting}
                    handleEnableSendBtn={() => setEnableSendBtn(true)}
                    handleTriggerLoad={(val) => setTriggerLoad(val)}
                />

                {/* <HomePurchaseCollBlock/> */}
                {inputValue && (
                    <AddNewHomePurchase open={true} rowValue={rowValue} setInputValue={setInputValue} setRowValue={setRowValue} />
                )}

                <HomePurchaseBreadcrumb
                    dispatch={dispatch}
                    list={purchFileList}
                    renderInputList={renderInputList}
                    renderBreadCrumb={renderBreadCrumb}
                    resetRenderBreadCrumb={() => setRenderBreadCrumb([])}
                    handleTabChange={(v) => handleTabChange(v)}
                    resetInputList={() => setInputList(false)}
                    updateSuccess={homecal.updateSuccess}
                />
                <TopBlockBox>
                    <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(231, 245, 255)' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 0 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('backward')}
                        >
                            {' '}
                            &lt;
                        </div>
                        <div
                            style={{
                                overflowX: 'auto',
                                display: 'flex'
                            }}
                            className="scroll"
                            onScroll={handleScroll}
                        >
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: '0 0 auto',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        borderBottom: '3px solid transparent',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                        color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                                    }}
                                    onClick={() => handleTabClick(index)}
                                >
                                    {tab.label}
                                </div>
                            ))}
                            {isSharingEnabled && <Tab label="Shared Data" {...a11yProps(2)} />}

                            <AddUserOptionBox style={{ display: 'flex', flex: 'none' }}>
                                <AddNewHomePurchase />
                            </AddUserOptionBox>

                            <AddUserOptionBox style={{ display: 'flex', flex: 'none' }}>
                                <RenameFilesPopup list={purchFileList} resetRenderBreadCrumb={() => setRenderBreadCrumb([])} />
                            </AddUserOptionBox>

                            <AddUserOptionBox style={{ display: 'flex', flex: 'none' }}>
                                <HomePurchViewOptions columns={tableColl} reRenderColumn={(val) => setRerenderColumn(val)} />
                            </AddUserOptionBox>

                            <RefreshBox>
                                <Box ref={searchcontainer} />
                            </RefreshBox>
                        </div>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 2 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('forward')}
                        >
                            &gt;
                        </div>
                    </div>
                    {/* <Tabs
                        className="topblock-tabs"
                        value={value}
                        variant="scrollable"
                        allowScrollButtonsMobile={true}
                        onChange={(e, v) => handleTabChange(v)}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="home purchase tabs"
                    >
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Upload Tool" {...a11yProps(1)} />
                        {isSharingEnabled && <Tab label="Shared Data" {...a11yProps(2)} />}

                        <AddUserOptionBox>
                            <AddNewHomePurchase />
                        </AddUserOptionBox>

                        <AddUserOptionBox>
                            <RenameFilesPopup list={purchFileList} resetRenderBreadCrumb={() => setRenderBreadCrumb([])} />
                        </AddUserOptionBox>

                        <AddUserOptionBox>
                            <HomePurchViewOptions columns={tableColl} reRenderColumn={(val) => setRerenderColumn(val)} />
                        </AddUserOptionBox>



                        <RefreshBox>
                            <Box ref={searchcontainer} />
                        </RefreshBox>
                    </Tabs> */}
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={(index) => {
                            setActiveTab(index);
                            setValue(index);
                        }}
                    >
                        <PortfolioTabPanel value={value} index={0} dir={theme.direction}>
                            <HomePurchaseTable
                                searchcontainer={searchcontainer}
                                dispatch={dispatch}
                                tableColumns={(v) => setTableColumns(v)}
                                disableEditTblField={debt.disableEditTblField}
                                loanDashboardData={loanDashboardData}
                                setInputRenderList={(val) => setInputList(val)}
                                homecal={homecal}
                                tableData={homecal.homePurchSelected || []}
                                profile={setting.profileDetail}
                                isSharedData={false}
                                setInputValue={setInputValue}
                                setRowValue={setRowValue}
                            />
                        </PortfolioTabPanel>

                        <PortfolioTabPanel value={value} index={1} dir={theme.direction}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <HomePurchaseUpload dispatch={dispatch} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <HomePurchaseOutput
                                        homecal={homecal}
                                        updateBreadCrumb={(data) => {
                                            setRenderBreadCrumb(data);
                                        }}
                                        // closeFileDialog={(val) => handleDeleteSubmission(val)}
                                    />
                                </Grid>
                            </Grid>
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={value} index={2} dir={theme.direction}>
                            <HomePurchaseTable
                                searchcontainer={searchcontainer}
                                dispatch={dispatch}
                                tableColumns={(v) => setTableColumns(v)}
                                disableEditTblField={debt.disableEditTblField}
                                loanDashboardData={loanDashboardData}
                                setInputRenderList={(val) => setInputList(val)}
                                homecal={homecal}
                                profile={setting.profileDetail}
                                isSharedData={true}
                                tableData={homecal.homePurchFileData || []}
                            />
                        </PortfolioTabPanel>
                    </SwipeableViews>
                </TopBlockBox>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Real Estate'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Home Purchase Tool
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Home Purchase Tool - Plan and Calculate Your Home Buying Budget | TPE Hub"
                    description="Use the TPE Hub Home Purchase Tool to plan your home buying budget. Calculate mortgage payments, explore financing options, and make informed decisions for your new home."
                    page="/home-purchase-tool"
                />
                <DoRegistration />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Home Purchase Tool" />
                <MainBox>
                    <LargeContainer maxWidth="xl">
                        {render()}
                        <HomePurchaseDesc />
                    </LargeContainer>
                </MainBox>
            </>
        )
    );
}
