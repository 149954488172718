import React from 'react';
import NewsFeedList from './NewsFeedList';
import NewsCategoryDescription from './NewsCategoryDescription';

const NewsCategory = ({ newsList, latestNews, dailyNews }) => {
    return (
        <section>
            <NewsCategoryDescription />
            <NewsFeedList newsList={newsList} latestNews={latestNews} dailyNews={dailyNews} />
        </section>
    );
};

export default NewsCategory;
