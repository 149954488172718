import { Dialog, Divider, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import DialogContent from '@mui/material/DialogContent';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import CustomDialogTitle from 'components/DialogTitle';
import { StyledDialog } from 'assets/jss/GeneralStyle';
import { numberWithCommas } from 'utils/Utils';

export const HomePurchaseViewPopup = ({ viewData, handleClose, data, ...rest }) => {
    const {id, homePurchFileRls,...filteredData }= data

    const transformKeys = (obj) => {
        const transformedObject = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const words = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)); // Convert to title case
            const newKey = words.join(' '); // Join words with space
            transformedObject[newKey] = obj[key];
        }
    }
    return transformedObject;
    }

    const validateData = (obj) => {
        const valToPass = {};
        const extra = { commaSep: true};
        
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                switch (typeof obj[key]) {
                    case 'number':
                        if(key === 'year'){
                            valToPass[key] = obj[key];
                            break;
                        }
                        valToPass[key] = extra.commaSep
                            ? !isNaN(obj[key]) && obj[key]?.toString().indexOf('.') !== -1
                                ? numberWithCommas(obj[key]?.toFixed(2))
                                : numberWithCommas(obj[key])
                            : obj[key];
                        break;
                    case 'string':
                        valToPass[key] = obj[key];
                        break;
                    default:
                        valToPass[key] = obj[key];
                        break;
                }
            }
        }
        return valToPass;
    };
    

    // Transform keys to replace underscores with spaces and convert to title case
    const validatedData = validateData(filteredData);
    const objToPass = transformKeys(validatedData);
   
    
    return (
        <StyledDialog open={viewData} onClose={handleClose} aria-labelledby="mutual-fund-view" >
            <CustomDialogTitle id="mutual-fund-view" onClose={handleClose}>
                View Home Purchase Items
            </CustomDialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2} alignItems="baseline" id="outputWrapper">
                    {objToPass && Object.keys(objToPass).length ? (
                        Object.keys(objToPass).map((val, i) => {
                            const title = val;
                            const value = val === "Created At" ? objToPass[val].split("T")[0].replace(/-/g, '/')  : objToPass[val] || '-';
                            const ignoreFields = ['mid', 'id'];
                            if (!ignoreFields.includes(title)) {
                                return (
                                    <Fragment key={i + 'mutualfundview'}>
                                        <Grid item xs={6} sm={6}>
                                            <Typography variant="body2" component="p" className="outputTitle">
                                                {title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <Typography variant="body2" className="outputLabel" component="p">
                                                <span>{value}</span>
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                );
                            }
                        })
                    ) : (
                        <NoDataFound />
                    )}
                </Grid>
            </DialogContent>
        </StyledDialog>
    );
};
