import { makeStyles } from '@mui/styles';
import { Container, createStyles, Typography, Grid } from '@mui/material';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import studentCalPageStyle from 'assets/jss/material-kit-react/views/studentCalPage';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import BudgetForm from './budgetForm';
import BudgetOuput from './budgetOutput';
import SavedForms from './SavedForms';
import { fetchSavedBudget } from 'redux/actions/BudgetActions';
import budgetPage from 'assets/jss/material-kit-react/views/budgetPage';
import { getCookie } from 'utils/Utils';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import BudgetDesc from './BudgetDesc';
import { BudgetNotePaper } from 'assets/jss/CommonCalStyle';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import PermissionDenied from 'views/Authorization/PermissionDenied';
import AccessControl from 'views/Authorization/AccessControl';
import Meta from 'components/meta';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...styles(theme),
        ...studentCalPageStyle(theme),
        ...budgetPage(theme)
    })
);
export default function BudgetCalIndex(props) {
    const budget = useSelector((state) => state.budget);
    const setting = useSelector((state) => state.setting);
    const [loadbud, setLoadBudFlag] = useState({});
    const [resetForm, setResetForm] = useState(false);
    const classes = mergedStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiToken = getCookie('auth_token');

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'budCal';
        }
    }, []);

    // to reset redux stored value of result when page is changing
    useEffect(() => {
        if (apiToken) dispatch(fetchSavedBudget());
        return () => {
            // dispatch(homeValuationCalSuccess({}));
            // dispatch(fetchSavedDataSuccess({}));
        };
    }, []);
    const render = () => {
        return (
            <>
                <AccessControl allowedPermissions={['Budget_calc']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}>
                    {budget.savedBudgetData.length > 0 ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SavedForms
                                    classes={classes}
                                    budget={budget}
                                    dispatch={dispatch}
                                    resetForm={(flag) => {
                                        setResetForm(flag);
                                        setLoadBudFlag({});
                                    }}
                                    loadSelectedBudget={(data) => {
                                        setLoadBudFlag(data);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        ''
                    )}
                    {/* <BudgetNotePaper elevation={0}>
                    <Typography className={'noteLabel'}> Note: </Typography>
                    <Typography className={'noteText'}> All amounts annual</Typography>
                </BudgetNotePaper> */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="widget-grid-item">
                            <BudgetForm
                                classes={classes}
                                budget={budget}
                                profile={setting.profileDetail}
                                loadbud={loadbud}
                                resetFormFlag={(flag) => setResetForm(flag)}
                                resetForm={resetForm}
                                budgetCalData={budget.budgetCalData}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                            <BudgetOuput data={budget.budgetCalData} widgetcal={props.widgetcal} />
                        </Grid>
                    </Grid>
                </AccessControl>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Budgets & Taxes'} />
        </CustomListItem>,
        <Typography key="3" color="text.light" className="current-menu">
            Budget Calculator
        </Typography>
    ];
    return (
        //classes.widgetCalIndivGrid
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Budget Calculator | TPE Hub"
                    description="Use our Budget Calculator to easily track and manage your finances. Enter your income and expenses to get a clear view of your budget, and make informed decisions to improve your financial health."
                    page="/budget-calculator"
                />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Budget Calculator" variantType="h4" />
                <MainBox>
                    <Container maxWidth="lg">
                        {budget.loader ? <Loader /> : ''}
                        {render()}
                        <BudgetDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
