import { Divider, Grid, List, ListItemIcon, IconButton, MenuItem, MenuList, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DisclaimerBox } from 'assets/jss/FooterStyle';
import { FooterBox, FooterLastBox } from 'assets/jss/FooterStyle';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle';
import { DashGrid } from 'assets/jss/LandingStyle';
import React, { forwardRef, Fragment, useMemo } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { toolsDetails } from 'views/LandingPage/ToolDetails';
import EmailSub from './EmailSub';

function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = useMemo(
        () =>
            forwardRef(function Link(itemProps, ref) {
                return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
            }),
        [to]
    );

    return (
        <CustomListItem button disableGutters className="listbutton" sx={{ m: 0 }} component={renderLink}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <CustomListItemText primary={primary} />
        </CustomListItem>
    );
}
const Footer = () => {
    const remove2nd = ([x, y, ...xs]) => [...xs];
    return (
        <FooterBox id="tpe-footer">
            <FooterLastBox height={256}>
                <Grid container spacing={2} className="grid-container">
                    <DashGrid item xs={12} sm={3} md={2}>
                        <Grid container spacing={2} className="grid-container">
                            <Grid item xs={12}>
                                <img src="/img/full-logo.svg" alt="TPE full logo" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="footer-subtext">
                                    Your Financial Future Starts With Us
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="social-icons">
                                <IconButton aria-label="delete" href="https://www.facebook.com/tpehub" target="_blank">
                                    <img src="/img/facebook.svg" alt="facebook" />
                                </IconButton>
                                <IconButton aria-label="delete" href="https://www.instagram.com/tpehub/?hl=en" target="_blank">
                                    <img src="/img/instagram.svg" alt="instagram" />
                                </IconButton>
                                {/* <IconButton aria-label="delete" href='' target='_blank'>
                                    <img src="img/linkedin.svg" />
                                </IconButton> */}
                                <IconButton aria-label="delete" href="https://twitter.com/ThePeoplesEcon1" target="_blank">
                                    <img src="/img/twitter.svg" alt="twitter" />
                                </IconButton>
                                <IconButton
                                    aria-label="delete"
                                    href="https://www.youtube.com/channel/UC7pCVKF5W4bWgaEN_Vnownw"
                                    target="_blank"
                                >
                                    <img src="/img/youtube.svg" alt="youtube" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DashGrid>

                    <DashGrid item xs={12} sm={3} md={2} className="menu-grid-item">
                        <Typography variant="h5">Dashboards</Typography>
                        <List component={Stack} direction="column" className="list-item">
                            {toolsDetails[1].children.map((val, i) => {
                                return (
                                    <CustomListItem key={i + '_dashboard_footerparentMenus'} disablePadding>
                                        <Box
                                            sx={{
                                                '& a span': {
                                                    fontSize: '14px'
                                                }
                                            }}
                                        >
                                            <ListItemLink to={val.to} primary={val.name} />
                                        </Box>
                                    </CustomListItem>
                                );
                            })}
                        </List>
                    </DashGrid>
                    <DashGrid item xs={12} sm={3} md={1} className="menu-grid-item">
                        <Typography variant="h5">Others</Typography>
                        <List component={Stack} direction="column" className="list-item">
                            {remove2nd(toolsDetails).map((val, i) => {
                                return (
                                    <CustomListItem key={i + '_other_parentMenus'} disablePadding>
                                        <Box
                                            sx={{
                                                '& a span': {
                                                    fontSize: '14px'
                                                }
                                            }}
                                        >
                                            <ListItemLink to={val.to} primary={val.parent} variant="h1" />
                                        </Box>
                                    </CustomListItem>
                                );
                            })}
                            <CustomListItem key={"privacy1"} disablePadding>
                                <Box
                                    sx={{
                                        '& a span': {
                                            fontSize: '14px'
                                        }
                                    }}
                                >
                                    <ListItemLink to={"/privacy-policy"} primary={"Privacy Policy"} variant="h1" />
                                </Box>
                            </CustomListItem>
                        </List>
                    </DashGrid>
                    <DashGrid item xs={12} sm={3} md={3} className="subscribe-gird-item">
                        <Typography variant="h5">Subscribe </Typography>
                        <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5, display: 'block', fontSize: '14px' }}>
                            Subscribe to our newsletter! Stay always in touch!
                        </Typography>
                        <EmailSub />
                    </DashGrid>
                </Grid>

                <Grid container spacing={2} className="grid-container">
                    <DashGrid item xs={12} sm={12} md={12} className="menu-grid-item">
                        <Typography sx={{ width: '100%', textAlign: 'center' }} variant="h5">
                            Calculators
                        </Typography>
                        <List component={Stack} direction="row" flexWrap="wrap" className="list-item" textAlign={'left'}>
                            {toolsDetails[0].children.map((val, i) => {
                                const menutitle = val.title || val.name;
                                const child = val?.children || [];
                                return (
                                    <Fragment key={i + '_tooldetailParent'}>
                                        <Box
                                            sx={{
                                                paddingRight: '15px',
                                                '& a p': {
                                                    fontSize: '12px'
                                                }
                                            }}
                                        >
                                            <Typography variant="h6">{menutitle}</Typography>
                                            <Divider />
                                            <MenuList>
                                                {child.map((submenu, s) => {
                                                    return submenu.to !== '/coming-soon' ? (
                                                        <MenuItem
                                                            component={Link}
                                                            key={s + '_submenus'}
                                                            disableGutters
                                                            to={submenu.to}
                                                            onClick={() => window.scrollTo(0, 0)}
                                                        >
                                                            <Typography variant="body1">{submenu.name}</Typography>
                                                        </MenuItem>
                                                    ) : (
                                                        <></>
                                                    );
                                                })}
                                            </MenuList>
                                        </Box>
                                    </Fragment>
                                    // <CustomListItem key={i + 'footer_index_clmenu'} disablePadding>
                                    //     <ListItemLink to={val.to} primary={val.title} />
                                    // </CustomListItem>
                                );
                            })}
                        </List>
                    </DashGrid>
                </Grid>

                <DisclaimerBox>
                    <Typography variant="body2">
                        Disclaimer: The information provided by The People’s Economist (TPE) in our financial calculators and dashboards is
                        for general informational purposes only and is not intended to provide specific financial or investment advice. The
                        calculations provided by these tools are based on certain assumptions that may vary with your specific
                        circumstances. Though TPE does our best to assure the accuracy of our calculators, we make certain simplifying
                        assumptions in some cases and the results of the financial calculators are not guaranteed and are not a substitute
                        for professional financial advice. Users should seek the advice of a finance professional before making any
                        financial decisions. Though we do our best to ensure the accuracy of the calculators, TPE is not responsible for any
                        errors or omissions, or for any action taken in reliance on information contained on this website. TPE expressly
                        disclaims all liability in respect of any actions taken or not taken based on any contents of this website. By using
                        TPE financial calculators and dashboards, you agree to hold TPE harmless from any and all losses, liabilities, or
                        expenses incurred as a result of using the information provided. The use of TPE Website and its contents does not
                        create a client relationship with TPE. TPE may update or modify this disclaimer at any time without prior notice. By
                        continuing to use TPE’s financial calculators after any changes to this disclaimer, you accept and agree to any and
                        all modifications. Please contact us with any questions or concerns about this disclaimer or its use of financial
                        calculators
                    </Typography>
                </DisclaimerBox>
                <Divider />
                <Typography variant="caption" className="dashboard-subheading">
                    All trademark and copyrights registered to “The People’s Economist”
                </Typography>
            </FooterLastBox>
        </FooterBox>
    );
};

export default Footer;
