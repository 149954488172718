import { makeStyles } from '@mui/styles';
import { Container, Grid, Typography } from '@mui/material';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import studentCalPageStyle from 'assets/jss/material-kit-react/views/studentCalPage';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import StudentLoanForm from './StudentLoanForm';
import StudLoanOuput from './StudLoanOutput';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import StudLoanDesc from './StudLoanDesc';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import { BudgetNotePaper } from 'assets/jss/ChartStyle';
import Meta from 'components/meta';
const useStyles = makeStyles(styles);
const studUseStyles = makeStyles(studentCalPageStyle);

export default function StudentLoanIndex(props) {
    const studLoanCal = useSelector((state) => state.studLoanCal);
    const navigate = useNavigate();
    const classes = useStyles();
    const general = useSelector((state) => state.generalCal);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'stdCal';
        }
    }, []);

    const studClasses = studUseStyles();
    const render = () => {
        return (
            <>
                {studLoanCal.loader ? <Loader /> : ''}
                <BudgetNotePaper elevation={0}>
                    <Typography className={'noteLabel'}> Note: </Typography>
                    <Typography className={'noteText'}> All costs are based on annual and current estimates. </Typography>
                </BudgetNotePaper>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <StudentLoanForm
                            classes={classes}
                            defaultoverrideData={general.defaultoverrideData}
                            studClasses={studClasses}
                            savedStudLoan={studLoanCal.savedStudLoan}
                            studLoanData={studLoanCal.studLoanData}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <StudLoanOuput data={studLoanCal.studLoanData} classes={studClasses} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Debt Calculators'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Student Loan Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Student Loan Calculator - Calculate Your Monthly Payments | TPE Hub"
                    description="Use our Student Loan Calculator to estimate your monthly payments and see how different interest rates and loan terms affect your repayment plan. Plan your finances and manage your student loans effectively with our easy-to-use calculator."
                    page="/student-loan-calculator"
                />
                <CommonCalculator variantText="Student Loan Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <StudLoanDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
