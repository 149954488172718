import React, { useEffect, useRef } from 'react';
import { Container, createStyles, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import studentCalPageStyle from 'assets/jss/material-kit-react/views/studentCalPage';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import { useSelector } from 'react-redux';
import TaxEstiForm from './TaxEstiForm.jsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import TaxOutput from './TaxOutput';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import TaxEstiDesc from './TaxEstiDesc.jsx';
import Meta from 'components/meta.jsx';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...styles(theme),
        ...studentCalPageStyle(theme)
    })
);
export default function TaxEstimatorIndex(props) {
    const budget = useSelector((state) => state.budget);
    const navigate = useNavigate();
    const classes = mergedStyle();

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'taxEst';
        }
    }, []);

    const render = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4.5} lg={4.5} className={props.widgetcal && classes.widgetCalIndivGrid}>
                    <TaxEstiForm classes={classes} taxEstiData={budget.taxEstiData} />
                </Grid>
                <Grid item xs={12} sm={6} md={7.5} className={props.widgetcal && classes.widgetCalIndivGrid}>
                    <TaxOutput data={budget.taxEstiData} classes={classes} />
                </Grid>
            </Grid>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Budgets & Taxes'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Tax Estimator
        </Typography>
    ];
    return (
        (props.widgetcal && <div>{render()}</div>) || (
            <>
                <Meta
                    title="Tax Estimator | TPE Hub"
                    description="Use our Tax Estimator tool to quickly and accurately estimate your taxes. Enter your financial information and get an estimate of your tax liability for the year. Simplify your tax planning with our easy-to-use calculator."
                    page="/tax-estimator-calculator"
                />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Tax Estimator" variantType="h1" />
                <MainBox>
                    <Container maxWidth="lg">
                        {budget.loader ? <Loader /> : ''}
                        {render()}
                        <TaxEstiDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
