import { Autocomplete, Divider, Grid, TextField, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitBudgetCalSuccess } from 'redux/actions/BudgetActions';
import { submitMutualFundTicker } from 'redux/actions/MutualFundActions';
import RenderField from 'utils/RenderField';
import CustomDialogTitle from 'components/DialogTitle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { StyledAddRemoveIconBtn } from 'assets/jss/CommonCalStyle';
import { fetchTickerList } from 'redux/actions/EquityDashboardActions';

// Debounce function for limiting API requests while typing
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
}

const AddNewTicker = (props) => {
    // const { tickerList } = props;
    const { tickers, loader } = useSelector((state) => state.equityDashboard);
    const [inputValue, setInputValue] = useState('');
    const debouncedInput = useDebounce(inputValue, 300);
    const [offset, setOffset] = useState(0);
    const limit = 20;
    const [open, setOpen] = React.useState(false);
    const [tickerIndex, setIndexes] = useState([0]);
    const [counter, setCounter] = useState(0);
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });
    const addNewLifeEvent = () => {
        const newCounter = counter + 1;
        setIndexes((prevIndexes) => [...prevIndexes, newCounter]);
        setCounter((prevCounter) => prevCounter + 1);
    };
    const removeLifeEvent = (index) => () => {
        setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        reset({});
        setOpen(false);
        setIndexes([0]);
        setCounter(0);
    };
    //to submit the form data
    const onSubmit = (data) => {
        // const tickerArr = [];
        // tickerIndex.forEach((ele) => {
        //     if (data['ticker_' + ele]?.match(/[,"' ]+/g)) {
        //         let updatedVal = data['ticker_' + ele].replace(/[,"' ]+/g, ' ').trim();
        //         updatedVal = updatedVal.split(' ');
        //         updatedVal.forEach((subval) => {
        //             tickerArr.push(subval);
        //         });
        //     } else {
        //         tickerArr.push(data['ticker_' + ele]);
        //     }
        // });
        dispatch(submitMutualFundTicker({ tickers: [data.symbol] }, 'post'));
        handleClose();
    };

    useEffect(() => {
        // reset output when route will change
        return () => {
            dispatch(submitBudgetCalSuccess({}));
        };
    }, []);

    // const handleFormInputChange = (e) => {
    //   const val = e.target.value;
    //   console.log(val, val.match(/[,"' ]+/g));
    //   if (val.match(/[,"' ]+/g)){
    //     let updatedVal=val.replace(/[,"' ]+/g, " ").trim();
    //     updatedVal = updatedVal.split(" ");
    //     let tickerCounter = counter+1;
    //     updatedVal.forEach((subval,i) => {
    //       console.log(subval, tickerCounter, i, "ticker_" + tickerCounter , "updatedVal");
    //       setValue("ticker_" + tickerCounter, subval);
    //       tickerCounter++;
    //       addNewLifeEvent();
    //     });
    //   }
    // };

    useEffect(() => {
        dispatch(fetchTickerList({ filter: debouncedInput, offset: offset, limit: limit }));
    }, [debouncedInput]);

    useEffect(() => {
        if (debouncedInput) {
            dispatch(fetchTickerList({ filter: inputValue }));
        }
    }, [debouncedInput]);

    const handleScroll = (event) => {
        const threshold = 100;
        const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + threshold;

        if (bottomReached && !loader) {
            setOffset((prevOffset) => prevOffset + limit);
            const objData = { filter: debouncedInput, offset: offset, limit: limit };
            dispatch(fetchTickerList(objData, 'scroll'));
        }
    };

    const highlightMatch = (text, input) => {
        if (!input) {
            return <span style={{ color: 'grey' }}>{text}</span>;
        }
        const regex = new RegExp(`(${input})`, 'gi');
        const parts = text?.split(regex);

        return parts?.map((part, index) =>
            regex?.test(part) ? (
                <span key={index} style={{ fontWeight: 'bold', color: 'black' }}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    };

    return (
        <div>
            <Tooltip title="Add" arrow>
                <CustomButton style={{ width: '100%' }} variant="text" startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen}>
                    Add New Ticker
                </CustomButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="ticker-dialog-title">
                <CustomDialogTitle id="ticker-dialog-title" onClose={handleClose}>
                    Add Ticker(s)
                </CustomDialogTitle>
                <Divider />
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {tickerIndex.map((index) => {
                            return (
                                <Grid container alignItems="baseline" key={'addnewticker' + index} marginBottom={2}>
                                    <Grid item xs={12} sx={{ display: 'flex' }}>
                                        {/* <Controller
                                            control={control}
                                            name={`ticker_${index}`}
                                            id={`ticker_${index}`}
                                            render={({ onChange, ...props }) => (
                                                <Autocomplete
                                                    style={{ width: '300px' }}
                                                    options={tickerList}
                                                    freeSolo
                                                    autoComplete
                                                    getOptionLabel={(option) =>
                                                        option.label?.toString() ? option.label?.toString() : option
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Tickers" placeholder="Tickers" />
                                                    )}
                                                    onInputChange={(event, newInputValue) => {
                                                        setValue(
                                                            `ticker_${index}`,
                                                            newInputValue.value ? newInputValue.value : newInputValue
                                                        );
                                                    }}
                                                    onChange={(e, data) => {
                                                        setValue(`ticker_${index}`, data.value ? data.value : data);
                                                    }}
                                                    {...props}
                                                />
                                            )}
                                        /> */}
                                        <Controller
                                            name="symbol"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <Autocomplete
                                                    style={{ width: '300px' }}
                                                    freeSolo
                                                    options={tickers}
                                                    getOptionLabel={(option) => option.ticker}
                                                    loading={loader}
                                                    inputValue={value || ''}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                        onChange(newInputValue);
                                                    }}
                                                    filterOptions={(options, { inputValue }) =>
                                                        options.filter(
                                                            (option) =>
                                                                option?.ticker?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                option?.company_name?.toLowerCase().includes(inputValue?.toLowerCase())
                                                        )
                                                    }
                                                    renderOption={(props, option, { index }) => (
                                                        <div {...props} style={{ width: '100%' }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'flex-start',
                                                                    padding: '10px'
                                                                }}
                                                            >
                                                                <span style={{ fontWeight: 'bold', color: '#1976d2' }}>
                                                                    {option.ticker}
                                                                </span>
                                                                <span>{highlightMatch(option.company_name, inputValue)}</span>
                                                                <hr style={{ margin: 0, border: '1px solid gray', width: '230px' }} />
                                                            </div>
                                                        </div>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Symbol"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {loader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                    ListboxProps={{
                                                        onScroll: handleScroll,
                                                        style: { maxHeight: 200, overflow: 'auto' }
                                                    }}
                                                />
                                            )}
                                        />
                                        {tickerIndex.length - 1 === tickerIndex.indexOf(index) && tickerIndex.length < 10 ? (
                                            <StyledAddRemoveIconBtn className={`addIcon`} onClick={addNewLifeEvent} disabled>
                                                <AddCircleIcon />
                                            </StyledAddRemoveIconBtn>
                                        ) : (
                                            ''
                                        )}
                                        {tickerIndex.length > 1 ? (
                                            // <GridItem
                                            //   xs={1}
                                            //   className={classes.removeIconButton}>
                                            <StyledAddRemoveIconBtn className={`removeIcon`} onClick={removeLifeEvent(index)}>
                                                <RemoveCircleIcon />
                                            </StyledAddRemoveIconBtn>
                                        ) : (
                                            // </GridItem>
                                            ''
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                        <CalCardActions className="contactus-cardaction">
                            <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                Submit
                            </CustomButton>
                        </CalCardActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default AddNewTicker;
