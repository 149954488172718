import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import Loader from 'components/CommonComponent/Loader.js';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CommonCalculator from 'components/CommonComponent/CommonCalculator';
import { Container, Typography } from '@mui/material';
import LoanDashDesc from './LoanDashDesc';
import LoanDashTable from './LoanDashTable';
import Meta from 'components/meta';
const useStyles = makeStyles(styles);

export default function LoanDashBoardIndex(props) {
    const loanDashboardData = useSelector((state) => state.loanDashboard);
    const navigate = useNavigate();

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'loanDash';
        }
    }, []);

    const classes = useStyles();
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Dashboards'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Loan Dashboard
        </Typography>
    ];
    return (
        <>
            <Meta
                title="Loan Dashboard | TPE Hub"
                description="Access and manage all your loan information in one place with our Loan Dashboard. Track payments, view loan details, and plan your finances with ease."
                page="/loan-dashboard"
            />
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="Loan Dashboard" />
            <MainBox>
                <Container maxWidth="lg">
                    {loanDashboardData.loader ? <Loader /> : ''}
                    <LoanDashTable loanDashboardData={loanDashboardData} classes={classes} />
                    <LoanDashDesc />
                </Container>
            </MainBox>
        </>
    );
}
