import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const BudgetDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Budget Calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                In our budget calculator, you first input your basic budget amount for each category you then click
                                calculate. After you input your budget items and click calculate. The output produces a pie chart showing
                                you how much you spend in category. In addition, we show your total expenses and a budget to income ratio as
                                well as a traffic light indicator showing – green, yellow red. The traffic light, which is a common feature
                                of our calculators indicates whether you are in safe zone (Green), warning (Yellow) and Red (danger).
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The income will be downloaded from your settings page if you saved it there, otherwise you need to enter it.
                                Further we allow you to save up to 3 budgets. These can be for other members of your household or family, or
                                can be several scenarios for yourself.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The update button allows you to update a saved budget and the Click Me/Reload button loads the data from
                                your latest saved portfolio.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default BudgetDesc;
