import { Container, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import ProductSection from './FixedIncomeDashboard';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import { useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MainBox } from 'assets/jss/GeneralStyle';
import FixedIncomeDesc from './FixedIncomeDesc.jsx';
import Tour from 'reactour';
import Meta from 'components/meta';

export default function FixedIncomeDashboardIndex(props) {
    const navigate = useNavigate();
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'fixInc';
        }
    }, []);

    const handleButtons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {currentStep !== 0 && (
                    <button
                        style={{
                            padding: '10px',
                            border: '1px solid black',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            fontSize: '18px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep - 1)}
                    >
                        Back
                    </button>
                )}
                {currentStep !== steps.length - 1 ? (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep + 1)}
                    >
                        Continue
                    </button>
                ) : (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => closeTour()}
                    >
                        Finish
                    </button>
                )}
            </div>
        );
    };

    let steps = [
        {
            selector: '#load',
            content: () => (
                <>
                    <h4>Load The Data</h4>
                    <p>Welcome to Load The Data! here you can reload for the data.</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#corporate',
            content: () => (
                <>
                    <h4>Corporate Rates</h4>
                    <p>Now, let me introduce to Corporate Rates! here you can see the Corporate Rates data table.</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 900 ? '' : '#crc',
            content: () => (
                <>
                    <h4>Corporate Rates Chart</h4>
                    <p>Now, let me introduce to Corporate Rates Chart! here you can see the Corporate Rates data with chart.</p>
                    {handleButtons()}
                </>
            )
        },

        {
            selector: '#treasury',
            content: () => (
                <>
                    <h4>Treasury Rates</h4>
                    <p>Now, let me introduce to Treasury Rates! here you can see the Treasury Rates data table.</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 900 ? '' : '#trd',
            content: () => (
                <>
                    <h4>Treasury Rates Chart</h4>
                    <p>Now, let me introduce to Treasury Rates Chart! here you can see the Treasury Rates data with chart.</p>
                    {handleButtons()}
                </>
            )
        }
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    const closeTour = () => {
        setIsTourOpen(false);
        document.body.style.overflow = 'auto';
    };

    const handleTour = () => {
        setIsTourOpen(true);
        document.body.style.overflow = 'hidden';
    };
    const investDashboardData = useSelector((state) => state.investDashboard);
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Dashboards'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Fixed Income Dashboards
        </Typography>
    ];
    return (
        <>
            <Meta
                title="Fixed Income Investments | TPE Hub"
                description="Explore fixed income investment options to achieve secure and predictable returns. Learn about bonds, certificates of deposit (CDs), and other low-risk investment opportunities."
                page="/fixed-income-dashboard"
            />
            <div>
                <Tour
                    steps={steps}
                    isOpen={isTourOpen}
                    onRequestClose={closeTour}
                    goToStep={currentStep}
                    showButtons={false}
                    showNavigation={true}
                    showNumber={false}
                    closeWithMask={false}
                    closeWithEsc={false}
                    rounded={10}
                    maskSpace={10}
                />
            </div>
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="Fixed Income Dashboard" handleTour={handleTour} />
            <MainBox>
                <Container maxWidth="lg">
                    {investDashboardData.loader ? <Loader /> : ''}
                    <ProductSection investDashboardData={investDashboardData} />
                    <FixedIncomeDesc />
                </Container>
            </MainBox>
        </>
    );
}
