import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles, withStyles } from "@mui/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Parallax from "components/Parallax/Parallax.js";
import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { CustomButton } from "assets/jss/ButtonStyle";
import Meta from "components/meta";

const useStyles = makeStyles(styles);
const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
})(Typography);
const ComingSoon = (props) => {
  const classes = useStyles();
  return (
      <Parallax filter darkenShadow={true} style={props.style || { height: '100vh' }} image={require('assets/img/comingSoon.jpg')}>
             <Meta
                title="Coming Soon | TPE Hub"
                description="We're working hard to bring you something new and exciting. Stay tuned for updates and be the first to know when we launch our new features. Thank you for your patience and support!"
                page="/coming-soon"
            />
          <div className={classes.container} justify="center">
              <Grid container justifyContent="center">
                  <Grid item xs={10} sm={6} alignItems="center">
                      <WhiteTextTypography variant="h2" align="center" fontWeight="fontWeightMedium">
                          Coming Soon
                      </WhiteTextTypography>
                      <p align="center">we're working hard on this enhancement</p>
                      {props.hideHomeBtn ? (
                          ''
                      ) : (
                          // <GridContainer justify="center">
                              <CustomButton component={Link} size="lg" to="/" variant="contained" rel="noopener noreferrer">
                                  <HomeIcon />
                                  Go To Home
                              </CustomButton>
                          // </GridContainer>
                      )}
                  </Grid>
              </Grid>
          </div>
      </Parallax>
  );
};
export default ComingSoon;
