import React, { useState } from 'react';
import { Box, ButtonGroup, Button } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import BlogsListView from './BlogsListView';
import BlogsTileView from './BlogsTileView';

function BlogList({ blogsList, allCategoriesColumn, blogCategory }) {
    const [isListView, setIsListView] = useState(false);
    const setView = () => {
        setIsListView(!isListView);
    };
    return (
        <Box sx={{ padding: '15px' }}>
            <Box display="flex" justifyContent="end">
                <ButtonGroup disableElevation onClick={setView} className="toggleButtonGroup" size="small">
                    <Button variant={isListView ? 'contained' : 'outlined'} className="toggleButton" sx={{ width: '35px' }}>
                        <FormatListBulletedIcon />
                    </Button>
                    <Button variant={!isListView ? 'contained' : 'outlined'} className="toggleButton" sx={{ width: '35px' }}>
                        <GridViewIcon />
                    </Button>
                </ButtonGroup>
            </Box>
            {isListView ? (
                <BlogsListView blogsList={blogsList} allCategoriesColumn={allCategoriesColumn} />
            ) : (
                <BlogsTileView blogCategory={blogCategory} blogsList={blogsList} />
            )}
        </Box>
    );
}

export default BlogList;
