export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-contained': {
                        color: theme.colors?.white
                    },
                    // color:theme.colors?.white,
                    borderRadius: '4px',
                    fontWeight: 700,
                    alignItems: 'center',
                    textAlign: 'center',
                    lineHeight: '14px',
                    '&.MuiButton-outlined': {
                        border: `1.5px solid ${theme.colors?.primaryMain}`
                    }
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `12px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        // MuiCardActions: {
        //     styleOverrides: {
        //         root: {
        //             padding: '24px'
        //         }
        //     }
        // },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.colors.secondaryDark,
                        backgroundColor: theme.colors.secondaryLight,
                        '&:hover': {
                            // backgroundColor: theme.colors.secondaryLight
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.colors.secondaryDark
                        }
                    },
                    '&:hover': {
                        // backgroundColor: theme.colors.secondaryLight,
                        // color: theme.colors.secondaryDark,
                        '& .MuiListItemIcon-root': {
                            color: theme.colors.secondaryDark
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '1rem',
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.greyDark
                    },
                    '&:hover $notchedOutline': {
                        // borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.greyLight
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primaryMain
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    background: theme.colors?.primary200
                },
                indicator: {
                    height: '3px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: theme.colors?.grey600
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.greyMain
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    padding: 0,
                    border: 0
                },
                toolbar: {
                    padding: 0
                },
                input: {
                    margin: 'auto'
                },
                displayedRows: {
                    margin: 'auto',
                    position: 'absolute',
                    right: '35px',
                    padding: '10px'
                },
                selectLabel: {
                    margin: 'auto'
                }
            }
        }
    };
}
