import React from 'react';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { Controller } from 'react-hook-form';
import CustomSlider from 'components/CommonComponent/CustomSlider';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import InputAdornment from '@mui/material/InputAdornment';
import { numberWithCommas } from 'utils/Utils';
import Autocomplete from '@mui/material/Autocomplete';
import Rating from '@mui/material/Rating';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'react-phone-input-2/lib/material.css';
import { InputLabel } from '@mui/material';
import CustomRadio from 'components/CommonComponent/CustomRadio';
import CustomCheckbox from 'components/CommonComponent/CustomCheckbox';
import CustomPhone from 'components/CustomPhone/CustomPhone';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import * as tooltip from 'Tooltip.json';
const price = /^[,0-9$ ]+$/g,
    validString = /^[a-zA-Z0-9&!',.\-_ ]{1,20}$/,
    validString2 = /^[a-zA-Z0-9&!',.\-_ ]{1,40}$/,
    decimalRate = /^[0-9,]*?(\.[0-9]{1,2})?$/g,
    non0decimalRate = /^[0-9,]*?(\.[0-9]{1,2})?$/g,
    email =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
    password = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,20}$/g,
    contactNumber = /^[0-9]{8,}/g,
    non0Num = /^(?![0]*$)\d{1,}(?:\d{1,})?$/g,
    number = /^[0-9]+$/g,
    decimalRate3 = /^[0-9,]*?(\.[0-9]{1,3})?$/g,
    address = /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>/ -]{1,50}$/g,
    name_no_space = /^[a-zA-Z0-9!@#$%^&*(),.?\":{}|<>]{1,100}$/g,
    descriptionString = /^([a-z0-9A-Z&!,.-_ ]{1,250})$/g;
// const price = /^[,0-9$ ]+$/g,
//   validString = /^([a-z0-9A-Z ]{1,20})$/g,
//   decimalRate = /^[0-9,]*?(\.[0-9]{1,2})?$/g,
//   email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
//   password = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,12}$/g,
//   contactNumber = /^[0-9]{8,}/g,
//   number = /^[0-9]+$/g;
const labels = ['Maturity Years', 'Repay Type'];

const isValidInterestRate = (rate) => {
    const numericRate = rate;
    return !isNaN(numericRate) && numericRate >= 0 && numericRate <= 100;
};

const RenderField = (props) => {
    const {
        type,
        name,
        control,
        defaultValue,
        label,
        id,
        options,
        errorMessage,
        required,
        disabled,
        placeholder,
        customPatternName,
        helperText,
        style,
        CustomInputProps,
        min = 0,
        max = 10,
        max_value = 0,
        customValidateLimit,
        getValues,
        setValue = null,
        thousandSeparator,
        prefix,
        CustomLabelProps,
        outputField,
        tooltipText,
        OverrideValue,
        padTop = 0,
        fieldName,
        indexValue,
        addNew,
        selectedOption,
        valid,
        ...rest
    } = props;

    const inputChangedHandler = (e, fieldName) => {
        const currValue = e.target.value.replace(/\,/g, '');
        if (setValue) setValue(fieldName + 'UnFormattedValue', currValue);
    };

    switch (type) {
        case 'multiline':
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue || OverrideValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        const tooltipKey = label || fieldName;
                        return (
                            <TextareaAutosize
                                name={name}
                                id={id ? id : name}
                                placeholder={placeholder}
                                value={value}
                                onChange={(e) => {
                                    rest.handleOnChange && rest.handleOnChange(e);
                                    onChange(e);
                                    inputChangedHandler(e, fieldName);
                                }}
                                style={{ width: '100%', minHeight: '80px', ...style }} // Adjust the height as needed
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                // Skip validation if valid prop is set to "noValid"
                                if (valid === 'noValid') {
                                    return true; // No validation
                                }
                                if (required) {
                                    if (!value) {
                                        return `${label || fieldName} required`;
                                    } else if (customPatternName === 'confirmPassword') {
                                        if (value === getValues()['password']) {
                                            return true;
                                        } else {
                                            return 'The passwords do not match';
                                        }
                                    } else if (customPatternName) {
                                        const regexPattern = new RegExp(eval(customPatternName), 'g');
                                        if (regexPattern !== undefined && !value.toString().match(regexPattern)) {
                                            if (customPatternName === 'password') {
                                                return `Use 8 or more characters with a mix of capital and small letters, numbers & symbols`;
                                            } else if (customPatternName == 'descriptionString' && value.length > 100) {
                                                return `Please enter valid ${label || fieldName} upto 100 characters. `;
                                            } else if (customPatternName == 'validString' && value.length > 20) {
                                                return `Please enter valid ${label || fieldName} upto 20 characters. `;
                                            } else {
                                                return `Please enter valid ${label || fieldName}`;
                                            }
                                        }
                                    }
                                } else {
                                    if (value) {
                                        if (customPatternName) {
                                            const regexPattern = new RegExp(eval(customPatternName), 'g');
                                            if (regexPattern !== undefined && !value.toString().match(regexPattern)) {
                                                if (customPatternName == 'validString' && value.length > 20) {
                                                    return `Please enter valid ${label || fieldName} upto 20 characters. `;
                                                } else if (customPatternName == 'address' && value.length > 50) {
                                                    return `Please enter valid ${label || fieldName} upto 50 characters. `;
                                                }
                                                return `Please enter valid ${label || fieldName}`;
                                            }
                                        } else if (!value.match(validString)) {
                                            return `Please enter valid text data`;
                                        }
                                    }
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'text':
        case 'password':
        case 'date':
        case 'email':
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        const tooltipKey = label || fieldName;
                        return (
                            <CustomInput
                                autoComplete="off"
                                labelText={label}
                                value={value === undefined ? defaultValue : value}
                                required={required}
                                id={id ? id : name}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputRef={ref}
                                disabled={disabled}
                                error={!!error}
                                helperText={error ? error.message : helperText}
                                inputType={type}
                                onChange={(e) => {
                                    rest.handleOnChange && rest.handleOnChange(e);
                                    onChange(e);
                                }}
                                CustomInputProps={
                                    tooltip.default?.[tooltipKey]
                                        ? // tooltipText
                                          {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CustomTooltip tooltipText={tooltip.default?.[tooltipKey]} label={tooltipKey} />
                                                  </InputAdornment>
                                              ),
                                              ...CustomInputProps
                                          }
                                        : CustomInputProps
                                }
                                inputProps={{ maxLength: rest.maxLength, ...rest.inputProps }}
                                defaultValue={defaultValue || value}
                                InputLabelProps={addNew ? (value ? { shrink: true } : { shrink: false }) : null}
                                placeholder={placeholder}
                                style={{ paddingTop: padTop, ...style }}
                                CustomLabelProps={CustomLabelProps}
                                {...rest}
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                // Skip validation if valid prop is set to "noValid"
                                if (valid === 'noValid') {
                                    return true; // No validation
                                }
                                if (required) {
                                    if (!value) {
                                        return `${label || fieldName} required`;
                                    } else if (customPatternName === 'confirmPassword') {
                                        if (value === getValues()['password']) {
                                            return true;
                                        } else {
                                            return 'The passwords do not match';
                                        }
                                    } else if (customPatternName) {
                                        const regexPattern = new RegExp(eval(customPatternName), 'g');
                                        if (regexPattern !== undefined && !value.toString().match(regexPattern)) {
                                            if (customPatternName === 'password') {
                                                return `Use 8 or more characters with a mix of capital and small letters, numbers & symbols`;
                                            } else if (customPatternName == 'descriptionString' && value.length > 250) {
                                                return `Please enter valid ${label || fieldName} upto 250 characters. `;
                                            } else if (customPatternName == 'validString' && value.length > 20) {
                                                return `Please enter valid ${label || fieldName} upto 20 characters. `;
                                            } else {
                                                return `Please enter valid ${label || fieldName}`;
                                            }
                                        }
                                    } else if (!validString.test(value)) {
                                        return 'Please enter a valid task name (1-20 characters, including letters, numbers, and allowed special characters).';
                                    }
                                } else {
                                    if (value) {
                                        if (customPatternName) {
                                            const regexPattern = new RegExp(eval(customPatternName), 'g');
                                            if (regexPattern !== undefined && !value.toString().match(regexPattern)) {
                                                if (customPatternName == 'validString' && value.length > 20) {
                                                    return `Please enter valid ${label || fieldName} upto 20 characters. `;
                                                } else if (customPatternName == 'address' && value.length > 50) {
                                                    return `Please enter valid ${label || fieldName} upto 50 characters. `;
                                                } else if (fieldName == 'Zip Code') {
                                                    return true;
                                                }
                                                return `Please enter valid ${label || fieldName}`;
                                            }
                                        } else if (!validString2.test(value)) {
                                            return 'Please enter a valid description (1-40 characters, including letters, numbers, and allowed special characters).';
                                        }
                                    }
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'number':
            const currValue = defaultValue || OverrideValue;
            return (
                <>
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={currValue}
                        render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                            const customValue = thousandSeparator ? numberWithCommas(OverrideValue || value) : OverrideValue || value;
                            const tooltipKey = label || fieldName;
                            return (
                                <>
                                    <CustomInput
                                        autoComplete="off"
                                        labelText={label}
                                        value={label === 'Index' ? indexValue : customValue}
                                        required={required}
                                        id={id ? id : name}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputRef={ref}
                                        InputLabelProps={addNew ? (value ? { shrink: true } : { shrink: false }) : null}
                                        disabled={
                                            label === 'Interest Rate' && props.floatingOption
                                                ? true
                                                : label === 'Margin' && !props.floatingOption
                                                ? true
                                                : label === 'Maturity Years' && props.creditCardOption
                                                ? true
                                                : label === 'Actual payment Made' && !props.creditCardOption
                                                ? true
                                                : label === 'Index' && props.indexType === 'custom'
                                                ? false
                                                : props?.header === 'Index' && props.indexType === 'custom'
                                                ? false
                                                : disabled
                                        }
                                        error={
                                            (props.creditCardOption && labels.includes(label)) ||
                                            (!props.creditCardOption && label === 'Actual payment Made') ||
                                            (props.floatingOption && label === 'Interest Rate') ||
                                            (!props.creditCardOption && labels.includes(props?.header)) ||
                                            (props.creditCardOption && props?.header === 'Actual Payment Made') ||
                                            (!props.floatingOption && props?.header === 'Interest Rate')
                                                ? false
                                                : !!error
                                        }
                                        helperText={
                                            (props.creditCardOption && labels.includes(label)) ||
                                            (!props.creditCardOption && label === 'Actual payment Made') ||
                                            (props.floatingOption && label === 'Interest Rate') ||
                                            (!props.creditCardOption && labels.includes(props?.header)) ||
                                            (props.creditCardOption && props?.header === 'Actual Payment Made') ||
                                            (!props.floatingOption && props?.header === 'Interest Rate')
                                                ? ''
                                                : error
                                                ? errorMessage || error.message
                                                : helperText || ''
                                        }
                                        inputType={'text'}
                                        onChange={(e) => {
                                            inputChangedHandler(e, name);
                                            rest.handleOnChange && rest.handleOnChange(e);
                                            onChange(e);
                                            label === 'Index' && props.setIndexValue(e.target.value);
                                        }}
                                        inputProps={{
                                            minLength: min,
                                            maxLength: rest.maxLength || max + 3,
                                            ...rest.inputProps
                                        }}
                                        defaultValue={defaultValue || value}
                                        placeholder={placeholder}
                                        style={{ paddingTop: padTop, ...style }}
                                        CustomLabelProps={CustomLabelProps}
                                        CustomInputProps={{
                                            startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
                                            endAdornment: tooltip.default?.[tooltipKey] && (
                                                <InputAdornment position="end">
                                                    <CustomTooltip tooltipText={tooltip.default?.[tooltipKey]} label={tooltipKey} />
                                                </InputAdornment>
                                            ),
                                            ...CustomInputProps
                                        }}
                                        {...rest}
                                    />
                                </>
                            );
                        }}
                        rules={{
                            validate: {
                                required: (value) => {
                                    let currValue = OverrideValue?.toString() || value?.toString() || '';
                                    if (required && !currValue) {
                                        return `${label || fieldName} required`;
                                    } else {
                                        if (currValue && !outputField) {
                                            currValue = currValue.replace(/\,/g, '');
                                            if (customPatternName) {
                                                if (label === 'Index' || props?.header === 'Index') return;
                                                const regexPattern = new RegExp(eval(customPatternName), 'g');
                                                if (label === 'Interest Rate' || props?.header === 'Interest Rate') {
                                                    if (!isValidInterestRate(value)) {
                                                        return `Please enter valid ${label || fieldName}`;
                                                    }
                                                }
                                                if (regexPattern !== undefined && !currValue.match(regexPattern)) {
                                                    return `Please enter valid ${label || fieldName}`;
                                                }
                                            } else {
                                                if (!currValue.match(number)) {
                                                    return `Please enter valid number`;
                                                }
                                            }
                                            if (customValidateLimit) {
                                                if (currValue > customValidateLimit) {
                                                    return `${label || fieldName} should be less then ${customValidateLimit}`;
                                                }
                                            }
                                            if (max_value != 0) {
                                                if (max !== undefined && parseFloat(currValue.replace(/,/g, '')) > parseFloat(max_value)) {
                                                    return `${label || fieldName} should be less than or equal to ${max_value}`;
                                                }
                                            } else {
                                            }
                                            return true;
                                        }
                                    }
                                    return true;
                                }
                            }
                        }}
                    />
                </>
            );
        case 'select':
            const tooltipKey = label || fieldName;
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                        <CustomSelect
                            labelText={label}
                            value={value}
                            onChange={(e) => {
                                rest.handleOnChange && rest.handleOnChange(e);
                                onChange(e);
                            }}
                            required={required}
                            id={id ? id : name}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputRef={ref}
                            chipSelect={rest.chipSelect}
                            disabled={label === 'Repay Type' && props.creditCardOption ? true : disabled}
                            error={
                                (props.creditCardOption && labels.includes(label)) ||
                                (!props.creditCardOption && labels.includes(props?.header))
                                    ? // (props.floatingOption && props.header === 'Index Type')
                                      false
                                    : !!error
                            }
                            helperText={
                                (props.creditCardOption && labels.includes(label)) ||
                                (!props.creditCardOption && labels.includes(props?.header))
                                    ? // (props.floatingOption && props.header === 'Index Type')
                                      ''
                                    : error
                                    ? error.message
                                    : helperText
                            }
                            options={options}
                            style={{ paddingTop: padTop, ...style }}
                            option={props.option}
                            setOption={props.setOption}
                            CustomInputProps={
                                tooltip.default?.[tooltipKey]
                                    ? {
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <CustomTooltip
                                                      tooltipText={tooltipText || tooltip.default?.[tooltipKey]}
                                                      label={tooltipKey}
                                                  />
                                              </InputAdornment>
                                          ),
                                          ...CustomInputProps
                                      }
                                    : CustomInputProps
                            }
                            {...rest}
                        />
                    )}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'searchSelect':
            return (
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        const tooltipKey = label || fieldName;
                        return (
                            <Autocomplete
                                value={value || {}}
                                id={id ? id : name}
                                style={{ ...style, width: '98%' }}
                                options={options}
                                includeInputInList={true}
                                onChange={(e, newval) => {
                                    rest.handleOnChange && rest.handleOnChange(e, newval, name);
                                    onChange(newval);
                                }}
                                size="small"
                                getOptionDisabled={(option) => (props?.renderDisableOption && props?.renderDisableOption(option)) || false}
                                required={required}
                                getOptionLabel={(option) => option.label || ''}
                                renderOption={(props, option) => rest.renderOption(props, option)}
                                autoHighlight={true}
                                disabled={disabled}
                                renderInput={(params) => {
                                    return (
                                        <CustomInput
                                            {...params}
                                            inputRef={ref}
                                            required={required}
                                            // InputLabelProps={value ? { shrink: true }: { shrink: false } }
                                            error={!!error}
                                            style={{ ...rest.customAutoInput, paddingTop: padTop }}
                                            helperText={error ? error.message : helperText}
                                            labelText={label}
                                            placeholder={placeholder}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            CustomInputProps={
                                                tooltip.default?.[tooltipKey]
                                                    ? {
                                                          endAdornment: (
                                                              <InputAdornment position="end">
                                                                  <CustomTooltip tooltipText={tooltipKey} label={tooltipKey} />
                                                              </InputAdornment>
                                                          ),
                                                          ...CustomInputProps
                                                      }
                                                    : CustomInputProps
                                            }
                                        />
                                    );
                                }}
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'slider':
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        return (
                            <CustomSlider
                                inputRef={ref}
                                id={id ? id : name}
                                defaultslidervalue={defaultValue || value}
                                onChange={(e) => {
                                    rest.handleOnChange && rest.handleOnChange(e);
                                    onChange(e);
                                }}
                                min={min}
                                max={max}
                                disabled={disabled}
                                label={props.label || null}
                                {...rest}
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'datetime':
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth>
                                {/* {label && <InputLabel style={{ top: '-35px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.8rem' }}>{label}</InputLabel>} */}
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        label={label || ''}
                                        format="L hh:mm a"
                                        id={id ? id : name}
                                        inputRef={ref}
                                        value={value || defaultValue}
                                        onChange={(e) => {
                                            rest.handleOnChange && rest.handleOnChange(e);
                                            onChange(e);
                                        }}
                                        renderInput={(params) => <CustomInput {...params} />}
                                    />
                                </LocalizationProvider>
                                {/* <Datetime
                        popperProps={{
                            positionFixed: true
                        }}
                        id={id ? id : name}
                        onChange={(e) => {
                            rest.handleOnChange && rest.handleOnChange(e);
                            onChange(e);
                        }}
                        closeOnSelect={true}
                        inputRef={ref}
                        timeFormat={rest.timeFormat || true}
                        initialValue={value || defaultValue}
                        className={rest.className}
                        inputProps={{
                            placeholder: placeholder,
                            ...rest.dateinputProps
                        }}
                        {...rest}
                    /> */}
                            </FormControl>
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'rating':
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        return (
                            <Rating
                                name={name || id}
                                onChange={(e, newValue) => {
                                    rest.handleOnChange && rest.handleOnChange(e, newValue, name);
                                    onChange(newValue);
                                }}
                                inputRef={ref}
                                value={value}
                                precision={rest.precision || 0.5}
                                size="large"
                                max={rest.maxStar || 5}
                                {...rest}
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'radio':
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        return (
                            <CustomRadio
                                name={name || id}
                                onChange={(e) => {
                                    rest.handleOnChange && rest.handleOnChange(e);
                                    onChange(e);
                                }}
                                list={rest.list}
                                inputRef={ref}
                                value={value}
                                size={rest.size}
                                {...rest}
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'checkbox':
            return (
                <Controller
                    name={name || id}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        return (
                            <CustomCheckbox
                                name={name || id}
                                checked={value}
                                error={error}
                                helperText={error ? error.message : helperText}
                                onChange={(e) => {
                                    rest.handleOnChange && rest.handleOnChange(e);
                                    onChange(e);
                                }}
                                inputRef={ref}
                                {...rest}
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        case 'phone':
            return (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
                        return (
                            <CustomPhone
                                name={name || id}
                                error={error}
                                specialLabel={`${required ? label + ' *' : label}`}
                                inputProps={{
                                    id: 'react-tel-input',
                                    name: 'phone',
                                    required: true
                                    // autoFocus: true
                                }}
                                className={rest.profilePhone ? 'profile-phone' : ''}
                                inputRef={ref}
                                value={value}
                                onChange={(e) => {
                                    rest.handleOnChange && rest.handleOnChange(e, name);
                                    onChange(e);
                                }}
                                {...rest}
                            />
                        );
                    }}
                    rules={{
                        validate: {
                            required: (value) => {
                                if (required && !value) {
                                    return `${label || fieldName} required`;
                                }
                                return true;
                            }
                        }
                    }}
                />
            );
        default:
        // fieldType = <input id={input.name} {...input} type={type} placeholder={placeholder} />;
    }
};
export default RenderField;
