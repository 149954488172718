import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const PropertyInvestDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Property Investment Tool
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Buying an investment property can be one of most complicated, frustrating and risky things we can do with
                                our money. The TPE property investment calculator allows you to do detailed analysis of the properties you
                                are considering. Our calculator accounts for the various costs of buying and maintaining an investment
                                property.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The output of this calculator displays various return metrics as well as break even occupancy rate.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                You can use this tool as well as the Home Purchase tool to maximise your return.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                We caution that international properties present challenges. Though the main transaction documents may be in
                                English, you may find many documents are only in the local language. These include letters from the tax
                                authorities and minutes from any home-owner association meetings. Further, the legal pitfalls of foreign
                                buying are many. For example, many areas in Spain began requiring hotel like licenses for those listing on
                                Airbnb and similar. Your humble founder was blocked from listing his apartment in Spain after the local
                                municipality passed a law requiring such a license. And applying for one (which should have been easy)
                                resulted in the TPE founder entering into a vortex of lawyers, Spanish documents and accountants. The lesson
                                is that you not only have to worry about laws in existence when you buy, you need to consider future laws.
                                And even in the US eviction rules vary widely and can mutate over time (particularly an issue for landlords
                                during Covid). Finally, if you’re buying in a holiday area where short term rentals are the norm, you need
                                to spend a lot of time understanding what the market is (price too high and you crush your occupancy rate
                                and price too low, and you lose revenue and potentially get dodgy tenants.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default PropertyInvestDesc;
