import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PeerToPeerCollBlock from './PeerToPeerCollBlock';
import PeerToPeerDesc from './PeerToPeerDesc';
import PeerToPeerForm from './PeerToPeerForm';
import PeerToPeerOutput from './PeerToPeerOutput';
import DoRegistration from 'views/DoRegistration';
import Meta from 'components/meta';
const useStyles = makeStyles(styles);
//Pending/Under Review/Declined/Accepted
const PeerToPeerIndex = (props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [triggerLoad, setTriggerLoad] = useState({});
    const [enableSendBtn, setEnableSendBtn] = useState(false);
    const peertopeer = useSelector((state) => state.peertopeer);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'peerCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {peertopeer.loader ? <Loader /> : ''}
                <PeerToPeerCollBlock
                    peertopeer={peertopeer}
                    handleEnableSendBtn={() => setEnableSendBtn(true)}
                    handleTriggerLoad={(val) => setTriggerLoad(val)}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <PeerToPeerForm
                            peertopeer={peertopeer}
                            classes={classes}
                            triggerLoad={triggerLoad}
                            enableSendBtn={enableSendBtn}
                            resetEnableSendBtn={() => setEnableSendBtn(false)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <PeerToPeerOutput peertopeer={peertopeer} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Debt Calculators'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Peer to Peer Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Peer-to-Peer Platform | TPE Hub"
                    description="Discover our Peer-to-Peer platform where you can connect with others, make transactions, and collaborate securely. Experience seamless interactions and build valuable connections with peers."
                    page="/peer-to-peer-calculator"
                />
                <DoRegistration />
                <CommonCalculator variantText="Peer to Peer Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <PeerToPeerDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
};
export default PeerToPeerIndex;
