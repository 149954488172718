import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CardContent, Grid, IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CustomImg } from 'assets/jss/HeaderStyle';
import { BgBox, LeftRightGrid, LoginBox, LoginCard, SubboxWrapperBox } from 'assets/jss/LoginStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import Loader from 'components/CommonComponent/Loader';
import useWindowDimensions from 'components/CommonComponent/UseWindowDimensions';
import Meta from 'components/meta';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { submitForgotResetPassword, submitForgotResetPasswordError } from 'redux/actions/AuthActions';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
export default function ResetPassword(props) {
    const auth = useSelector((state) => state.login);
    const { height } = useWindowDimensions();
    const dispatch = useDispatch();
    const history = useLocation();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        getValues,
        formState: { isSubmitting }
    } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = (e) => setShowPassword({ ...showPassword, [e]: !showPassword[e] });
    useEffect(() => {
        if (getCookie('auth_token') !== null) {
            navigate('/');
        }
    }, []);
    useEffect(() => {
        if (auth.passwordUpdated) {
            navigate('/reset-success');
            dispatch(submitForgotResetPasswordError());
        }
    }, [auth.passwordUpdated]);
    const handleRegister = (data) => {
        const search = history.search;
        const params = new URLSearchParams(search);
        const token = params.get('token');
        const email = params.get('email');
        const objToPass = {
            email: email,
            password: data.password,
            token: token
        };
        dispatch(submitForgotResetPassword(objToPass, 'reset'));
    };
    return (
        <BgBox>
              <Meta
                title="Reset Password | TPE Hub"
                description="Reset your password to regain access to your account. Follow the instructions on this page to securely update your password and restore your account access."
                page="/reset-password"
            />
            <ToastContainer />
            <Grid container>
                {auth.loader ? <Loader /> : ''}
                <LeftRightGrid item xs={12} md={5} className="form-grid">
                    <MenuItem component={Link} disableGutters to={'/'} className="logo-menu">
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            disableRipple={true}
                        >
                            <CustomImg src="img/TPE-logo.svg" className="login-tpe-logo" />
                        </IconButton>
                    </MenuItem>
                    <LoginBox minheight={height}>
                        <LoginCard>
                            <CardContent>
                                <Typography variant="subtitle1" sx={{ mb: 3.5 }} textAlign="center">
                                    Reset Password
                                </Typography>

                                <form onSubmit={handleSubmit(handleRegister)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="password"
                                                control={control}
                                                label="Password"
                                                asterisk
                                                id="password"
                                                type={showPassword['password'] ? 'text' : 'password'}
                                                required
                                                customPatternName="password"
                                                helperText="Use 8 or more characters with a mix of capital and small letters, numbers & symbols"
                                                placeholder="Enter password"
                                                CustomInputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => handleClickShowPassword('password')}
                                                            >
                                                                {showPassword['password'] ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="confirmpassword"
                                                control={control}
                                                label="Confirm Password"
                                                asterisk
                                                id="confirmpassword"
                                                type={showPassword['confirmpassword'] ? 'text' : 'password'}
                                                required
                                                getValues={getValues}
                                                customPatternName="confirmPassword"
                                                placeholder="Repeat password"
                                                CustomInputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => handleClickShowPassword('confirmpassword')}
                                                            >
                                                                {showPassword['confirmpassword'] ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: 'off'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <CalCardActions>
                                        <CustomButton
                                            className="login-btn"
                                            type="submit"
                                            ps={'9px 30px'}
                                            variant="contained"
                                            disabled={isSubmitting}
                                        >
                                            Reset Password
                                        </CustomButton>
                                    </CalCardActions>
                                </form>
                            </CardContent>
                        </LoginCard>
                        {/* </SubboxWrapperBox> */}
                    </LoginBox>
                </LeftRightGrid>
                <LeftRightGrid item xs={12} md={7} className="right-side">
                    <SubboxWrapperBox>
                        <Typography variant="subtitle1">A financial move</Typography>
                        <Typography variant="subtitle1" ml={'37%'}>
                            is smart move
                        </Typography>
                        {/* <PiggyBox></PiggyBox> */}
                        <img src="/img/login-piggy.png" className="login-piggy"  alt='piggy bank'/>
                    </SubboxWrapperBox>
                </LeftRightGrid>
            </Grid>
        </BgBox>
    );
}
