const widgetPage = (theme) => ({
  main: {
    lineHeight: "45px !important",
    color: "#fff",
    zIndex: "1000",
    backgroundImage: "url(img/widget_cal.png)",
    bottom: 172,
    float: "right",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    boxShadow: "1px 2px 10px #555",
    borderRadius: "100%",
    transition: "all 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550)",
    cursor: "pointer",
    "& .cir": {
      display:'none',
      zIndex: "2",
      opacity: "0",
      position: "absolute",
      right: "100px",
      bottom: "50%",
      width: "10px",
      height: "10px",
      borderRadius: "100%",
      transition: "all 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550)",
    },
    "&.open >.cir": {
      display:'inline',
      opacity: "1",
      width: "100px",
      height: 100,
      transformStyle: "preserve-3d",
    },
    "& p": {
      opacity: "0",
      position: "relative",
      float: "right",
      left: "-58px",
      lineHeight: "normal",
      top: "-60px",
      padding: "2px 10px",
      height: "20px",
      borderRadius: "3px",
      background: "#9E9E9E",
      boxShadow: "1px 2px 10px #555",
    },
    "& button": {
      top: "-60px",
      left: "-58px",
      float: "right",
      opacity: "1",
      padding: "2px 10px",
      position: "relative",
      boxShadow: "1px 2px 10px #555",
      lineHeight: "normal",
      "&:nth-of-type(1)": {
        backgroundColor: "#e5316ee0",
        left: "91% !important",
        top: "-44% !important",
      },
      "&:nth-of-type(2)": {
        backgroundColor: "#ff9800",
        left: "-7%",
        top: "-97%",
      },
      "&:nth-of-type(3)": {
        left: "-49%",
        backgroundColor: "#4ab44e",
        top: "-82%",
      },
      "&:nth-of-type(4)": {
        backgroundColor: "#e46a76",
        left: "12%",
        top: "-77%",
      },
    },
    "&:hover > p": { opacity: "1" },
  },
  widgetCalWrapper: {
    height: "100%",
    overflow: "scroll",
  },
});

export default widgetPage;
