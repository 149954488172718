import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { IconButton, Tooltip } from "@mui/material";
import { deleteSavedBudgetCal } from "redux/actions/BudgetActions";
import { numberWithCommas } from "utils/Utils";
const SavedForms = ({ budget,...rest}) => {
  const [sampleTable, setSampleTable] = useState({});

  useEffect(() => {
    if (budget.savedBudgetData.length >0) {
      //  const ignoreFields = [ "modified_at"];
       const ignoreFields = ["id"];
       let formatChartData = [];
      Object.keys(budget.savedBudgetData[0]).forEach((subVal) => {
        if (!ignoreFields.includes(subVal)) {
            const formattedKey = subVal.replaceAll(/_/g, " ");
             formatChartData.push({
               Header: formattedKey || subVal,
               accessor: subVal,
               disableFilters: true,
               Cell: ({ value, row, cell }) => {
                 if (subVal === 'created_at') {
                   return moment.utc(value).format("L LT")
                 }
                 if(subVal === 'modified_at'){
                   return moment.utc(value).format("L LT")
                 }
                 return (!isNaN(value) &&
                  value?.toString().indexOf(".") !== -1 ?
                  numberWithCommas(value?.toFixed(2)):numberWithCommas(value)) || value ||"-"
               }
             });
           }
      });
      formatChartData.unshift({
        Header: "Action",
        Cell: ({ row }) => {
            return (
              <div className={"actionWrapper"}>
                <Tooltip title='delete'>
                  <IconButton
                    aria-label='delete'
                    onClick={() => {
                      rest.dispatch(deleteSavedBudgetCal([row.original.id]))
                       rest.resetForm(true);
                    }
                    }>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='load'>
                  <IconButton
                    aria-label='load'
                    onClick={() => rest.loadSelectedBudget(row.original)}>
                    <AutorenewIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
        }
      });
       setSampleTable({
         data: budget.savedBudgetData,
         column: formatChartData,
       });
    }
  }, [budget.savedBudgetData]);

  return (
    <Accordion square elevation={2}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='saved-budgetcal-content'
        id='saved-budgetcal-header'>
        <Typography >Saved Form Data</Typography>
      </AccordionSummary>

      <AccordionDetails>
        {budget.savedBudgetData.length > 0 ? (
          <>
            <EnhancedTable
              enablePagination={false}
              enableSearch={false}
              columns={sampleTable.column || []}
              data={sampleTable?.data || []}
              enableSort={false}
              hideToolBar={true}
            />
          </>
        ) : (
          ""
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SavedForms;
