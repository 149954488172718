import { useState } from 'react';
import { createStyles, Grid, Paper, Tabs, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import inputstyles from 'assets/jss/material-kit-react/components/customInputStyle.js';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import portfolioPageStyle from 'assets/jss/material-kit-react/views/portfolioPage';
import settingstyles from 'assets/jss/material-kit-react/views/settingPage.js';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { getDefaultOverride, submitDefaultOverride } from 'redux/actions/GeneralCalActions';
import RenderField from 'utils/RenderField';
import { a11yProps } from 'utils/Utils';
import PortfolioTabPanel from '../../PortfolioValueUpload/PortfolioTabPanel';
import EquityTicker from './EquityTicker';
import EconomicTicker from './EconomicTicker';
import FeedbackSetting from './FeedbackSetting';
import ModuleSetting from './ModuleSetting';
import SubscriptionSetting from './SubscriptionSetting';
import TrafficLight from './TrafficLight';
import WidgetCalSetting from './WidgetCalSetting';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import KnowledgeResearchAdmin from './KnowledgeResearch';
import { setActiveSettingsAdminPanelTab } from 'redux/actions/SettingActions';
import ChatbotMessagesAdmin from './ChatbotMessages';
// import './index.css';
import CustomScroll from 'components/CustomScroll';
import KnowledgeResearchTable from './KnowledgeResearchTable/KnowledgeResearchTable';
import PortfolioAllocationfunds from './PortfolioAllocationfunds';
import NewsFeedAdmin from './NewsFeedAdmin';

const tabs = [
    { label: 'Default Assumptions' },
    { label: 'Traffic Light System' },
    { label: 'Permission System' },
    { label: 'Subscriptions' },
    { label: 'Equity Ticker' },
    { label: 'Economic Ticker' },
    { label: 'Portfolio Allocation funds' },
    { label: 'Feedback' },
    { label: 'Widget Calculator' },
    { label: 'Blogs & Research' },
    { label: 'Create Research Table' },
    { label: 'Chat Messages' },
    { label: 'News Feed' }
];

const fieldsDetail = [
    {
        name: 'investReturn',
        fieldName: 'Investment Return',
        fieldLabel: 'Investment Return *',
        type: 'number',
        placeholder: 'Investment Return',
        customPatternName: 'decimalRate',
        helperText: '(in Decimal)'
    },
    {
        name: 'inflation',
        fieldName: 'Inflation',
        fieldLabel: 'Inflation *',
        type: 'number',
        placeholder: 'Inflation',
        customPatternName: 'decimalRate'
    }
];
const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...portfolioPageStyle(theme),
        ...settingstyles(theme),
        ...styles(theme),
        ...inputstyles
    })
);
export default function AdminPanel() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const general = useSelector((state) => state.generalCal);
    const setting = useSelector((state) => state.setting);
    const activeSettingsAdminPanelTab = setting.activeSettingsAdminPanelTab;
    const classes = mergedStyle();
    // const handleTabChange = (event, newValue) => dispatch(setActiveSettingsAdminPanelTab(newValue));
    const [activeTab, setActiveTab] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        dispatch(getDefaultOverride());
    }, []);

    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });
    // to submit override form
    const onSubmit = (data) => {
        const objToPass = {
            infl_rt: data.inflationUnFormattedValue || data.inflation || 0,
            inv_rt: data.investReturnUnFormattedValue || data.investReturn || 0
        };
        dispatch(submitDefaultOverride(objToPass));
    };
    // to pre load value in form for the investment return and inflation fields
    useEffect(() => {
        if (Object.keys(general.defaultoverrideData).length) {
            setValue('investReturn', general.defaultoverrideData.investReturn);
            setValue('inflation', general.defaultoverrideData.inflation);
        }
    }, [general.defaultoverrideData]);

    // useEffect(() => {
    //     const scrollContainer = document.querySelector('.scroll');
    //     const lastTab = document.querySelector('.scroll > div:last-child');
    //     if (!scrollContainer || !lastTab) return;

    //     const containerRight = scrollContainer.getBoundingClientRect().right;
    //     const lastTabRight = lastTab.getBoundingClientRect().right;

    //     const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
    //     if (isLastTabVisible) {
    //         setScrollPosition(3);
    //     }
    // }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scrolll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        dispatch(setActiveSettingsAdminPanelTab(index));
    };

    return (
        <TopBlockBox>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                    style={{
                        cursor: 'pointer',
                        marginLeft: '20px',
                        marginRight: '20px',
                        visibility: scrollPosition !== 0 ? 'visible' : 'hidden'
                    }}
                    onClick={() => handleNavigation('backward')}
                >
                    {' '}
                    &lt;
                </div>
                <div
                    style={{
                        overflowX: 'auto',
                        display: 'flex'
                    }}
                    className="scrolll"
                    onScroll={handleScroll}
                >
                    {tabs.map((tab, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                gap: 4,
                                alignItems: 'center',
                                flex: '0 0 auto',
                                padding: '10px 20px',
                                cursor: 'pointer',
                                borderBottom: '3px solid transparent',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                            }}
                            onClick={() => handleTabClick(index)}
                        >
                            <div>{tab.label}</div>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        cursor: 'pointer',
                        marginLeft: '20px',
                        marginRight: '20px',
                        visibility: scrollPosition !== 2 ? 'visible' : 'hidden'
                    }}
                    onClick={() => handleNavigation('forward')}
                >
                    &gt;
                </div>
            </div>
            {/* <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} /> */}

            {/* <Tabs
                className="topblock-tabs"
                value={activeSettingsAdminPanelTab}
                onChange={handleTabChange}
                textColor="primary"
                //variant="scrollable"
                allowScrollButtonsMobile={true}
                aria-label="Admin panel tabs"
            >
                <Tab label="Default Assumptions" {...a11yProps(0)} />
                <Tab label="Traffic Light System" {...a11yProps(1)} />
                <Tab label="Permission System" {...a11yProps(2)} />
                <Tab label="Subscriptions" {...a11yProps(3)} />
                <Tab label="Equity Ticker" {...a11yProps(4)} />
                <Tab label="Economic Ticker" {...a11yProps(5)} />
                <Tab label="Feedback" {...a11yProps(6)} />
                <Tab label="Widget Calculator" {...a11yProps(7)} />
                <Tab label="News & Research" {...a11yProps(8)} />
                <Tab label="Chat Messages" {...a11yProps(9)} />
            </Tabs> */}
            {/* </Tabs> */}
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeSettingsAdminPanelTab}
                onChangeIndex={(index) => {
                    setActiveTab(index);
                    dispatch(setActiveSettingsAdminPanelTab(index));
                }}
            >
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={0} dir={theme.direction}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container id="outputWrapper">
                            {fieldsDetail.map((val, i) => {
                                return (
                                    <React.Fragment key={i + 'assumptionFields'}>
                                        <Grid item xs={6} sm={6}>
                                            <Typography variant="body2" className={`outputLabel`} component="p">
                                                {val.fieldLabel}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <RenderField
                                                name={val.name}
                                                control={control}
                                                id={val.name}
                                                fieldName={val.fieldName}
                                                type={val.type}
                                                required={true}
                                                placeholder={val.placeholder}
                                                setValue={setValue}
                                                {...val}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                            <Grid item xs={12}>
                                <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                    Save
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={1} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <TrafficLight classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>

                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={2} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <ModuleSetting classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={3} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <SubscriptionSetting classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={4} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <EquityTicker classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={5} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <EconomicTicker classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={6} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <PortfolioAllocationfunds classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={7} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <FeedbackSetting classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={8} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <WidgetCalSetting classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={9} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <KnowledgeResearchAdmin classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={10} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <KnowledgeResearchTable classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={11} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <ChatbotMessagesAdmin classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={activeSettingsAdminPanelTab} index={12} dir={theme.direction}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <NewsFeedAdmin classes={classes} setting={setting} />
                            </Paper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
            </SwipeableViews>
        </TopBlockBox>
    );
}
