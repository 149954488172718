import React, { useEffect } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, useMediaQuery } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { LatestGrid, LatestUpdateBox, LatestUpdateCard, LatestUpdateCardAction } from 'assets/jss/LandingStyle';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Loader from 'components/CommonComponent/Loader';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { fetchDashboardBlogsList } from 'redux/actions/BlogTypes';
import { getBlogList } from 'redux/actions/GeneralCalActions';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LatestUpdateBlock = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const general = useSelector((state) => state.blogReducer);
    const isMobile = useMediaQuery('(max-width:960px)'); // Adjust the breakpoint as per your requirement

    useEffect(() => {
        dispatch(getBlogList());
        dispatch(fetchDashboardBlogsList());
    }, [dispatch]);

    useEffect(() => {
        localStorage.setItem('myBlog', JSON.stringify(general?.dashboardBlogsList));
    }, [general?.dashboardBlogsList?.length]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => (
            <div
                style={{
                    position: 'relative',
                    bottom: '-20px' // Adjust this value to position the dots as needed
                }}
            >
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </div>
        )
    };

    return (
        <LatestUpdateBox>
            {general?.loader ? <Loader /> : ''}
            <div className="title-div" id="blog">
                <Typography variant="h4" sx={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px' }}>
                    Our Latest Updates
                </Typography>
                <CustomButton variant="contained" ps={15} sx={{ mt: 1 }} onClick={() => navigate('/knowledge-and-research/blog')}>
                    Read Blog
                </CustomButton>
            </div>
            {isMobile ? (
                <Slider {...settings}>
                    {general?.dashboardBlogsList?.slice(0, 3).map((val, i) => (
                        <Box key={i + '_blog'} px={1}>
                            <LatestUpdateCard sx={{ maxWidth: 345 }}>
                                <Box className="blog-image-box">
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        height="140"
                                        image={val.image_url?.includes('http') ? val.image_url : 'img/blog-bg-cr.jpg'}
                                        className="blog-image"
                                    />
                                </Box>
                                <CardContent className="desc-card-content">
                                    <Typography gutterBottom variant="body1" component="div" className="blog-date" sx={{ mb: 2 }}>
                                        {val.published_at ? moment(val.published_at, 'DD-MM-YYYY HH:mm:ss').format('MM / DD / YYYY') : '-'}
                                    </Typography>
                                    <Typography variant="h5">{parse(val.title)}</Typography>
                                </CardContent>
                                <LatestUpdateCardAction className={'read-more-box'}>
                                    <CustomButton
                                        onClick={() =>
                                            navigate(
                                                '/knowledge-and-research/blog/' +
                                                    val?.title
                                                        .toLowerCase()
                                                        .replace(/\s+/g, '-')
                                                        .replace(/[^a-z0-9\-]/g, '')
                                            )
                                        }
                                        endIcon={<ArrowForwardIcon />}
                                        className="read-more"
                                    >
                                        Read Research
                                    </CustomButton>
                                </LatestUpdateCardAction>
                            </LatestUpdateCard>
                        </Box>
                    ))}
                </Slider>
            ) : (
                <Grid container spacing={2} className="grid-container">
                    {general?.dashboardBlogsList?.slice(0, 3).map((val, i) => (
                        <LatestGrid item xs={12} sm={5} md={4} key={i + '_blog'}>
                            <LatestUpdateCard sx={{ maxWidth: 345 }}>
                                <Box className="blog-image-box">
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        height="140"
                                        image={val.image_url?.includes('http') ? val.image_url : 'img/blog-bg-cr.jpg'}
                                        className="blog-image"
                                    />
                                    {/* <IconButton>
                                        <ShareIcon />
                                    </IconButton> */}
                                </Box>
                                <CardContent className="desc-card-content">
                                    <Typography gutterBottom variant="body1" component="div" className="blog-date" sx={{ mb: 2 }}>
                                        {val.published_at ? moment(val.published_at, 'DD-MM-YYYY HH:mm:ss').format('MM / DD / YYYY') : '-'}
                                    </Typography>
                                    <Typography variant="h5">{parse(val.title)}</Typography>
                                </CardContent>
                                <LatestUpdateCardAction className={'read-more-box'}>
                                    <CustomButton
                                        onClick={() =>
                                            navigate(
                                                '/knowledge-and-research/blog/' +
                                                    val?.title
                                                        .toLowerCase()
                                                        .replace(/\s+/g, '-')
                                                        .replace(/[^a-z0-9\-]/g, '')
                                            )
                                        }
                                        endIcon={<ArrowForwardIcon />}
                                        className="read-more"
                                    >
                                        Read Research
                                    </CustomButton>
                                    {/* <IconButton>
                                        <ChatBubbleOutlineIcon /> <Typography sx={{ ml: 1 }}>2</Typography>
                                    </IconButton> */}
                                </LatestUpdateCardAction>
                            </LatestUpdateCard>
                        </LatestGrid>
                    ))}
                </Grid>
            )}
        </LatestUpdateBox>
    );
};

export default LatestUpdateBlock;
