import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const PortfolioAllocationDesc = ({ classes, control, handleOnChange, setValue, ...rest }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="subheading2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Portfolio Allocation
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This calculator evaluates your current situation and recommends an asset allocation compared to your current
                                allocation. This calculator goes further and recommends specific trades, a timeline for execution and even
                                specific assets based on a model portfolio.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                 Our allocation is based on an algorithm that considers your age,
                                risk tolerance and several other factors.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default PortfolioAllocationDesc;
